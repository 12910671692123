@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.auditTemplateGrid {
    &__spinner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: c.$gray100;
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include t.display("xs", "semibold");
        color: c.$baseWhite;
    }
    &__title {
        color: c.$baseWhite;
        @include t.text("xl", "semibold");
    }
    &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__item {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}
