@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.selectWithActionsItem {
    display: flex;
    padding: 2px 4px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 100%;
    justify-content: space-between;
    &:hover {
        background-color: c.$gray100;
        color: c.$gray600;
    }
    &--active {
        @extend .selectWithActionsItem;
        background-color: c.$primary50;
        &:hover {
            color: c.$gray700;
        }
    }
    &__content {
        display: flex;
        padding: 9px 10px;
        align-items: center;
        gap: 8px;
        border-radius: 6px;

        &__text {
            color: c.$gray700;
            @include t.text("sm", "medium");
        }
    }
    &__actions {
        display: flex;
    }
}
