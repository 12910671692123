@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/mixin/clamp" as m;

.mobilePlanRowBody {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    &__info {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
    }
    &__name {
        @include t.text("xs", "medium");
        color: c.$gray800;
        @include m.textclamp();
    }
    &__icon {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        height: 100%;
        &__edit {
            height: 16px;
            width: 16px;
            color: c.$gray600;
        }
    }
}
