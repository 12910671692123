@use "/src/styles/colors.scss" as c;

.cellIcons {
    display: flex;
    justify-content: center;
    &__icon {
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        &--darkGray {
            @extend .cellIcons__icon;
            color: c.$gray600;
        }
        &--ligthGray {
            @extend .cellIcons__icon;
            color: c.$gray300;
        }
    }
}
