@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.onBoardingSubStepItem {
    display: flex;
    gap: 4px;
    align-items: flex-start;
    &__name {
        @include t.text("md");
        line-height: 28px;
        color: c.$gray700;
    }
    &__blockedName {
        @include t.text("md");
        line-height: 28px;
        color: c.$gray500;
    }
}
