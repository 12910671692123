@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.FAQList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__title {
        @include t.text("xl", "semibold");
        color: c.$baseWhite;
    }
    &__description {
        @include t.text("md");
        padding: 0 8px;
    }
}
