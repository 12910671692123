@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.editImageIcon {
    --active-bg: "black";

    padding: 0;
    margin: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: rgba($color: c.$gray600, $alpha: 0.7);
    &--active {
        backdrop-filter: unset;
        background-color: var(--active-bg);
    }
    &__svg {
        @include t.text("sm", "medium");
        color: c.$baseWhite;
    }
}
