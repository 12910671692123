@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.issueBodyAssetField {
    color: c.$gray900;
    @include t.text("md", "regular");
    text-wrap: balance;
    &::marker {
        font-size: 12px;
    }
}
