@use "/src/styles/variables.scss" as v;

.notificationsDepartmentModal {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
    &__company {
        width: 100%;
    }
    &__body {
        display: flex;
        gap: 32px;
        width: 100%;
        flex-direction: column;
    }
}

@media screen and (min-width: v.$tablet) {
    .notificationsDepartmentModal {
        &__company {
            width: calc(50% - 16px);
        }
        &__body {
            flex-direction: row;
        }
    }
}
