@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.temporaryGrid {
    &__status {
        display: flex;
        align-items: center;
        justify-content: center;

        &__ball {
            font-size: 20px;
            margin-right: 5px;
            &--active {
                @extend .temporaryGrid;
                color: #2cc200;
            }

            &--inActive {
                @extend .temporaryGrid;
                color: #c2002f;
            }
        }
    }
    &__dates {
        color: c.$gray700;
        @include t.text("sm", "regular");
        &--clickable {
            @extend .temporaryGrid__dates;
            color: c.$primary500;
            cursor: pointer;
        }
    }
    &__actions {
        width: 120px;
    }
}
