@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
.breadcrumbsContent {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.breadcrumbs {
    display: flex;
    width: 100%;
    overflow: auto;
    transition: all 300ms;
    &::-webkit-scrollbar {
        height: 0px !important;
    }

    &__goBack {
        display: flex;
        width: 13.333px;
        height: 13.333px;
        justify-content: center;
        align-items: center;
        color: c.$baseWhite;
        cursor: pointer;
    }
    &__container {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0;
        flex-shrink: 0;
        &__oneLevel {
            display: flex;
            align-items: center;
            gap: 2px;
        }
    }
    &__dots {
        color: c.$baseWhite;
        font-size: 15px;
        letter-spacing: 2px;
        font-weight: 500;
        height: 24px;
    }
}
