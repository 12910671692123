@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.dropdownListItem {
    display: flex;
    padding: 2px 4px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: background-color 300ms linear;
    width: 100%;
    &:hover {
        background-color: c.$gray100;
        color: c.$gray600;
    }
    &__content {
        display: flex;
        padding: 9px 10px;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        &__icon {
            width: 16px;
            height: 16px;
            color: c.$gray600;
            text-align: center;
            font-size: 16px;
            &--active {
                @extend .dropdownListItem__content__icon;
                color: c.$primary700;
            }
        }
        &__text {
            color: c.$gray700;
            &--sm {
                @include t.text("sm", "medium");
            }
            &--md {
                @include t.text("md", "medium");
            }
            &--active {
                @extend .dropdownListItem__content__text--sm;
                color: c.$primary700;
            }
        }
    }

    &--active {
        @extend .dropdownListItem;
        background-color: c.$primary50;
        &:hover .dropdownListItem__content__text--active {
            color: c.$gray700;
        }
        &:hover .dropdownListItem__content__icon--active {
            color: c.$gray700;
        }
    }
}
