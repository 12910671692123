@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.roundedFilterButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 46px;
    backdrop-filter: blur(4px);
    flex: 1;
    transition: all 200ms;
    height: 100%;
    user-select: none;
    cursor: pointer;

    &--primary {
        color: c.$primary200;
    }
    &--appcc {
        color: c.$appcc200;
    }
    &:hover {
        background: rgba(255, 255, 255, 0.2);
        color: c.$baseWhite;
    }
    &--active {
        @extend .roundedFilterButton;
        color: c.$baseWhite;
        background-color: rgba(255, 255, 255, 0.2);
        &:hover {
            background-color: rgba(255, 255, 255, 0.3);
            color: c.$baseWhite;
        }
        &--focus {
            @extend .roundedFilterButton;
            background-color: rgba(255, 255, 255, 0.35);
            color: c.$baseWhite;
            &:hover {
                background-color: rgba(255, 255, 255, 0.35);
                color: c.$baseWhite;
            }
        }
    }
    &--focus {
        @extend .roundedFilterButton;
        background-color: rgba(255, 255, 255, 0.35);
        color: c.$baseWhite;
        &:hover {
            background-color: rgba(255, 255, 255, 0.35);
            color: c.$baseWhite;
        }
    }
    &__left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        &__icon {
            text-align: center;
            font-style: normal;
            font-weight: 400;
            &--xs {
                font-size: 12px;
            }

            &--sm {
                font-size: 16px;
            }
        }
        &__number {
            text-align: center;
            @include t.display("xs", "medium");
        }
    }
    &__text {
        text-align: center;
        &--xs {
            @include t.text("xs", "regular");
        }

        &--sm {
            @include t.text("sm", "regular");
        }
    }
}
