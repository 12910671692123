@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.selectProfiles {
    max-height: 375px;
    min-height: 0;
    &__title {
        color: c.$gray900;
        @include t.display("xs", "semibold");
        text-align: center;
    }
    &__cardContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow: auto;
        flex: 1;
    }
    &__addUser {
        display: flex;
        align-items: center;
        gap: 6px;
        width: 100%;
        cursor: pointer;
        color: c.$primary700;
        @include t.text("sm", "semibold");
        &__icon {
            width: 16px;
            color: c.$primary700;
            height: 16px;
        }
    }
}
