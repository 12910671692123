// shadows
$shadow-light: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$shadow-mid: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$shadow-dark: 0 10px 20px -5px rgba(0, 0, 0, 0.6);
$shadow-second-color: 0 0 5px -1px #1c5372;
$shadow-white: 0 0 15px -1px white;
// }

// colors {
$main-color: #397096;
$main-color-transparent: rgba(57, 112, 150, 0.3);
$light-blue: #e1effe;
$light-gray: #f4f4f4;
$mid-gray: #999999;
$dark-gray: #797979;
$yellow: #ee9f0d;
$danger: #c20b2f;
// }

// padding
$padd-dsk: 20px;
$padd-mob: 10px;

// margin
$margin-dsk: 20px;
$margin-mob: 10px;

// gap
$gap-1: 5px;
$gap-2: 10px;
$gap-3: 15px;
$gap-4: 20px;
$gap-5: 25px;
$gap-6: 30px;

.relative {
    position: relative;
}

$desktop: 1440px;
$laptop: 1024px;
$tablet: 901px;
$mobile: 768px;

// FIXME:
$mobile-767: 767px;
$laptop-1023: 1023px;

$layoutMaxWidth: 1300px;
