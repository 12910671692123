@use "/src/styles/variables.scss" as v;

.comapanyLogo {
    display: flex;
    width: 152px;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
    &__logo {
        width: 100%;
        max-width: 225px;
    }
}

@media screen and (min-width: v.$tablet) {
    .comapanyLogo {
        flex-direction: column;
        gap: 8px;
        max-width: 120px;
        &__logo {
            max-width: 120px;
        }
    }
}
