@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.reviewWorker {
    &__crumbHeader {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }
    &__header {
        display: block;
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 2;
        &__items {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            flex-direction: column;

            &__date {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    &__userAvatar {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 20px;
        &__arrowBack {
            display: none;
            cursor: pointer;
            padding: 10px;
            color: c.$baseWhite;
            @include t.text("lg", "semibold");
        }
        &__bell {
            cursor: pointer;
            padding: 10px;
            color: c.$baseWhite;
            @include t.text("lg", "semibold");
            display: block;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .reviewWorker {
        &__header {
            display: none;

            &__items {
                flex-direction: row;
                gap: 10px;
                &__date {
                    width: 25%;
                    min-width: 250px;
                }
            }
        }
        &__userAvatar {
            &__arrowBack {
                display: block;
            }
        }
    }
}
