@use "/src/styles/variables.scss" as v;

.issueModal {
    display: flex;
    flex-direction: column;
    gap: 32px;
    &__sppiner {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    &__header {
        display: block;
        height: auto;
        margin: -10px 0px;
    }
    &__info {
        display: flex;
        gap: 32px;
        width: 100%;
        flex-direction: column;
    }
    &__selects {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100%;
        width: 100%;
    }
}
