@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.selectWithActionNoOptions {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 100%;
    &__container {
        display: flex;
        padding: 11px 10px;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
    }
    &__text {
        color: c.$gray500;
        @include t.text("sm", "medium");
    }
}
