@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.regexValidationItem {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    &__icon {
        display: flex;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        &--invalid {
            @extend .regexValidationItem__icon;
            color: c.$error500;
        }
        &--valid {
            @extend .regexValidationItem__icon;
            color: c.$success500;
        }
    }
    &__errorMessage {
        @include t.text("sm", "medium");
        &--invalid {
            @extend .regexValidationItem__errorMessage;
            color: c.$gray500;
        }
        &--valid {
            @extend .regexValidationItem__errorMessage;
            color: c.$gray800;
        }
    }
}
