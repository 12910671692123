@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.roleCollapseRow {
    display: grid;
    grid-template-columns: 200px repeat(5, minmax(90px, 1fr));
    width: 100%;
    border-bottom: 1px solid c.$gray300;
    padding: 12px 0;
    &--noBorder {
        @extend .roleCollapseRow;
        border: none;
    }
    &__text {
        color: c.$gray700;
        @include t.text("sm", "medium");
    }
}
