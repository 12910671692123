@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileCompanyGridBody {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid c.$gray200;

    &__container {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        width: 100%;
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        width: 100%;
        &__title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            color: c.$gray800;
            text-overflow: ellipsis;
            @include t.text("xs", "medium");
        }
        &__userCode,
        &__address {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            color: c.$gray700;
            text-overflow: ellipsis;
            @include t.text("xs", "regular");
        }
    }
    &__icon {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 20px;
        height: 100%;
        cursor: pointer;
        &__edit {
            height: 20px;
            width: 20px;
            color: c.$gray600;
        }
    }
}
