@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.workingPositionGridMobileBody {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid c.$gray200;
    &--odd {
        @extend .workingPositionGridMobileBody;
        background: c.$baseWhite;
    }
    &--even {
        @extend .workingPositionGridMobileBody;
        background: c.$gray25;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        width: 100%;
    }
    &__body {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        width: 100%;
        &__headers {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
        }
    }
    &__title,
    &__code,
    &__description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: c.$gray800;
        text-overflow: ellipsis;
        @include t.text("xs", "medium");
    }
    &__code {
        color: c.$gray700;
        @include t.text("xs", "regular");
    }
    &__description {
        -webkit-line-clamp: 2;
        color: c.$gray800;
        @include t.text("xs", "regular");
    }

    &__actions {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &__edit {
            color: c.$gray600;
            text-align: center;
            font-size: 16px;
            width: 16px;
        }
    }
    &__footer {
        display: flex;
        width: 100%;
        gap: 8px;
        flex-direction: column;
        justify-content: flex-start;
        & > a {
            justify-content: flex-start;
        }
    }
}
