@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;
@use "/src/styles/variables.scss" as v;

.taskPillBody-skelleton {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    &__title {
        display: flex;
        width: 130px;
        height: 14px;
        align-items: center;
        gap: 10px;
        @include m.skeleton-is-loading(c.$gray400);
        border-radius: 12px;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        align-self: stretch;
        &__description {
            display: flex;
            width: 90px;
            height: 10px;
            align-items: center;
            gap: 10px;
            @include m.skeleton-is-loading(c.$gray200);
            border-radius: 12px;
        }
        &__container {
            display: flex;
            align-items: center;
            gap: 12px;
            &__button {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                @include m.skeleton-is-loading(c.$gray200);
            }
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .taskPillBody-skelleton {
        &__header {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }
}
