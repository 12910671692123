@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;

.dateRangePicker {
    width: 100%;
    height: 40px;
    border: 1px solid c.$gray300;
    border-radius: 6px;
    padding: 8px 12px;
    @include t.text("md");
    color: c.$gray700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    cursor: pointer;
    background-color: c.$baseWhite;
    &--disabled {
        @extend .dateRangePicker;
        background: c.$gray50;
        border: 1px solid c.$gray300;
        color: c.$gray500;
        &.transparent {
            background-color: transparent;
        }
    }

    &__input {
        outline: none;
        background-color: transparent;
        width: 100%;
        border: none;
        cursor: pointer;
        color: c.$gray800;
        @include t.text("md");
        &::placeholder {
            font-size: 16px;
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
        &--relative {
            @extend .dateRangePicker__container;
            position: relative;
        }
    }
    &__icons {
        display: flex;
        gap: 6px;
        &--red {
            @extend .dateRangePicker__icons;
            color: c.$error700;
        }
    }
    &__options {
        background-color: c.$baseWhite;
        border: 1px solid c.$gray300;
        border-radius: 8px;
        padding: 6px;
        position: absolute;
        width: 100%;
        top: 100%;
        z-index: 2;
        margin-top: 4px;
    }
}

.dateRangePickerInverted {
    @extend .dateRangePicker;
    border: 1px solid c.$baseWhite;
    color: c.$baseWhite;
    background-color: transparent;
    position: relative;

    &__input {
        @extend .dateRangePicker__input;
        color: c.$baseWhite;

        &::placeholder {
            color: rgb(206, 206, 206) !important;
            font-size: 16px;
        }
    }

    &--disabled {
        @extend .dateRangePickerInverted;
        background: #48718d;
        color: #a9a3a3;
        &::placeholder {
            color: #a9a3a3;
            font-size: 16px;
        }
    }
}

.react-datepicker__triangle {
    left: 120px !important;
    transform: translate(0) !important;
}
.react-datepicker-popper {
    z-index: 2 !important;
}

.dateError {
    border-color: c.$error500;
}

.dateRangePickerFocus {
    border: 2px solid c.$primary500;
}
