@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.tableHeader {
    height: 40px;
    padding: 12px;
    user-select: none;
    border-bottom: 1px solid c.$gray200;
    background: c.$gray50;

    &--roundedCorners {
        &:first-child {
            border-radius: 8px 0px 0px 0px;
        }
        &:last-child {
            border-radius: 0px 8px 0px 0px;
        }
    }

    &__checkbox {
        @extend .tableHeader;
        width: 20px;
    }
    &:hover {
        background: c.$gray200;
    }
    &__container {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    &__title {
        overflow: hidden;
        color: c.$gray600;
        text-overflow: ellipsis;
        @include t.text("xs", "medium");
        text-align: left;
        width: auto;
    }
    &__icon {
        text-align: center;
        font-style: normal;
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
        height: 11px;
        width: 11px;
        &--default {
            @extend .tableHeader__icon;
            color: c.$gray600;
        }
        &--sorted {
            @extend .tableHeader__icon;
            color: c.$primary500;
        }
    }
}

.noHover:hover {
    background: c.$gray50;
}
