.timeRangePicker {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    width: 100%;
    &__timePickers {
        @extend .timeRangePicker;
        flex-direction: row;
        gap: 30px;
    }
}