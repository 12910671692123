@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.issueAssetModalStep {
    &__titles {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
        &--regular {
            color: c.$gray700;
            @include t.text("sm", "medium");
        }

        &__plural {
            list-style: none;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
    &__spinner {
        height: 100%;
        display: flex;
        justify-content: center;
    }
    &__info {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }
}
