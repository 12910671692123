@use "/src/styles/variables.scss" as v;

.feedback__popup {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media screen and (min-width: v.$tablet) {
    .feedback__popup__container {
        padding-top: 0;
    }
}
