@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;

.secondaryFilterReset {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    &__icon {
        color: c.$primary700;
        font-size: 16px;
    }
    &__text {
        color: c.$primary700;
        @include t.text("sm", "semibold");
    }
}
