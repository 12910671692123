@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.numberIcon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: c.$primary500;
    border: 1px solid c.$baseWhite;
    text-align: center;
    color: c.$baseWhite;
    display: flex;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    @include t.text("xs", "medium");
}
