@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.fullScreenModalHeader {
    display: flex;
    align-items: center;
    width: 100%;
    &__btn {
        all: unset;
        color: c.$baseWhite;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        @include t.text("md", "semibold");
    }
    &__icon {
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        &__content {
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
