@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.mailModal {
    position: absolute;
    bottom: 0;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;

    height: 100%;
    box-shadow: c.$shadow-lg;
    overflow: hidden;
    padding: 0px 12px;
    border-radius: 8px 8px 0 0;
    &--showBlur {
        background-color: c.$blackTransparent;
    }
    &__container {
        height: 55vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px 8px 0 0;
    }
    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: c.$baseWhite;
        &__children {
            flex: 1;
            overflow: auto;
            padding: 12px;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .mailModal {
        align-items: center;
        &__container {
            max-width: 600px;
        }
        &__body {
            border-radius: 0 0 8px 8px;
            &__children {
                padding: 32px;
            }
        }
    }
}
