@use "/src/styles/colors.scss" as c;

.avatarPopover {
    &__list {
        width: 220px;
        display: flex;
        flex-direction: column;
        &__lastItem {
            border-top: 1px solid c.$gray200;
        }
    }
}
