@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

@mixin headerIcon {
    width: 45px;
    height: 40px;
    border-radius: 6px;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 12px 8px 14px;
    cursor: pointer;
    user-select: none;
}

.headerRightIcon {
    @include headerIcon();
    &.headerText {
        gap: 10px;
        color: c.$baseWhite;
        width: auto;
        @include t.text("sm", "semibold");
        transition: color 0s;
        &:hover {
            color: c.$baseWhite;
        }

        &--active {
            @extend .headerText;
            color: c.$baseWhite;
        }
    }

    &--solo {
        @include headerIcon();
        padding-left: 12px;
        &--active {
            @extend .headerRightIcon--solo;
            &--grayMode {
                @extend .headerRightIcon--solo--active;
                background: c.$basewhiteTransparent;
            }
            &--primary {
                @extend .headerRightIcon--solo--active;
                background: c.$basewhiteTransparent;
            }
            &--appcc {
                @extend .headerRightIcon--solo--active;
                background: c.$basewhiteTransparent;
            }
        }
        &:hover {
            & .headerRightIcon__icon {
                color: c.$baseWhite;
            }
        }
    }

    &--active {
        @extend .headerRightIcon;
        background: c.$basewhiteTransparent;
        &--grayMode {
            @extend .headerRightIcon--active;
            background: c.$basewhiteTransparent;
        }
        &--primary {
            @extend .headerRightIcon--active;
            background: c.$basewhiteTransparent;
        }
        &--appcc {
            @extend .headerRightIcon--active;
            background: c.$basewhiteTransparent;
        }
    }

    &:hover {
        background-color: c.$basewhiteTransparent;
        & .headerRightIcon__icon {
            color: c.$baseWhite;
        }
    }

    &__icon {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: c.$baseWhite;
        &--solo {
            @extend .headerRightIcon__icon;
            &--active {
                @extend .headerRightIcon__icon--solo;
                color: c.$baseWhite;
            }
        }
        &--active {
            @extend .headerRightIcon__icon;
            color: c.$baseWhite;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .headerRightIcon {
        height: 36px;
        &--solo {
            @include headerIcon();
            padding-left: 12px;
            &:hover {
                background-color: c.$basewhiteTransparent;
            }
            &--active {
                @extend .headerRightIcon--solo;
                background: c.$basewhiteTransparent;
                &:hover {
                    & .headerRightIcon__icon {
                        color: c.$baseWhite;
                    }
                }
                &--grayMode {
                    &:hover {
                        background-color: c.$gray400;
                    }
                }
                &--primary {
                    &:hover {
                        background-color: c.$primary400;
                    }
                }
                &--appcc {
                    &:hover {
                        background-color: c.$appcc500;
                    }
                }
            }
        }

        &--active {
            background: c.$basewhiteTransparent;
            &--grayMode {
                &:hover {
                    background-color: c.$gray400;
                }
            }
            &--primary {
                &:hover {
                    background-color: c.$primary400;
                }
            }
            &--appcc {
                &:hover {
                    background-color: c.$appcc500;
                }
            }
        }

        &__icon {
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            &--solo {
                @extend .headerRightIcon__icon;
                font-size: 20px;
                line-height: 20px;
                &--active {
                    @extend .headerRightIcon__icon--solo;
                    color: c.$baseWhite;
                }
            }
        }
    }
}

.headerItem {
    position: relative;
    &--TABLET,
    &--TAB_DESK,
    &--DESKTOP {
        @extend .headerItem;
        display: none;
    }
}

@media screen and (min-width: v.$desktop) {
    .headerItem--TABLET {
        display: block;
    }
}
@media screen and (min-width: v.$tablet) and (max-width: v.$desktop) {
    .headerItem--DESKTOP {
        display: block;
    }
}
