@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;

.supervisorDetailPage {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__date {
        width: 100%;
        display: flex;
        gap: 12px;
        flex-direction: row;
        &--50 {
            @extend .supervisorDetailPage__date;
            width: 50%;
        }
        &--100 {
            @extend .supervisorDetailPage__date;
            width: 100%;
        }
        &__buttons {
            display: flex;
            gap: 10px;
            width: 100%;
            &--three {
                display: flex;
                width: 100%;
                gap: 10px;
            }
        }
    }
    &__headerFilters {
        display: flex;
        width: 75%;
        gap: 12px;
        align-items: center;
        max-width: 900px;
    }
    &__filters {
        display: flex;
        align-items: center;
    }
    &__button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        margin-bottom: 12px;
    }
    &__chart {
        width: 100%;
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        display: flex;
        justify-content: center;
        margin: 10px 0px;

        &__doughnut {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            &__25 {
                min-width: 300px;
                width: 25%;
            }
        }
        &__ranking {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;
            width: 100%;
        }
    }
}

@media only screen and (max-width: v.$mobile-767) {
    .supervisorDetailPage {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        &__button {
            flex-direction: column;
            margin-bottom: 20px;
        }

        &__date {
            width: 100%;
            flex-direction: column;
            &__buttons {
                &--three {
                    flex-direction: column;
                }
            }
        }
        &__chart {
            width: 100%;
            padding: 12px;

            &__doughnut {
                display: flex;
                flex-direction: column;
                &__25 {
                    width: 75%;
                }
            }
            &__ranking {
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: center;
                width: 100%;
            }
        }
    }
    .maxWidth250 {
        max-width: 100%;
        min-width: 100%;
    }
}
.maxWidth250 {
    width: 100%;
    max-width: 250px;
}
