@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.menuSideBar {
    position: absolute;
    top: 0;
    background-color: transparent;
    left: 0;
    animation-name: fade-in;
    animation-duration: 300ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    z-index: 50;
    height: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        &__items {
            display: flex;
            flex-direction: column;
            gap: 20px;
            height: 100%;
            margin-top: 20px;
            padding: 0 8px 8px 8px;
            overflow: auto;
        }
    }

    &__menu {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: relative;
        animation: slide-in 300ms cubic-bezier(0.42, 0, 0.58, 1) forwards;
        display: flex;
        &__footer {
            padding: 0px 8px 24px 8px;
            &__config {
                border-top: 1px solid c.$gray200;
                border-bottom: 1px solid c.$gray200;
                padding-top: 14px;
                padding-bottom: 30px;
                margin-bottom: 20px;
            }
        }

        &__container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &--hide {
            @extend .menuSideBar__menu;
            left: -100%;
            opacity: 0;
            max-width: 320px;
            height: 100%;
            background-color: c.$baseWhite;
            opacity: 0;
            position: relative;
            animation: slide-out 300ms cubic-bezier(0.42, 0, 0.58, 1) forwards;
        }
        &__items {
            background-color: c.$baseWhite;
            max-width: 320px;
            min-width: 320px;
            position: relative;
        }
        &__icon {
            color: c.$baseWhite;
            padding-top: 12px;
            font-size: 14px;
            width: 15%;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
    }
    &__line {
        margin-top: 30px;
        margin-bottom: 20px;
        border: 1px solid c.$gray200;
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__cross {
        padding: 12px 0 0 12px;
    }
}

@media screen and (min-width: v.$tablet) {
    .menuSideBar__menu {
        &__items,
        &--hide {
            width: 320px;
        }
        &__icon {
            width: 5%;
            justify-content: left;
            text-align: left;
        }
    }
}

@keyframes fade-in {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: rgba(52, 64, 84, 0.6);
    }
}

@keyframes slide-in {
    0% {
        left: -30%;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes slide-out {
    0% {
        left: 0;
        opacity: 1;
    }
    100% {
        left: -30%;
        opacity: 0;
    }
}
