@use "/src/styles/colors.scss" as c;

.collapsable {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    gap: 20px;
    transition: gap 200ms;
    &--white {
        background-color: c.$baseWhite;
    }
    &--transparent {
        background-color: transparent;
    }
    &--gap0 {
        @extend .collapsable;
        gap: 0;
    }
}
