.baseTaskGrid {
    &__actions {
        width: 80px;
    }
    &__icons {
        width: 120px;
    }

    &__minWidth {
        width: 150px;
    }

    &__classification {
        width: 180px;
    }

    &__center {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
