@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.licenseMainContact {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &__title {
            color: c.$primary500;
            @include t.text("md", "medium");
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .licenseMainContact {
        justify-content: space-evenly;
        flex-direction: row;
        padding: 30px;
        align-items: center;
    }
}
