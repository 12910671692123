@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileCorrectiveMeasureRow {
    display: flex;
    width: 100%;
    padding: 12px;
    align-items: flex-start;
    gap: 20px;
    border-bottom: 1px solid c.$gray200;
    user-select: none;
    &--odd {
        @extend .mobileCorrectiveMeasureRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .mobileCorrectiveMeasureRow;
        background: c.$gray25;
    }
    &__header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }
    &__description {
        color: c.$gray800;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        @include t.text("xs", "medium");
    }
    &__plan {
        color: c.$gray700;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        @include t.text("xs", "regular");
    }
}
