.taskTemporalityDateRangeWeeklyDisabled {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__label {
        display: flex;
    }
    &__weeklyDays {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        flex-wrap: wrap;
    }
}
