@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.starsReviews {
    &__icon {
        font-size: 20px;
        &--yellow {
            @extend .starsReviews__icon;
            color: c.$yellow400;
        }
        &--primary {
            @extend .starsReviews__icon;
            color: c.$primary500;
        }
        &--disabled {
            @extend .starsReviews__icon;
            color: c.$gray200;
        }
    }
    &__stars {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        &__text {
            @include t.text("xs", "medium");
            color: c.$gray500;
        }
    }
}
