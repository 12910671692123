@use "/src/styles/variables.scss" as v;

.sliderModal {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 12px;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        &__icon {
            padding: 9px;
            color: white;
            cursor: pointer;
            font-size: 22px;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}

@media screen and (min-width: v.$tablet) {
    .sliderModal {
        &__wrapper {
            width: 85%;
            max-width: 1200px;
        }
    }
}
