@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.issueBodyAsset {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    &__text {
        color: c.$gray900;
        @include t.text("md", "regular");
        text-wrap: pretty;
    }
    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
}

@media screen and (min-width: v.$tablet) {
    .issueBodyAsset {
        flex-direction: row;
        justify-content: space-between;
    }
}
