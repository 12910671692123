@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.taskGrid {
    &__container {
        position: relative;
    }
    &__avatar {
        width: 200px;
    }
    &__detailCell {
        min-width: 200px;
    }
    &__actions {
        width: 120px;
    }
    &__hours {
        width: 180px;
    }
    &__hoursRow {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    &__typeCode {
        width: 110px;
        justify-content: center;
    }
    &__icons {
        width: 120px;
    }
    &__deleteModal {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        width: 100%;
    }
}
