@use "/src/styles/colors.scss" as c;

.hueSlider {
    --hue-bg: "red";

    // writing-mode: vertical-lr;
    appearance: none;
    height: 13px;
    width: 200px;
    border-radius: 10px;
    background: linear-gradient(to right, red, yellow, lime, cyan, blue, magenta, red);
    outline: none;
    cursor: pointer;
    transform: rotate(-270deg);
    transform-origin: bottom right;

    &::-webkit-slider-thumb {
        width: 23px;
        height: 23px;
        -webkit-appearance: none;
        background: transparent;
        border-radius: 50%;
        cursor: pointer;
        background-color: var(--hue-bg);
        border: 2px solid c.$baseWhite;
    }

    &::-moz-range-thumb {
        width: 23px;
        height: 23px;
        appearance: none;
        background: transparent;
        border-radius: 50%;
        cursor: pointer;
        background-color: var(--hue-bg);
        border: 2px solid c.$baseWhite;
    }
}
