@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.designSys {
    width: 100%;
    background-color: white;
    padding: 0 12px;
    &__section {
        padding: 20px 0;
        background-color: white;
        width: 100%;

        &__titleContainer {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            border-bottom: 1px solid black;
            &__title {
                font-size: 28px;
                text-align: center;
            }
        }
        &__element {
            display: flex;
            align-items: center;
            gap: 20px;
            margin: 20px 0;
            width: 100%;
            &__blueContainer {
                background-color: c.$primary600;
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 12px;
                gap: 20px;
                border-radius: 20px;
            }
        }

        &__separator {
            width: 100%;
        }
    }
    &__select {
        width: 100%;
    }
}

.tabsBackground {
    background-color: c.$primary700;
    padding: 20px;
}

.filterbuttonsdesingsystem {
    align-items: flex-start;
    background-color: rgb(21, 84, 126);
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 4px;
    width: 100%;
}

.uploadPhotoBackground {
    width: 100%;
    height: 140px;
    padding: 10px;
    background-color: c.$primary500;
    display: flex;
    gap: 20px;
}

.wizardHeaderSection {
    max-width: 600px;
    width: 100%;
}

.headerAnalystSection {
    width: 100%;
    height: 100%;
    background-color: c.$primary500;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.breadcrumbSection {
    background: #27648d;
    padding: 10px;
    overflow-x: hidden;
}

.modalSectionmd {
    display: flex;
    width: 375px;
    padding: 12px 20px 20px 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
    }
}

.circularProgressBarSection {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    flex-direction: column;
}
.progressBarSection {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    &__progressbar {
        @extend .progressBarSection;
        width: 350px;
    }
}

@media screen and (min-width: v.$tablet) {
    .modalSectionmd {
        padding: 12px 20px 20px 20px;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row;
    }

    .circularProgressBarSection {
        flex-direction: row;
    }
}

.presentacioJordiSection {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.designSystemOtp {
    background-color: c.$primary900;
    width: 400px;
    height: 70px;
    padding: 12px;
}

.designSystemSquareButton {
    background-color: c.$primary900;
    width: 400px;
    padding: 12px;
    display: flex;
    gap: 12px;
}

.designSystem__webcamCapture__container {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
}

.designSystem__mediaCapture {
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
}
.designSystemEditImage {
    display: flex !important;
    justify-content: center;
    max-height: 100dvh !important;
}

.designSystemAvatar {
    background-color: rgb(255, 208, 219);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
}

.designSystemButtonTransparent {
    padding: 12px;
    background-color: c.$primary600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
