@use "/src/styles/colors.scss" as c;

.selectWithActions {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    color: c.$gray900;
    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }
}
