@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.editImageFooterIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: rgba($color: c.$gray600, $alpha: 0.6);
    padding: 0;
    margin: 0;
    border: none;
    &--active {
        background-color: c.$baseWhite;
    }

    &__svg {
        @include t.text("md", "medium");
        color: c.$baseWhite;
        &--active {
            color: c.$gray900;
        }
    }
}
