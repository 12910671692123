.newGroupCompanyForm {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;
    &__container {
        display: flex;
        height: 100%;
        width: 100%;
        flex: 1;
    }
}
