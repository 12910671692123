@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.icon {
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    &--dark {
        @extend .icon;
        color: c.$baseWhite;
    }
    &--light {
        @extend .icon;
        color: c.$gray600;
    }
    &--row {
        flex-direction: row;
    }
    &--rowReverse {
        flex-direction: row-reverse;
    }
    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

        &--xxs {
            @extend .icon-container;
            width: 16px;
            height: 16px;
        }
        &--xs {
            @extend .icon-container;
            width: 20px;
            height: 20px;
        }
        &--sm {
            @extend .icon-container;
            width: 25px;
            height: 25px;
        }
        &-svg {
            flex-shrink: 0;
            width: 16px;
            font-size: 16px;

            &--xxs {
                width: 16px;
                height: 16px;
            }
            &--xs {
                width: 16px;
                height: 16px;
            }
            &--sm {
                width: 20px;
                height: 20px;
            }
            &.disabled {
                cursor: default;
                opacity: 0.4;
            }
        }
    }
    &__text {
        &--dark {
            color: c.$baseWhite;
        }
        &--light {
            color: c.$gray600;
        }
        &--xxs {
            @include t.text("sm", "regular");
        }
        &--xs {
            @include t.text("md", "regular");
        }
        &--sm {
            @include t.text("xs", "regular");
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .icon {
        transition: background-color 200ms;
        &--dark {
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
            &.disabled:hover {
                background-color: transparent;
            }
        }
        &--light {
            &:hover {
                background-color: c.$gray100;
            }
            &.disabled:hover {
                background-color: transparent;
            }
        }
    }
}
