@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.avatarName {
    border: none;
    @include t.text("xs", "semibold");
    color: c.$primary600;
    text-align: left;
    &--primary {
        color: c.$primary600;
    }
    &--secondary {
        color: c.$baseWhite;
    }
}
