@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.qrCheckerContainer {
    max-width: 600px;
    width: 100%;
    overflow: hidden;
    padding: 16px;
    max-height: 470px;
    &__title {
        text-align: center;
        color: c.$gray900;
        @include t.text("xl", "semibold");
    }
    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        overflow: auto;
        flex: 1;
    }
}
