@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.taskDetail {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 12px;
        padding-bottom: 20px;
        background-color: c.$baseWhite;
        gap: 32px;

        &__column {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: fit-content;
            gap: 32px;
        }
    }
    &__disabledText {
        width: 100%;
        text-align: center;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        &__icon {
            color: c.$warning500;
        }
    }
    &__openIssue {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    &__buttons {
        position: fixed;
        bottom: 0;
        left: 0;
        overflow-y: hidden;
        background-color: c.$baseWhite;
        border-top: 1px solid c.$gray200;
        box-shadow: c.$shadow-up;
        height: 64px;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 12px;
        &__reopen {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        &__cancel {
            display: none;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .taskDetail {
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            gap: 32px;
            height: 100%;
            overflow-y: initial !important;
            padding: 32px;
            &__column {
                justify-content: space-between;
                width: 47%;
                gap: 32px;
                height: fit-content;
                &.flexStart {
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
            scrollbar-width: auto;

            &::-webkit-scrollbar {
                width: 5px !important;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
            }
        }
        &__buttons,
        &__buttons__cancel {
            gap: 8px;
            position: relative;
            height: fit-content;
            display: flex;
            flex-direction: row;
            box-shadow: none;
        }
    }
}
