@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.printAuditReport {
    padding: 20px 0px;
    margin: 0 auto;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__header {
        display: flex;
        justify-content: space-between;
        @include t.text("md", "semibold");
        color: c.$appcc500;
        &__title {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
}

.pagebreak {
    page-break-after: always;
}

@media print {
    body {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        display: block;
    }
    .auditReport,
    .globalContainer {
        height: auto;
    }
}
