@use "/src/styles/colors.scss" as c;

.pagination {
    display: flex;
    align-items: center;
    gap: 16px;
    &__details {
        color: c.$gray700;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        min-width: 50px;
        &__of {
            @extend .pagination__details;
            font-weight: 400;
        }
    }
}
