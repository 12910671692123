@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

.tabskeleton {
    height: 40px;
    width: 90px;
    display: flex;
    gap: 18px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 8px;

    &__text {
        height: 12px;
        width: 80px;
        border-radius: 27px;
        @include m.skeleton-is-loading(c.$primary100);
    }
    &__underline {
        height: 2px;
        width: 100%;
        @include m.skeleton-is-loading(c.$primary100);
    }
}
