@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/mixin/clamp" as m;
@use "/src/styles/variables.scss" as v;

.auditIssuePill {
    background-color: c.$baseWhite;
    border: 1px solid c.$gray200;
    box-shadow: v.$shadow-light;
    padding: 12px;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;

    &--fullHeight {
        @extend .auditIssuePill;
        height: 100%;
    }

    &__body {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        &__description {
            @include t.text("xs", "regular");
            color: c.$gray700;
            @include m.textclamp(2);
        }
        &__icons {
            display: flex;
            gap: 4px;
        }
        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 20px;
            height: 20px;
            &__svg {
                color: c.$gray600;
                font-size: 16px;
            }
        }
    }
}
