@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;
@use "src/styles/typographies.scss" as t;

.mobileFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 52px;
    padding: 0 20px;
    position: absolute;
    bottom: 0;
    z-index: 7;
    &--primary {
        background-color: c.$primary600;
        color: c.$primary25;
    }
    &--appcc {
        border-top: 1px solid c.$gray200;
        background: c.$baseWhite;
    }

    &__iconContainer {
        width: 40px;
        display: flex;
        justify-content: center;
    }
}

.qrIcon {
    width: 25px;
    height: 25px;
    color: c.$primary600;
}

.containerQr {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 2px;
    border: 4px solid c.$primary500;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: c.$baseWhite;
    &__ghostDiv {
        width: 60px;
    }
}

.containerQrIos {
    @extend .containerQr;
    bottom: 20px !important;
}

.mobileFooterModal {
    background-color: c.$baseWhite;
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px !important;
    top: unset !important;
    bottom: 52px !important;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: c.$shadow-2xl;
    z-index: 10 !important;
    color: c.$gray700;
    @include t.text("sm", "medium");
    right: 10px;

    &__container {
        width: 100%;
        opacity: 0;
        animation: fadeInAnimation 0.2s ease forwards;
        background-color: rgba(253, 253, 253, 0.274);
        backdrop-filter: blur(1px);
    }
    &__item {
        height: 50px;
        padding: 0 10px;
        width: 100%;
        user-select: none;
        cursor: pointer;
        &:last-child {
            border-top: 1px solid c.$gray300;
        }
        &--noBorder {
            border: none !important;
        }
    }
}
@media screen and (min-width: v.$tablet) {
    .mobileFooterModal {
        width: 30%;
    }
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
