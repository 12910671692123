@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.collapsableSidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    gap: 4px;
    transition: gap 200ms;
    background-color: c.$baseWhite;
    &--closed {
        @extend .collapsableSidebar;
        gap: 0;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        cursor: pointer;
        padding: 0 12px;
    }
    &__title {
        color: c.$primary500;
        @include t.text("xs", "medium");
        user-select: none;
    }
    &__icon {
        width: 15px;
        height: 15px;
        color: c.$primary500;
        font-size: 12px;
        transition: rotate 200ms;
        &--closed {
            @extend .collapsableSidebar__icon;
            rotate: 180deg;
        }
    }
    &__collapsable {
        display: grid;
        grid-template-rows: 1fr;
        overflow: hidden;
        transition: grid-template-rows 200ms;
        &--closed {
            @extend .collapsableSidebar__collapsable;
            grid-template-rows: 0fr;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        min-height: 0;
    }
}
