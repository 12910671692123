@use "/src/styles/variables.scss" as v;

.customRepeatEveryInputSelectContainer {
    display: flex;
    gap: 6px;
    width: 100%;
    flex-direction: column;
}

.taskEndsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.taskEndsContainer__radioInput {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: row;
    &__date {
        width: 100%;
    }
}
.taskendsNever {
    height: 36px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

@media screen and (min-width: 901px) {
    .customRepeatEveryInputSelectContainer {
        flex-direction: row;
    }
}
