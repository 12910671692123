@use "src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.alertNotificationHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
    }
    &__icon {
        width: 20px;
        height: 19px;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: c.$warning700;
        &__shadow-big {
            min-width: 48px;
            min-height: 48px;
            background-color: c.$warning100;
            border: 8px solid c.$warning50;
            border-radius: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        margin: 0;
        @include t.text("lg", "semibold");
    }

    &__total {
        padding: 3px 12px 3px 12px;
        background-color: c.$primary50;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 3px 12px;
        gap: 4px;
        border-radius: 27px;
        @include t.text("xs", "semibold");
        &__currentIndex {
            color: c.$primary500;
        }
        &__slashTotal {
            color: c.$gray500;
        }
    }
}
