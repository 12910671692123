@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.roleCollapseBodyTitles {
    display: grid;
    grid-template-columns: 200px repeat(5, minmax(90px, 1fr));
    width: 100%;
    &__title {
        @include t.text("sm", "medium");
        color: c.$gray700;
        text-align: center;
    }
}
