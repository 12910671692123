@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.whitebox {
    background-color: c.$baseWhite;
    border: 1px solid c.$gray200;
    box-shadow: v.$shadow-light;
    padding: 12px;
    border-radius: 8px;

    &--fullHeight {
        @extend .whitebox;
        height: 100%;
    }
    &--fullWidth {
        width: 100%;
    }
}

@media screen and (min-width: v.$mobile) {
    .whitebox {
        padding: 20px;
    }
}
