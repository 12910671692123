@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;
@use "/src/styles/mixin/clamp" as clamp;

.wizardStepBarItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
    cursor: pointer;
    &--disabled {
        user-select: none;
        pointer-events: none;
        cursor: default;
    }
    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    &__title {
        display: none;
    }
}

@media screen and (min-width: v.$tablet) {
    .wizardStepBarItem__title {
        max-width: 200px;
        text-align: center;
        color: c.$gray700;
        @include t.text("sm", "semibold");
        @include clamp.textclamp();
    }
}
