@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.issueBodyAttachments {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
    }
    &__attachments {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 6px;
        flex-wrap: wrap;
        width: 100%;
    }
}
