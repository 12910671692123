@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.fullScreenQrModalManual {
    display: flex;
    flex-direction: column;
    gap: 32px;
    &__title {
        color: c.$baseWhite;
        text-align: center;
        text-wrap: balance;
        @include t.text("md", "medium");
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}
