@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.headerAnalyst {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    &__info {
        display: flex;
        align-items: center;
        gap: 12px;
        &__names {
            display: flex;
            flex-direction: column;
            gap: 4px;
            height: 100%;
        }
        &__title {
            color: c.$baseWhite;
            @include t.text("xl", "semibold");
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .headerAnalyst {
        flex-direction: row;
        justify-content: space-between;
    }
}
