@use "/src/styles/colors.scss" as c;

.dropdownList {
    display: flex;
    padding: 4px 0px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    user-select: none;
}
