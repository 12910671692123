@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.avatarRating {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    width: 100%;
    &__userInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        &__name {
            color: c.$baseWhite;
            @include t.text("md", "semibold");
        }
        &__text {
            color: c.$baseWhite;
            font-size: 11.386px;
            line-height: 17.079px;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .avatarRating {
        justify-content: flex-start;
    }
}
