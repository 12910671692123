@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.headerItemRight {
    &__text {
        display: flex;
        align-items: center;
        gap: 3px;
        color: c.$baseWhite;
        @include t.text("sm", "semibold");
        &:hover {
            @extend .headerItemRight__text;
        }
        &::first-letter {
            text-transform: uppercase;
        }
    }
}
