@use "/src/styles/colors.scss" as c;

@mixin switchBallFocused {
    @extend .switchContainer__switch__ball;
    width: 20px;
    transition: all 100ms;
}

.switchContainer {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-direction: row;
    &--reverse {
        @extend .switchContainer;
        flex-direction: row-reverse;
    }
    &__switch {
        width: 36px;
        min-width: 36px;
        height: 20px;
        background-color: c.$gray200;
        border-radius: 9999px;

        cursor: pointer;
        position: relative;
        border: 2px solid transparent;
        transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);

        &:hover {
            background-color: c.$gray300;
        }

        &--primary {
            @extend .switchContainer__switch;
        }
        &--warning {
            @extend .switchContainer__switch;
        }
        &--danger {
            @extend .switchContainer__switch;
        }
        &--active {
            @extend .switchContainer__switch;
            &--primary {
                @extend .switchContainer__switch--active;
                background-color: c.$primary500;
                &:hover {
                    background-color: c.$primary600;
                }
            }
            &--warning {
                @extend .switchContainer__switch--active;
                background-color: c.$warning500;
                &:hover {
                    background-color: c.$warning600;
                }
            }
            &--danger {
                @extend .switchContainer__switch--active;
                background-color: c.$error500;
                &:hover {
                    background-color: c.$error600;
                }
            }

            &--focused {
                @extend .switchContainer__switch;
                &--primary {
                    @extend .switchContainer__switch--focused;
                    background-color: c.$primary700;
                }
                &--warning {
                    @extend .switchContainer__switch--focused;
                    background-color: c.$warning700;
                }
                &--danger {
                    @extend .switchContainer__switch--focused;
                    background-color: c.$error700;
                }
            }
        }

        &--focused {
            @extend .switchContainer__switch;
            background-color: c.$gray400;

            &--primary {
                @extend .switchContainer__switch;
            }
            &--warning {
                @extend .switchContainer__switch;
            }
            &--danger {
                @extend .switchContainer__switch;
            }
            &:hover {
                background-color: c.$gray400;
            }
        }

        &.disabled {
            cursor: default;
            background: c.$gray100;
            &:hover {
                background: c.$gray100;
            }
        }

        &__ball {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            position: absolute;
            background-color: c.$baseWhite;
            left: 0;
            transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
            box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
            &.disabled {
                cursor: default;
            }
            &--focused {
                @include switchBallFocused;
            }

            &--active {
                @extend .switchContainer__switch__ball;
                left: 16px;
                &--focused {
                    left: 12px;
                    @include switchBallFocused;
                }
            }
        }
    }
}

.invertedSwitch {
    background-color: transparent;
    border-color: white;
    transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
        background-color: transparent;
    }
    &--active {
        background-color: c.$baseWhite;

        &:hover {
            background-color: c.$baseWhite;
        }
    }
    &__ball {
        background-color: c.$baseWhite;
        transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);

        &:hover {
            background-color: c.$baseWhite;
        }
        &--active {
            background-color: c.$primary700;
            &:hover {
                background-color: c.$primary700;
            }
        }
    }
}
