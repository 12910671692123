@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.multi-percentage-bar {
    height: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    &--container {
        width: 100%;
        &--lateral {
            display: flex;
            align-items: center;
            gap: 20px;
            width: 100%;
        }
    }
    &-percentage {
        margin-top: 12px;
    }

    &-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 14px;
        font-weight: bold;
        position: relative;
        min-width: 70px;
        border-right: 1px solid white;
        &--lightRed {
            @extend .multi-percentage-bar-section;
            background-color: c.$error300;
        }
        &--red {
            @extend .multi-percentage-bar-section;
            background-color: c.$error500;
        }
        &--lightYellow {
            @extend .multi-percentage-bar-section;
            background-color: c.$yellow200;
        }
        &--yellow {
            @extend .multi-percentage-bar-section;
            background-color: c.$yellow400;
        }
        &--lightGreen {
            @extend .multi-percentage-bar-section;
            background-color: c.$success200;
        }
        &--green {
            @extend .multi-percentage-bar-section;
            background-color: c.$success300;
        }
        &--lightBlue {
            @extend .multi-percentage-bar-section;
            background-color: c.$primary300;
        }
        &--blue {
            @extend .multi-percentage-bar-section;
            background-color: c.$primary600;
        }
        &--lightBlue {
            @extend .multi-percentage-bar-section;
            background-color: c.$primary300;
        }
        &--darkRed {
            @extend .multi-percentage-bar-section;
            background-color: c.$error700;
        }

        &:last-child {
            border-right: none;
        }
    }

    .progress-label {
        margin-bottom: 4px;
    }

    .progress-percentage {
        @include t.text("lg", "semibold");
        color: white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
