@use "/src/styles/colors.scss" as c;

@mixin paginationIcon() {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.paginationArrow {
    display: inline-flex;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 2px;
    cursor: pointer;
    transition: background 300ms;
    &--primary {
        @extend .paginationArrow;

        &:hover {
            border-radius: 2px;
            background: c.$primary50;
        }
        &--focused {
            &:hover {
                border-radius: 2px;
                background: c.$primary100;
            }
        }
    }
    &--appcc {
        @extend .paginationArrow;

        &:hover {
            border-radius: 2px;
            background: c.$appcc100;
        }
        &--focused {
            &:hover {
                border-radius: 2px;
                background: c.$appcc200;
            }
        }
    }

    &--disabled {
        cursor: default;
        &:hover {
            border-radius: 2px;
            background: transparent;
        }
    }

    &__svg {
        @include paginationIcon;
        &--primary {
            color: c.$primary600;
        }
        &--appcc {
            color: c.$appcc600;
        }
        &--disabled {
            color: c.$gray300;
        }
    }
}
