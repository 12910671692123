.dashboardFilter {
    &__company {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__checkboxes {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        &__container {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}
