@use "/src/styles/variables.scss" as v;

.baseTaskExtraData {
    grid-column: 1 / 2;
    display: flex;
    width: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

@media screen and (min-width: v.$tablet) {
    .baseTaskExtraData {
        grid-column: 2 / 3;
    }
}
