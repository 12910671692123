.closeRangeDates {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    &__select {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    &__button {
        display: flex;
        align-items: flex-start;
        padding: 9px 0;
    }
}
