@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.message {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;
    line-break: auto;
    word-break: break-word;
    &__audio {
        border: 1px solid c.$primary200;
        border-radius: 8px 0px 8px 8px;
        background-color: c.$primary50;
        height: 40px;
        width: 100%;
        &::-webkit-media-controls-panel {
            background-color: c.$primary50;
        }
    }
    &__userName {
        margin: 5px;
    }
    &__row {
        display: flex;
        align-items: flex-end;
        align-items: flex-start;
        gap: 8px;
        max-width: 90%;
        &--maxWidthFitContent {
            @extend .message__row;
            max-width: fit-content;
        }
    }
    &__profile {
        width: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include t.text("xs");
        color: c.$gray600;
        gap: 5px;
    }
    &__container {
        min-width: 245px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-end;
        width: 100%;
        &__nameDate {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &__flex {
            display: flex;
            align-items: center;
            gap: 5px;
            .checkIcon {
                font-size: 12px;
                color: c.$primary500;
            }
        }
        &__userName {
            @include t.text("xs", "medium");
            color: c.$gray700;
        }
    }
    &__flex {
        display: flex;
        gap: 6px;
    }
    &__text {
        position: relative;
        padding: 10px 14px;
        background-color: c.$primary600;
        color: c.$baseWhite;
        display: inline-block;
        border-radius: 8px 0px 8px 8px;
        min-width: 100%;
        @include t.text("sm");
    }

    &__date {
        @include t.text("xs");
        color: c.$gray600;
    }
    &__deleteIcon {
        color: c.$primary500;
        margin-right: 10px;
        cursor: pointer;
        font-size: 12px;
    }
    &__img {
        width: 100%;
        height: 100%;
        min-height: 40px;
        max-height: 200px;
        cursor: pointer;
        border: 1px solid c.$primary200;
        border-radius: 8px 0px 8px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
    }
}

.message__other {
    @extend .message;
    align-items: flex-start;
    .message__container {
        @extend .message__container;
        align-items: flex-start;
        .message__container__flex {
            @extend .message__container__flex;
            margin-left: 15px;
        }
    }

    .message__text {
        @extend .message__text;
        background-color: c.$gray100;
        border-radius: 0px 8px 8px 8px;
        color: c.$gray900;
    }
    .message__img {
        @extend .message__img;
        border-radius: 0px 8px 8px 8px;
    }

    .message__audio {
        @extend .message__audio;
        border-radius: 0px 8px 8px 8px;
    }

    .message__date {
        @extend .message__date;
        align-self: flex-end;
    }
}
