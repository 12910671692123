@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.audioRecorder {
    width: 100%;
    max-width: 250px;
    &__playContainer {
        display: flex;
        align-items: center;
    }

    &__container {
        display: flex;
        gap: 12px;
        align-items: center;

        &__recording {
            display: flex;
            align-items: center;
            font-family: verdana, sans-serif;
            color: c.$danger;
            @include t.text("sm", "medium");
        }
    }
}

.audioRecorder__container__recording::before {
    content: "🔴";
    left: 5px;
    animation: recording 600ms alternate infinite;
    margin-right: 10px;
}

@keyframes recording {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.2;
    }
}
