@use "/src/styles/variables.scss" as v;

.issueHistoryReopen {
    height: 450px;
    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
        padding-bottom: 12px;
    }
}

@media screen and (min-width: v.$tablet) {
    .issueHistoryReopen {
        &__container {
            padding-bottom: 32px;
        }
    }
}
