.automaticRecord {
    &__center {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__stretch {
        width: 130px;
    }
}
