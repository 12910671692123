@use "src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.mySlides {
    position: relative;
    &__image {
        display: block;
        width: 100%;
        z-index: 4;
        height: 400px;
        object-fit: cover;
    }

    &--show {
        position: relative;
        height: 100%;
        width: 100%;
        display: block;
    }

    &--hide {
        position: relative;
        display: none;
    }

    &--fullScreen {
        position: relative;
        width: auto;
    }
}

.zoomSlider {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 12px;
    bottom: 12px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;

    &--desktop {
        left: 4px;
        bottom: 4px;
    }

    &__icon {
        @include t.text("md", "medium");
        color: c.$baseWhite;
    }
}
.swiper__number {
    color: #f2f2f2;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding: 3px 8px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    border-radius: 27px;
    z-index: 1;
}
.swiper-pagination {
    display: flex;
    justify-content: center;
    &-bullet {
        width: 5px !important;
        height: 5px !important;
        background-color: c.$gray600 !important;
        &-active {
            background-color: c.$primary500 !important;
        }
    }
}
.swiper-button-prev,
.swiper-button-next {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    border-radius: 4px !important;
    width: 28px !important;
    height: 28px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px !important;

    &::after {
        @include t.text("md", "medium");
        color: c.$baseWhite;
    }
}
