@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.fullScreenQrModalManualPlaceholder {
    display: flex;
    padding: 32px 32px 40px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 12px;
    background-color: c.$primary800;
    &__title {
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 10px;
        color: c.$baseWhite;
    }
}
