@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;

.secondaryFilter {
    height: 100%;
    width: 100%;
    background-color: c.$baseWhite;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &__header {
        height: 48px;
        min-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: c.$baseWhite;
        @include t.text("md", "semibold");
        &--primary {
            background-color: c.$primary600;
        }
        &--appcc {
            background-color: c.$appcc600;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 20px;
        padding: 12px;
        overflow-y: scroll;
        &__filter {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}
