@use "/src/styles/colors.scss" as c;

.addUsersModal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    &__container {
        width: 100%;
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 32px;
    }
    &__dropdown {
        width: 100%;
    }
    &__button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
        border-top: 1px solid c.$gray200;
    }
}
