.issueGrid {
    &__id {
        min-width: 70px;
        width: 70px;
    }
    &__auditCode {
        min-width: 150px;
        width: 30%;
    }
    &__description {
        min-width: 200px;
        width: 40%;
    }
    &__asset {
        min-width: 150px;
        width: 35%;
    }
    &__openDate {
        min-width: 100px;
        width: 40%;
    }
    &__actualStatus {
        min-width: 85px;
        width: 85px;
        &__row {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
    &__edit {
        min-width: 80px;
        width: 80px;
    }
}
