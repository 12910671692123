@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.stepFiveWizardTask {
    gap: 12px;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.stepFiveLabel {
    color: c.$gray500;
    @include t.text("sm", "medium");
}
.stepFivePlaceHolderInput {
    color: c.$gray900;
    @include t.text("md", "regular");
}

.stepFivePlaceHolderInput__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.taskFinishedInfo {
    display: flex;
    flex-direction: column;
}

.taskStepFourInfo {
    display: flex;
    gap: 12px;
    flex-direction: column;
}
