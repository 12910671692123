.notificationDistributionList {
    &__actions {
        width: 100px;
    }
    &__center {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__modal {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        &__spinner {
            display: flex;
            justify-content: center;
            height: 100%;
            min-height: 68px;
        }
    }
}
