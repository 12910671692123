@use "/src/styles/colors.scss" as c;

.chatbotGPT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    &__conversation {
        overflow: scroll;
        scroll-behavior: auto;
        display: flex;
        padding: 12px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 6px 6px 0px 0px;
        outline: 1px solid c.$gray300;
        background: c.$baseWhite;
        flex-direction: column;
        height: 100%;
    }
}
