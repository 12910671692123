@use "/src/styles/variables.scss" as v;

.systemMessage {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid v.$dark-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    &__info {
        display: flex;
        justify-content: space-between;
        color: v.$dark-gray;
        font-size: 12px;
        align-items: center;
        &__icon {
            font-weight: 600;
        }
    }
    &__text {
        font-weight: 400;
        line-height: 1.1;
        &--bold {
            @extend .systemMessage__text;
            font-weight: 700;
        }
    }
    &:hover {
        background-color: v.$light-blue;
        cursor: pointer;
    }
}
