@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.groupedTaskField {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    border: 1px solid c.$gray300;
    padding: 8px;
    border-radius: 6px;

    &__header {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: space-between;
        align-items: center;

        &__title {
            align-self: flex-start;
            color: c.$gray500;
            @include t.text("sm", "semibold");
        }

        &__notWorking {
            display: flex;
            flex-direction: row;
            gap: 6px;
        }
    }
    &__switch {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
}
