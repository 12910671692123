@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileAuditRow {
    display: flex;
    width: 100%;
    padding: 12px;
    align-items: flex-start;
    gap: 20px;
    border-bottom: 1px solid c.$gray200;
    user-select: none;
    flex-direction: column;
    &--odd {
        @extend .mobileAuditRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .mobileAuditRow;
        background: c.$gray25;
    }
    &__header {
        display: flex;
        width: 100%;
        gap: 8px;
        align-items: flex-start;
        flex-direction: column;
        &__info {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            justify-content: space-between;
        }
        &__title {
            color: c.$gray800;
            @include t.text("xs", "medium");
        }
    }

    &__body {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        width: 100%;
        &__user {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            width: 100%;
            &--inProgress {
                @extend .mobileAuditRow__body__user;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
    &__username {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        color: c.$primary600;
        @include t.text("xs", "semibold");
    }
}
