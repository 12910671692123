@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.taskCheckListTitle {
    display: flex;
    padding-bottom: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-bottom: 1px solid c.$primary400;
    width: 100%;
    &__container {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 350px;
    }
}
