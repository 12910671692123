@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.roleGrid {
    &__cell {
        display: flex;
        align-items: center;
        gap: 8px;
        &__icon {
            font-size: 16px;
            color: c.$gray600;
            text-align: center;
        }
    }
    &__userCount {
        width: 60px;
    }
    &__actions {
        width: 120px;
    }

    &__temporary {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &__link {
            @include t.text("sm", "medium");
            color: c.$gray600;
            text-decoration: underline;
            text-align: end;

            &:hover {
                color: c.$gray700;
            }
        }
    }
}

.editRoleForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    &__loading {
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__buttons {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: flex-end;
        padding: 12px;
        border-top: 1px solid c.$gray300;
        width: calc(100% + 64px);
        margin-left: -32px;
    }
}

@media screen and (max-width: v.$mobile-767) {
    .editRoleForm {
        &__buttons {
            margin-left: -12px;
            width: calc(100% + 24px);
        }
    }
}
