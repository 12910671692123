@use "/src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.workerPill {
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: c.$shadow-md;
    gap: 14px;
    cursor: pointer;
    background-color: c.$baseWhite;
    &__info {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__icons {
                display: flex;
                align-items: center;
                gap: 25px;
                font-size: 20px;
                color: c.$primary700;
                &__icon {
                    cursor: pointer;
                }
            }
            .userValoration {
                gap: 4px;
            }
        }
    }
    &__tasks {
        width: 20%;
        height: 57px;
        background-color: c.$gray100;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: c.$gray600;

        &--error {
            @extend .workerPill__tasks;
            background-color: c.$error50;
            color: c.$error600;
        }
        &__number {
            @include t.text("lg", "semibold");
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .workerPill__info__line {
        .userValoration {
            gap: 12px;
        }
    }
}
