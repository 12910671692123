@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.legendItem {
    display: flex;
    padding: 2px 8px;
    align-items: center;
    gap: 8px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: background-color 300ms;
    transition: border-color 300ms;
    &__container {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
    }
    &__text {
        color: c.$gray700;
        @include t.text("sm", "medium");
    }
    &__percentage {
        width: 70px;
        flex-shrink: 0;
        @include t.text("lg", "semibold");
    }
    &--clickable {
        cursor: pointer;
        &--lightRed:hover,
        &--red:hover {
            @extend .legendItem--clickable;
            background-color: c.$error50;
            border-color: c.$error600;
        }
        &--lightDarkRed:hover,
        &--darkRed:hover {
            @extend .legendItem--clickable;
            background-color: c.$error100;
            border-color: c.$error800;
        }
        &--lightYellow:hover,
        &--yellow:hover {
            @extend .legendItem--clickable;
            background-color: c.$yellow50;
            border-color: c.$yellow500;
        }
        &--lightGreen:hover,
        &--green:hover {
            @extend .legendItem--clickable;
            background-color: c.$success50;
            border-color: c.$success500;
        }
        &--lightBlue:hover,
        &--blue:hover {
            @extend .legendItem--clickable;
            background-color: c.$primary50;
            border-color: c.$primary500;
        }
    }
}
