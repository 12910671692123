@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.planAPPCCGridHeader {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    &__title {
        color: c.$baseWhite;
        @include t.text("xl", "semibold");
    }
    &__select {
        width: 100%;
    }
}

@media screen and (min-width: v.$mobile) {
    .planAPPCCGridHeader {
        flex-direction: row;
        &__select {
            width: 400px;
        }
    }
}
