@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

@mixin disabledBadgeTitle() {
    color: c.$gray600 !important;
}

.badgeTitle {
    &--blue {
        &.light {
            color: c.$primary700;
        }
        &.dark {
            color: c.$primary100;
        }
    }
    &--grey {
        &.light {
            color: c.$gray700;
        }
        &.dark {
            color: c.$baseWhite;
        }
    }
    &--red {
        &.light {
            color: c.$error700;
        }
        &.dark {
            color: c.$error100;
        }
    }
    &--orange {
        &.light {
            color: c.$warning700;
        }
        &.dark {
            color: c.$warning100;
        }
    }
    &--green {
        &.light {
            color: c.$success700;
        }
        &.dark {
            color: c.$success100;
        }
    }
    &--yellow {
        &.light {
            color: c.$yellow600;
        }
        &.dark {
            color: c.$yellow100;
        }
    }
    &--purple {
        &.light {
            color: c.$purple900;
        }
        &.dark {
            color: c.$purple100;
        }
    }

    &--deepBlue {
        &.light {
            color: c.$primary700;
        }
        &.dark {
            color: c.$primary100;
        }
    }
    &--deepGreen {
        &.light {
            color: c.$green600;
        }
        &.dark {
            color: c.$green100;
        }
    }

    &--disabled {
        @include disabledBadgeTitle();
    }

    &--sm,
    &--md,
    &--lg {
        @include t.text("xs", "medium");
        &--bolder {
            @include t.text("xs", "bold");
        }
    }

    &--clamp {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        width: 100%;
        word-break: break-word;
    }
}
