@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;

.timePicker {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    &__label {
        display: flex;
        align-items: center;
        gap: 12px;
        @include t.text("sm", "medium");
        color: c.$gray700;
    }
    &__input {
        width: 100%;
        height: 40px;
        outline: none;
        padding: 10px 14px 10px 10px;
        @include t.text("md");
        color: c.$gray900;
        border: 1px solid c.$gray300;
        border-radius: 6px;

        &:focus {
            @extend .timePicker__input;
            border: 2px solid c.$primary500;
        }
        &--error {
            @extend .timePicker__input;
            border: 1px solid c.$error600;
            &:focus {
                @extend .timePicker__input;
                border: 2px solid c.$error600;
            }
        }
    }
    &__error {
        @include t.text("sm");
        color: c.$error600;
    }
    &__hint {
        @include t.text("sm");
        color: c.$gray600;
    }
}
