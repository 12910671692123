@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.newUserTabs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__header {
        display: flex;
        &__container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            &__icon {
                width: 20px;
                height: 20px;
                color: c.$baseWhite;
                cursor: pointer;
            }
            &__username {
                color: c.$baseWhite;
                @include t.text("sm", "semibold");
                &--lg {
                    @extend .newUserTabs__header__container__username;
                    @include t.text("lg", "semibold");
                }
            }
        }
    }
}

.userPage {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__button {
        display: flex;
        justify-content: flex-end;
    }
}
