@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;
@use "/src/styles/typographies.scss" as t;

.crudLayoutLeftNode {
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;

    &--column {
        @extend .crudLayoutLeftNode;
        flex-direction: column;
    }

    &__title {
        color: c.$baseWhite;
        text-align: left;
        &--column {
            @extend .crudLayoutLeftNode__title;
            text-align: center;
        }
        @include t.text("lg", "semibold");
    }
    &__customHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        width: 100%;
    }
}

.crudHeaderChildren {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__leftNodeButtons {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        flex-shrink: 0;
    }
}

@media screen and (min-width: v.$mobile) {
    .crudLayoutLeftNode {
        flex-direction: row;
        &__title {
            text-align: left;
        }
        &__customHeader {
            display: flex;
            flex-direction: row;
            gap: 12px;
        }
    }
}
