@use "/src/styles/colors.scss" as c;

.stepFourTaskWizard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.recurrencyCheckboxStepFour {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.stepFourInput__wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    flex-direction: column;
    width: 100%;
}

.stepFourAddHourInput {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 999px;
    color: c.$error700;
    font-weight: 600;
    font-size: 18px;
    margin-left: 0.5rem;
    cursor: pointer;
    padding: 3px;
    margin-left: 1rem;
    align-self: center;
}

.stepFourRCustomOptionDays {
    height: 30px;
    width: 30px;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: c.$primary500;
    color: white;
    cursor: pointer;
}

.stepFourRCustomOptionDays.active {
    background-color: #ee9f0d;
    color: c.$baseWhite;
}

.stepFourRadioButtons__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    width: 344px;
}

.step_four_custom_select {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
}

.wizard_custom_day_bubles__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;
}

.step_four_repeat_every input[type="text"] {
    width: 100%;
}

.step_four_custom_select__inputNumber {
    width: 70px;
    margin-left: 16px;
}

.stepFourInput__wrapper__taskHour {
    display: flex;
    align-items: center;
    gap: 2rem;
    position: relative;
}

.step_four_custom_select .mainInputNumber__wrapper {
    width: 15%;
    min-width: 80px;
}
