@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
.percentageStars {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    &__title {
        @include t.text("md", "semibold");
    }
    &__ratings {
        gap: 8px;
    }
    &__progressBar {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        &__starNumber {
            color: c.$gray600;
            @include t.text("sm", "medium");
            width: 73px;
            min-width: 73px;
        }
        &__percentage {
            color: c.$gray600;
            @include t.text("sm", "medium");
            width: 55px;
            min-width: 55px;
        }
    }
}

.percentageStarsLine {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @include t.text("sm", "medium");
    color: c.$gray600;

    &__title {
        min-width: 80px;
        white-space: nowrap;
    }

    &__container {
        width: 100%;
        height: 8px;
        background-color: c.$gray200;
        position: relative;
        border-radius: 8px;
    }
    &__line {
        height: 100%;
        background-color: c.$primary600;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        &__container {
            display: flex;
            align-items: center;
            gap: 12px;
            width: 80%;
        }
    }
    &__percentage {
        min-width: 50px;
        text-align: right;
    }
}

.rankingPill__info__ratings {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.percentageStarsLine__line__container--justifyStart {
    justify-content: flex-start !important;
    &__title {
        min-width: 0px;
        @include t.text("xs", "medium");
        color: c.$gray700;
    }
}
