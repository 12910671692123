@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.wizardContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 12px;
        padding-bottom: 0;
        flex: 1;
        overflow: hidden;
    }
    .stepsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        overflow-y: auto;
        height: 100%;
    }
    .wizardFooter {
        padding: 12px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;
        &--between {
            @extend .wizardFooter;
            justify-content: space-between;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .wizardContainer {
        &__container {
            padding: 32px;
            padding-bottom: 0;
        }
    }
}
