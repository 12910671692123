@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.issueMobileRowHeader {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &__info {
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__icon {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        height: 100%;
        &__edit {
            height: 20px;
            width: 20px;
            color: c.$gray600;
        }
    }
}
