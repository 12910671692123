@use "src/styles/colors.scss" as c;

.starPercentage {
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    position: relative;

    &__star {
        margin-right: 2px;
        display: flex;
        color: c.$yellow400;
        &--md {
            @extend .starPercentage__star;
            width: 20px;
            height: 20px;
        }
        &--xs {
            @extend .starPercentage__star;
            width: 14px;
            height: 14px;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__overlay {
        background-color: c.$primary600;
        mix-blend-mode: color;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        &--gray {
            @extend .starPercentage__overlay;
            background-color: c.$gray200;
        }
        &--white {
            @extend .starPercentage__overlay;
        }
    }
}
