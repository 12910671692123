@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.attachedFile {
    background-color: c.$primary50;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px 10px 10px;
    width: 100%;
    height: 48px;
    border-radius: 8px 0px 8px 8px;
    border: 1px solid c.$primary200;
    cursor: pointer;
    gap: 10px;
    &--other {
        @extend .attachedFile;
        border-radius: 0px 8px 8px 8px;
    }
    &__text {
        color: c.$primary600;
        @include t.text("sm", "medium");
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    &__icon {
        font-size: 16px;
        color: c.$primary600;
    }
}

.attachedFileSmall {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    gap: 8px;
    height: 32px;
    background: c.$primary50;
    border: 1px solid c.$primary200;
    border-radius: 4px;
    overflow-wrap: break-word;
    max-width: 100%;
    width: fit-content;
}
