@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

@mixin skelletonTextContainer($height) {
    height: $height;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
}

.avatarRating-skelleton {
    height: 64px;
    justify-content: space-between;
    &-73 {
        @extend .avatarRating-skelleton;
        height: 73px;
    }
    &--center {
        @extend .avatarRating-skelleton;
        justify-content: center;
    }
    &__userInfo {
        &__text {
            height: 16px;
            width: 120px;
            border-radius: 12px;
            @include m.skeleton-is-loading(c.$primary100);
        }

        &__stars {
            height: 16px;
            width: 150px;
            border-radius: 12px;
            @include m.skeleton-is-loading(c.$primary300);
        }

        &__supervisorText {
            height: 11px;
            width: 170px;
            border-radius: 12px;
            @include m.skeleton-is-loading(c.$primary300);
        }
    }
    &-17 {
        @include skelletonTextContainer(17px);
        justify-content: center;
    }
    &-24 {
        @include skelletonTextContainer(24px);
        justify-content: center;
    }
    &-28 {
        @include skelletonTextContainer(28px);
        justify-content: center;
    }
    &-45 {
        @include skelletonTextContainer(45px);
    }
}
