.multiTaskHour {
    display: flex;
    gap: 12px;
    flex-direction: column;
    width: 100%;

    &__wrapper {
        display: flex;
        gap: 12px;

        &__separator {
            position: absolute;
            top: 30px;
            left: 95px;
        }
    }
    &__deleteMultiHour {
        display: flex;
        align-items: flex-end;
        margin-bottom: 12px;
    }
}

.taskHourContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;

    &__inputBtn {
        display: flex;
        gap: 6px;
        width: 100%;
        align-items: center;
        &--alone {
            @extend .taskHourContainer__inputBtn;
            width: 50%;
            min-width: 219px;
        }
    }
}
