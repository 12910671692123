@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.assetSensorInfo {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        gap: 12px;
        &__content {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            &__noData {
                @include t.text("md");
                color: c.$gray500;
            }
            &__badges {
                display: flex;
                flex-direction: row;
                gap: 12px;
            }
        }
    }
}

@media screen and (min-width: v.$mobile) {
    .assetSensorInfo {
        &__container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            &__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
            }
        }
    }
}
