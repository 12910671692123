@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.tableNoTabHeader {
    display: flex;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    padding: 12px;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    &--primary {
        background-color: c.$primary600;
    }
    &--appcc {
        background-color: c.$appcc600;
    }

    &__title {
        @include t.text("md", "medium");
        color: c.$baseWhite;
    }
}
