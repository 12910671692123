@use "/src/styles/typographies.scss" as t;

.editNotificationDistributionListUsersModal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__title {
        display: flex;
        @include t.text("sm");
    }
    &__addAllUsers {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    &__label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
    }
}
