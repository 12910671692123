@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.systemNotifications {
    overflow: hidden;
    height: 100%;
    background-color: white;
    &__seen {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px;
        width: 100%;
        user-select: none;
        &__text {
            color: c.$primary700;
            @include t.text("sm", "semibold");
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        &--alerts {
            padding: 5px 0 0 0;
            overflow: scroll;
            height: 100%;
            user-select: none;
        }
    }
    &__list {
        overflow: scroll;
        height: 100%;
        user-select: none;
    }
}

.systemNotifications__list::-webkit-scrollbar {
    width: 8px !important;
    height: 50%;
    min-height: 10px;
    border-radius: 8px;
}

.systemNotifications__list::-webkit-scrollbar-thumb {
    background: c.$gray200;
}

.systemNotifications__list::-webkit-scrollbar-thumb:hover {
    background: c.$gray400;
}

@media screen and (min-width: v.$tablet) {
    .systemNotifications__seen--alerts {
        width: 100%;
        height: 100%;
        overflow: scroll;
        user-select: none;
    }
}
