@use "src/styles/colors.scss" as c;

.filterFeedback {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__container {
        display: flex;
        align-items: center;
        gap: 12px;
        &__stars {
            height: 24px;
            display: flex;
            align-items: center;
        }
        &__starsIcon {
            width: 20px;
            height: 20px;
            color: c.$warning400;
            &--disabled {
                @extend .filterFeedback__container__starsIcon;
                color: c.$gray200;
            }
        }
    }
}
