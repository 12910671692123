@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.pendingTasksCompanyDepartmentModal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 450px;
    }
    &__title {
        @include t.text("md", "regular");
    }
}
