.taskTemporalityDateRangeWeekly {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
    width: 100%;
    &__bubbles {
        display: flex;
        justify-content: flex-start;
        gap: 4px;
        width: 100%;
    }
}
