@use "/src/styles/variables.scss" as v;

.editBaseTask {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__container {
        display: flex;
        height: 100%;
        flex-direction: column;
    }
    &__basicInfo {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
    }
}

@media screen and (min-width: v.$tablet) {
    .editBaseTask {
        &__basicInfo {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .editBaseTask {
        &__basicInfo {
            padding: 0;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
