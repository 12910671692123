@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.mobileHeader {
    display: flex;
    width: 100%;
    min-height: 48px;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 12px;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    &--primary {
        @extend .mobileHeader;
        background-color: c.$header-bg;
    }
    &--transparent {
        @extend .mobileHeader;
        background-color: transparent;
    }
    &--grey {
        @extend .mobileHeader;
        background-color: c.$gray600;
    }
    &--image {
        @extend .mobileHeader;
        background: url("../../../../public/headerBackground.jpg");
    }
    &--absolute {
        @extend .mobileHeader;
        position: absolute;
        width: 100%;
        z-index: 2;
    }
    &--removePadding {
        padding: 0;
    }

    &__container {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 12px;
    }

    &__navigation {
        display: flex;
        align-items: center;
        width: 100%;
        &__arrowBack {
            padding: 10px;
            font-size: 20px;
            cursor: pointer;
            color: c.$baseWhite;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .mobileHeader {
        flex-wrap: nowrap;
        &--auto {
            height: auto;
        }
    }
}
