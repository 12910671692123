@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.dateTimePicker {
    display: flex;
    flex-direction: column;
    gap: 6px;
    &__dateTime {
        width: 100%;
        height: 40px;
        font-size: 16px;
        border-radius: 6px;
        border: 1px solid c.$gray300;
        color: c.$gray900;
        padding: 8px 12px;
        outline: none;
        @include t.text("md");
        &::-webkit-calendar-picker-indicator {
            cursor: pointer;
            filter: invert(31%) sepia(0%) saturate(199%) hue-rotate(216deg) brightness(101%) contrast(94%);
            font-size: 16px;
        }
        &--error {
            border-color: c.$error600;
        }
    }
}
