.entityPendingTasksCard {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    &__company {
        display: flex;
        gap: 12px;
    }
    &__dates {
        display: flex;
        align-items: center;
        padding-left: 32px;
        width: 300px;
    }
}
