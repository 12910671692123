@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;
.progresssBarWithLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
    &__leftLabel {
        max-width: 250px;
        @include t.text("sm", "medium");
        &--fullWidth {
            width: 100%;
        }
    }
    &__bar {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;
        &__barLabel {
            &--xs {
                color: c.$gray700;
                @include t.text("xs", "medium");
            }

            &--sm {
                color: c.$gray700;
                @include t.text("sm", "medium");
            }

            &--md {
                min-width: 75px;
                @include t.text("lg", "semibold");
            }
        }
    }
}
