@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.selectDataToReportAsset {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-right: 0;
    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
    &__title {
        color: c.$primary600;
        @include t.text("sm", "semibold");
    }
}
