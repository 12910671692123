@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        animation: spinner 0.6s linear infinite;
        &--primary {
            border: 3px solid c.$main-color-transparent;
            border-top-color: c.$primary500;
        }
        &--danger {
            border: 3px solid c.$error200;
            border-top-color: c.$error500;
        }
        &--purple {
            border: 3px solid c.$purple200;
            border-top-color: c.$purple500;
        }
        &--appcc {
            border: 3px solid c.$appcc200;
            border-top-color: c.$appcc500;
        }
    }
}

@keyframes spinner {
    to {
        transform: rotateZ(360deg);
    }
}

.fullScreenSpinner {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: v.$tablet) {
    .fullScreenSpinner .spinner__icon {
        border-width: 5px;
        height: 48px;
        width: 48px;
    }
}
