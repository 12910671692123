@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.chatBotInput {
    display: flex;
    padding: 8px 8px 8px 12px;
    align-items: center;
    border-radius: 0px 0px 6px 6px;
    outline: 1px solid c.$gray300;
    background: c.$baseWhite;
    width: 100%;

    &:focus-within {
        outline: 2px solid c.$primary600;
    }
    &__input {
        color: c.$gray900;
        @include t.text("sm", "regular");
        border: none;
        width: 100%;

        &::placeholder {
            color: c.$gray500;
        }
        &:focus,
        &:focus-visible {
            border: none;
            outline: none;
        }
    }
}
