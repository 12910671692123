@use "/src/styles/colors.scss" as c;

.auditBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__error {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 250px;
    }
}
