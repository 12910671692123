@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;
@use "/src/styles/variables.scss" as v;

.desktop-taskPillWithStar-skelleton {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background: c.$baseWhite;
    box-shadow: c.$shadow-md;
    flex-direction: row;
    padding: 12px;
    gap: 12px;
    &__img {
        @include m.skeleton-is-loading(c.$gray200);
        height: 122px;
        border-radius: 4px 4px 0px 0px;
        align-self: center;
        width: 180px;
        min-width: 180px;
    }
    &__container {
        display: flex;
        gap: 12px;
        width: 100%;
        padding: 0;
        flex-direction: row;
        height: 122px;
        justify-content: space-between;
        &__taskInfo {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            justify-content: center;
        }
    }
}
