@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.dividerWithText {
    @include t.text("sm", "semibold");
    color: c.$primary700;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
    &__line {
        border-color: c.$primary300;
    }
}
