@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;

.dragFile {
    border: 1px solid c.$gray300;
    background-color: c.$baseWhite;
    height: 126px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    &--hide {
        visibility: hidden;
    }

    &:hover {
        @extend .dragFile;
        border: 1px solid c.$primary600;
        background-color: c.$primary25;
        .dragFile__upload {
            &__icon {
                background-color: c.$primary100;
                border-color: c.$primary50;
            }
        }
    }

    &--height76 {
        @extend .dragFile;
        height: 76px;
        padding: 0px;
        &:hover {
            padding: 0px;
            height: 76px;
        }
    }

    &--disabled {
        border: 1px solid c.$gray200;
        background-color: c.$gray50;
        pointer-events: none;
        &:hover {
            border: 1px solid c.$gray200;
            background-color: c.$gray50;
            cursor: default;
            pointer-events: none;
        }
    }

    &__upload {
        display: flex;
        flex-direction: column;
        gap: 12px;
        @include t.text("sm", "semibold");
        color: c.$primary700;
        justify-content: center;
        width: 100%;
        align-items: center;
        &--height76 {
            @extend .dragFile__upload;
            flex-direction: row;
        }
        &--disabled {
            color: c.$gray300;
        }
        &__icon {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background-color: c.$gray200;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            border: 4px solid c.$gray100;
            &--disabled {
                color: c.$gray400;
            }
        }
        &__container {
            display: flex;
            flex-direction: column;
            gap: 4px;
            &__subPlaceholder {
                @include t.text("xs");
                color: c.$gray500;
                text-align: center;
            }
        }
    }
    &__preview {
        width: 100%;
    }
}
