@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;

.button {
    cursor: pointer;
    user-select: none;
    transition: background 300ms, color 300ms, border 300ms;

    &--primary {
        &--contained {
            border: 1px solid c.$primary600;
            background: c.$primary600;
            color: c.$baseWhite;
            &:hover {
                border: 1px solid c.$primary600;
                background: c.$baseWhite;
                color: c.$primary600;
            }
            &--focus {
                &:hover {
                    background-color: c.$primary50;
                }
            }
            &--loading {
                background: c.$primary25;
                color: c.$primary300;
                border: 1px solid c.$primary500;
                cursor: default;
                &:hover {
                    background: c.$primary25;
                    color: c.$primary300;
                    border: 1px solid c.$primary500;
                }
            }
        }
        &--secondary {
            border: 1px solid c.$primary700;
            background: c.$baseWhite;
            color: c.$primary700;
            &:hover {
                border: 1px solid c.$primary600;
                background: c.$primary600;
                color: c.$baseWhite;
            }
            &--focus {
                &:hover {
                    border: 1px solid c.$primary700;
                    background: c.$primary700;
                    color: c.$baseWhite;
                }
            }

            &--loading {
                background: c.$primary25;
                color: c.$primary300;
                border: 1px solid c.$primary500;
                cursor: default;
                &:hover {
                    background: c.$primary25;
                    color: c.$primary300;
                    border: 1px solid c.$primary500;
                }
            }
        }
        &--tertiary {
            border: 1px solid c.$primary50;
            background: c.$primary50;
            color: c.$primary700;
            &:hover {
                border: 1px solid c.$primary100;
                background: c.$primary100;
                color: c.$primary800;
            }
            &--focus {
                &:hover {
                    border: 1px solid c.$primary50;
                    background: c.$primary200;
                    color: c.$primary800;
                }
            }
            &--loading {
                border: 1px solid c.$primary50;
                background: c.$primary50;
                color: c.$primary300;
                cursor: default;
                &:hover {
                    border: 1px solid c.$primary50;
                    background: c.$primary50;
                    color: c.$primary300;
                }
            }
        }
        &--link {
            color: c.$primary700;
            &:hover {
                color: c.$primary800;
                text-decoration: underline;
            }
            &--focus {
                &:hover {
                    color: c.$primary700;
                    text-decoration: underline;
                }
            }
            &--loading {
                color: c.$primary300;
                cursor: default;
                &:hover {
                    color: c.$primary300;
                    text-decoration: none;
                }
            }
        }
        &--transparent {
            background: transparent;
            border: 1px solid c.$baseWhite;
            &--loading {
                &:hover {
                    background: transparent;
                    border: 1px solid c.$baseWhite;
                }
            }
        }
    }
    &--danger {
        &--contained {
            border: 1px solid c.$error600;
            background: c.$error600;
            color: c.$baseWhite;
            &:hover {
                border: 1px solid c.$error600;
                background: c.$baseWhite;
                color: c.$error600;
            }
            &--focus {
                &:hover {
                    background-color: c.$error50;
                }
            }

            &--loading {
                background: c.$error25;
                color: c.$error300;
                border: 1px solid c.$error500;
                cursor: default;
                &:hover {
                    background: c.$error25;
                    color: c.$error300;
                    border: 1px solid c.$error500;
                }
            }
        }
        &--secondary {
            border: 1px solid c.$error700;
            background: c.$baseWhite;
            color: c.$error700;
            &:hover {
                border: 1px solid c.$error600;
                background: c.$error600;
                color: c.$baseWhite;
            }
            &--focus {
                &:hover {
                    border: 1px solid c.$error700;
                    background: c.$error700;
                    color: c.$baseWhite;
                }
            }
            &--loading {
                background: c.$error25;
                color: c.$error300;
                border: 1px solid c.$error500;
                cursor: default;
                &:hover {
                    background: c.$error25;
                    color: c.$error300;
                    border: 1px solid c.$error500;
                }
            }
        }
        &--tertiary {
            border: 1px solid c.$error50;
            background: c.$error50;
            color: c.$error700;
            &:hover {
                border: 1px solid c.$error100;
                background: c.$error100;
                color: c.$error800;
            }
            &--focus {
                &:hover {
                    border: 1px solid c.$error50;
                    background: c.$error200;
                    color: c.$error800;
                }
            }
            &--loading {
                background: c.$error25;
                color: c.$error300;
                border: 1px solid c.$error500;
                cursor: default;
                &:hover {
                    background: c.$error25;
                    color: c.$error300;
                    border: 1px solid c.$error500;
                }
            }
        }
        &--link {
            color: c.$error700;
            &:hover {
                color: c.$error800;
                text-decoration: underline;
            }
            &--focus {
                &:hover {
                    color: c.$error700;
                    text-decoration: underline;
                }
            }
            &--loading {
                color: c.$error300;
                cursor: default;
                &:hover {
                    color: c.$error300;
                    text-decoration: none;
                }
            }
        }
        &--transparent {
            background: transparent;
            border: 1px solid c.$baseWhite;
            &--loading {
                border: 1px solid c.$error600;
                &:hover {
                    background: transparent;
                    border: 1px solid c.$error600;
                }
            }
        }
    }
    &--purple {
        &--contained {
            border: 1px solid c.$purple600;
            background: c.$purple600;
            color: c.$baseWhite;
            &:hover {
                border: 1px solid c.$purple600;
                background: c.$baseWhite;
                color: c.$purple600;
            }
            &--focus {
                &:hover {
                    background-color: c.$purple50;
                }
            }
            &--loading {
                background: c.$purple25;
                color: c.$purple300;
                border: 1px solid c.$purple500;
                cursor: default;
                &:hover {
                    background: c.$purple25;
                    color: c.$purple300;
                    border: 1px solid c.$purple500;
                }
            }
        }
        &--secondary {
            border: 1px solid c.$purple700;
            background: c.$baseWhite;
            color: c.$purple700;
            &:hover {
                border: 1px solid c.$purple600;
                background: c.$purple600;
                color: c.$baseWhite;
            }
            &--focus {
                &:hover {
                    border: 1px solid c.$purple700;
                    background: c.$purple700;
                    color: c.$baseWhite;
                }
            }

            &--loading {
                background: c.$purple25;
                color: c.$purple300;
                border: 1px solid c.$purple500;
                cursor: default;
                &:hover {
                    background: c.$purple25;
                    color: c.$purple300;
                    border: 1px solid c.$purple500;
                }
            }
        }
        &--tertiary {
            border: 1px solid c.$purple50;
            background: c.$purple50;
            color: c.$purple700;
            &:hover {
                border: 1px solid c.$purple100;
                background: c.$purple100;
                color: c.$purple800;
            }
            &--focus {
                &:hover {
                    border: 1px solid c.$purple50;
                    background: c.$purple200;
                    color: c.$purple800;
                }
            }
            &--loading {
                border: 1px solid c.$purple50;
                background: c.$purple50;
                color: c.$purple300;
                cursor: default;
                &:hover {
                    border: 1px solid c.$purple50;
                    background: c.$purple50;
                    color: c.$purple300;
                }
            }
        }
        &--link {
            color: c.$purple700;
            &:hover {
                color: c.$purple800;
                text-decoration: underline;
            }
            &--focus {
                &:hover {
                    color: c.$purple700;
                    text-decoration: underline;
                }
            }
            &--loading {
                color: c.$purple300;
                cursor: default;
                &:hover {
                    color: c.$purple300;
                    text-decoration: none;
                }
            }
        }
        &--transparent {
            background: transparent;
            border: 1px solid c.$baseWhite;
            &--loading {
                border: 1px solid c.$purple600;
                &:hover {
                    background: transparent;
                    border: 1px solid c.$purple600;
                }
            }
        }
    }
    &--appcc {
        &--contained {
            border: 1px solid c.$appcc600;
            background: c.$appcc600;
            color: c.$baseWhite;
            &:hover {
                border: 1px solid c.$appcc600;
                background: c.$baseWhite;
                color: c.$appcc600;
            }
            &--focus {
                &:hover {
                    background-color: c.$appcc50;
                }
            }
            &--loading {
                background: c.$appcc25;
                color: c.$appcc300;
                border: 1px solid c.$appcc500;
                cursor: default;
                &:hover {
                    background: c.$appcc25;
                    color: c.$appcc300;
                    border: 1px solid c.$appcc500;
                }
            }
        }
        &--secondary {
            border: 1px solid c.$appcc700;
            background: c.$baseWhite;
            color: c.$appcc700;
            &:hover {
                border: 1px solid c.$appcc600;
                background: c.$appcc600;
                color: c.$baseWhite;
            }
            &--focus {
                &:hover {
                    border: 1px solid c.$appcc700;
                    background: c.$appcc700;
                    color: c.$baseWhite;
                }
            }

            &--loading {
                background: c.$appcc25;
                color: c.$appcc300;
                border: 1px solid c.$appcc500;
                cursor: default;
                &:hover {
                    background: c.$appcc25;
                    color: c.$appcc300;
                    border: 1px solid c.$appcc500;
                }
            }
        }
        &--tertiary {
            border: 1px solid c.$appcc50;
            background: c.$appcc50;
            color: c.$appcc700;
            &:hover {
                border: 1px solid c.$appcc100;
                background: c.$appcc100;
                color: c.$appcc800;
            }
            &--focus {
                &:hover {
                    border: 1px solid c.$appcc50;
                    background: c.$appcc200;
                    color: c.$appcc800;
                }
            }
            &--loading {
                border: 1px solid c.$appcc50;
                background: c.$appcc50;
                color: c.$appcc300;
                cursor: default;
                &:hover {
                    border: 1px solid c.$appcc50;
                    background: c.$appcc50;
                    color: c.$appcc300;
                }
            }
        }
        &--link {
            color: c.$appcc700;
            &:hover {
                color: c.$appcc800;
                text-decoration: underline;
            }
            &--focus {
                &:hover {
                    color: c.$appcc700;
                    text-decoration: underline;
                }
            }
            &--loading {
                color: c.$appcc300;
                cursor: default;
                &:hover {
                    color: c.$appcc300;
                    text-decoration: none;
                }
            }
        }
        &--transparent {
            background: transparent;
            border: 1px solid c.$baseWhite;
            &--loading {
                border: 1px solid c.$appcc600;
                &:hover {
                    background: transparent;
                    border: 1px solid c.$appcc600;
                }
            }
        }
    }

    &--contained,
    &--secondary,
    &--tertiary {
        display: inline-flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 60px;
    }

    &--xs {
        height: 40px;
        @include t.text("xs", "semibold");
    }

    &--sm {
        height: 40px;
        @include t.text("sm", "semibold");
    }

    &--onlyIcon {
        &--xs {
            padding: 12px;
            width: 32px;
            height: 32px;
            @include t.text("xs", "semibold");
        }

        &--sm {
            padding: 12px;
            width: 44px;
            height: 44px;
            @include t.text("sm", "semibold");
        }
    }

    &--disabled {
        &--contained {
            border: 1px solid c.$gray300;
            background: c.$gray300;
            color: c.$baseWhite;
            cursor: default;
            &:hover {
                border: 1px solid c.$gray300;
                background: c.$gray300;
                color: c.$baseWhite;
            }
        }
        &--secondary {
            border: 1px solid c.$gray200;
            background: c.$gray100;
            color: c.$gray300;
            cursor: default;
            &:hover {
                border: 1px solid c.$gray200;
                background: c.$gray100;
                color: c.$gray300;
            }
        }
        &--tertiary {
            border: 1px solid c.$gray25;
            background: c.$gray50;
            color: c.$gray300;
            cursor: default;
            &:hover {
                border: 1px solid c.$gray25;
                background: c.$gray50;
                color: c.$gray300;
            }
        }
        &--link {
            color: c.$gray300;
            cursor: default;
            &:hover {
                color: c.$gray300;
                text-decoration: none;
            }
        }
    }

    &--link,
    &--linkWhite {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border: none;
        background: transparent;
        padding: 0;
    }
}
