@use "/src/styles/variables.scss" as c;
@use "/src/styles/colors.scss" as color;
@use "/src/styles/typographies.scss" as t;

.emojisInput {
    display: flex;
    align-items: center;
    background-color: color.$baseWhite;
    border-radius: 5px;
    padding: 2px;
    height: 38px;
    justify-content: space-between;
    position: relative;
    gap: 14px;
    &--disabled {
        @extend .emojisInput;
        background-color: #f3f3f3;
    }
    &__input {
        flex: 1;
        border: none;
        outline: none;
        font-size: 16px;
        width: 100%;
        color: color.$gray800;
        @include t.text("md");
    }
    &__emojiIcon {
        font-size: 16px;
        margin: 0;
        cursor: pointer;
        user-select: none;
        color: color.$primary500;
        &--disabled {
            @extend .emojisInput__emojiIcon;
            cursor: auto;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0;
        gap: 10px;
        width: 318px;
        background-color: color.$baseWhite;
        position: absolute;
        bottom: 40px;
        left: 0;
        height: 300px;
        overflow-y: scroll;
        padding: 10px;
        border-radius: 8px;
        box-shadow: c.$shadow-mid;
        z-index: 4;
        &--upsideDown {
            @extend .emojisInput__container;
            bottom: -200px;
        }
        &__emoji {
            font-size: 20px;
            user-select: none;
            cursor: pointer;
            margin-bottom: 0;
            width: 24px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.shortEmojis {
    height: 200px;
}
