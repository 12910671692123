@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.issueMobileRow {
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid c.$gray200;
    cursor: pointer;
    &--odd {
        @extend .issueMobileRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .issueMobileRow;
        background: c.$gray25;
    }
}
