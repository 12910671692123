@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;

@mixin baseStyle() {
    width: 100%;
    height: 100%;
    color: c.$baseWhite;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadPhoto {
    width: 120px;
    height: 120px;
    gap: 7px;
    cursor: pointer;
    &--logo {
        @extend .uploadPhoto;
        width: 100%;
    }
    &__existed {
        @extend .uploadPhoto;
        @include baseStyle();
        position: relative;
        &.logo {
            border: 1px solid c.$gray300;
            color: c.$gray500;
            &:hover {
                color: c.$baseWhite;
            }
        }
        &__img {
            @include baseStyle();
            border: 1px solid c.$baseWhite;
            object-fit: cover;
        }
        &.logo {
            object-fit: contain;
        }
        &--translucent {
            @extend .uploadPhoto__existed;
            @include baseStyle();
            border: 1px solid c.$baseWhite;
            background-color: c.$primary500;
            opacity: 0.7;
            position: absolute;
            &__icon {
                @extend .uploadPhoto__notExisted__icon;
            }
            &__text {
                @extend .uploadPhoto__notExisted__text;
            }
        }
        &--transparent {
            @extend .uploadPhoto__existed;
            @include baseStyle();
            border: 1px solid c.$baseWhite;
            position: absolute;
            opacity: 0;
            &__icon {
                @extend .uploadPhoto__notExisted__icon;
            }
            &__text {
                @extend .uploadPhoto__notExisted__text;
            }
        }
        &--squared {
            @extend .uploadPhoto;
            @include baseStyle();
            border-radius: 8px;
            position: relative;

            &.logo {
                border: 1px solid c.$gray300;
                color: c.$gray500;
                &:hover {
                    color: c.$baseWhite;
                }
            }
            &__img {
                @include baseStyle();
                border-radius: 8px;
                border: 1px solid c.$baseWhite;
                object-fit: cover;
                &.logo {
                    object-fit: contain;
                }
            }
            &--translucent {
                @extend .uploadPhoto__existed--translucent;
                border-radius: 8px;
                &__icon {
                    @extend .uploadPhoto__notExisted--squared__icon;
                }
                &__text {
                    @extend .uploadPhoto__notExisted--squared__text;
                }
            }
            &--transparent {
                @extend .uploadPhoto__existed--transparent;
                border-radius: 8px;
                &__icon {
                    @extend .uploadPhoto__notExisted--squared__icon;
                }
                &__text {
                    @extend .uploadPhoto__notExisted--squared__text;
                }
            }
        }
    }
    &__notExisted {
        @extend .uploadPhoto;
        @include baseStyle();
        border: 1px solid c.$baseWhite;

        &.logo {
            border: 1px solid c.$gray300;
            color: c.$gray500;
            &:hover {
                color: c.$baseWhite;
            }
        }
        &:hover {
            @extend .uploadPhoto;
            @include baseStyle();
            border: 1px solid c.$baseWhite;
            background-color: c.$primary400;
        }
        &__icon {
            width: 16px;
            height: 16px;
        }
        &__text {
            text-align: center;
            @include t.text("xs", "medium");
        }
        &--squared {
            @extend .uploadPhoto__notExisted;
            border-radius: 8px;

            &.logo {
                border: 1px solid c.$gray300;
                color: c.$gray500;
                &:hover {
                    color: c.$baseWhite;
                }
            }
            &:hover {
                @extend .uploadPhoto__notExisted--squared;
            }
            &__icon {
                @extend .uploadPhoto__notExisted__icon;
            }
            &__text {
                @extend .uploadPhoto__notExisted__text;
            }
        }
    }
}
