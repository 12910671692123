@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

.dateRange-skelleton {
    display: flex;
    height: 40px;
    padding: 8px 12px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid c.$baseWhite;
    &__input {
        display: flex;
        width: 50%;
        height: 10px;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        @include m.skeleton-is-loading(c.$primary100);
    }
    &__icon {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        @include m.skeleton-is-loading(c.$primary300);
        border-radius: 50%;
    }
}
