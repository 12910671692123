@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.myProfile {
    width: 100%;
    height: 100%;
    &__header {
        position: relative;
        width: 100%;
        height: 100%;
        &__doNotDisturb {
            position: absolute;
            right: 0;
            display: flex;
            gap: 12px;
            align-items: center;
        }
        &__user {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            text-align: center;
            color: c.$baseWhite;
            &__info {
                display: flex;
                flex-direction: column;
                gap: 12px;
                &__name {
                    @include t.text("xl", "semibold");
                }
            }
        }
    }
}

.myprofile {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__column {
        width: 100%;
    }
}

@media screen and (min-width: v.$tablet) {
    .myProfile__header {
        &__user {
            flex-direction: row;
            gap: 20px;
            justify-content: flex-start;
            &__info {
                align-items: flex-start;
            }
        }
    }
    .myprofile {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-self: center;

        &__column {
            width: 50%;
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .myProfile__header {
        &__user {
            flex-direction: row;
            gap: 20px;
            justify-content: flex-start;
            &__info {
                align-items: flex-start;
            }
        }
    }
}
