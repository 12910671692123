@use "/src/styles/variables.scss" as v;

.customLegend {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6px;
    &__item {
        width: 100%;
    }
}

@media only screen and (min-width: v.$mobile) {
    .customLegend {
        flex-direction: row;
        gap: 20px;
        &__item {
            width: fit-content;
        }
    }
}
