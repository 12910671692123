@use "/src/styles/variables.scss" as v;

.notificationsCompanyModal {
    &__company {
        width: 100%;
    }
}

@media screen and (min-width: v.$tablet) {
    .notificationsCompanyModal {
        &__company {
            width: calc(50% - 16px);
        }
    }
}
