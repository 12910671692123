@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.taskWizardStepTwo {
    display: flex;
    cursor: pointer;
}

.wizardTaskStepTwoTitle {
    color: c.$primary500;
    @include t.text("sm", "medium");
}
.wizardTaskStepTwoContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}
.stepTwoSelectWithRadio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
    &__label {
        padding-left: 32px;
        &--disabled {
            @extend .stepTwoSelectWithRadio__label;
        }
    }
    &__errorMessage {
        padding-left: 32px;
    }
    &__container {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        &--disabled {
            padding-left: 32px;
        }
    }
}
