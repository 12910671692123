@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileBaseTaskRowHeader {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @include t.text("sm");
    &__icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        height: 100%;
    }
}
