@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;

.mainInputFile {
    &__wrapper {
        display: flex;
        cursor: pointer;
        flex-direction: column;
    }
    &-visible__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: c.$primary500;
        padding: 0.75rem 2rem;
        font-size: 11px;
        border-radius: 5px;
        color: c.$baseWhite;
    }
    &-visibleLabel {
        cursor: pointer;
        overflow-x: hidden;
    }
    &-visibleLabel:hover {
        text-decoration: underline;
    }
    &__icon {
        width: 30px;
        height: 30px;
    }
    &__input {
        cursor: inherit;
        font-size: 16px;
    }
}

@media screen and (min-width: v.$tablet) {
    .mainInputFile {
        &__wrapper img {
            max-width: 300px;
        }
    }
}

.mainDragFile {
    &__label {
        padding-left: 1rem;
        padding-bottom: 0.5rem;
    }

    &__container {
        display: flex;
        align-items: center;
        gap: 12px;

        &__closeIcon {
            color: #c9264b;
            cursor: pointer;
        }
    }
}

.dragFileWithName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}
