@use "/src/styles/colors.scss" as c;

.selectWithActionsMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__lastItems {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid c.$gray500;
    }
}
