@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.tableError {
    display: flex;
    padding: 0px 12px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            &__textContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                &__title {
                    text-align: center;
                    color: c.$gray900;
                    @include t.text("md", "semibold");
                    &--disabled {
                        @extend .tableError__container__content__textContainer__title;
                        color: c.$gray600 !important;
                        &--sm {
                            @extend .tableError__container__content__textContainer__title;
                            @include t.text("sm", "semibold");
                            font-size: 16px !important;
                            color: c.$gray600 !important;
                        }
                    }
                    &--sm {
                        @extend .tableError__container__content__textContainer__title;
                        @include t.text("sm", "semibold");
                        font-size: 16px !important;
                    }
                }
                &__description {
                    text-align: center;
                    color: c.$gray600;
                    @include t.text("sm", "regular");
                    &--disabled {
                        @extend .tableError__container__content__textContainer__description;
                        color: c.$gray500 !important;
                    }
                }
            }
            &--flex {
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .tableError {
        &__container {
            gap: 32px;
            &__content {
                gap: 20px;
                &__textContainer {
                    &__title {
                        text-align: center;
                        color: c.$gray900;
                        @include t.text("lg", "semibold");
                    }
                }
            }
        }
    }
}
