.step-three-file-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.step-three-file-wrapper__switchGroup {
    display: flex;
    gap: 8px;
}

.wizardTaskStepThree {
    &__readOnly {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-right: 5px;
    }
}
