@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;

.userValoration {
    display: flex;
    align-items: center;
    color: c.$baseWhite;
    gap: 6px;
    &__emoji {
        width: 20px;
    }
    &--center {
        @extend .userValoration;
        justify-content: center;
    }
    &--start {
        @extend .userValoration;
        justify-content: flex-start;
    }

    &--width-100 {
        @extend .userValoration;
        width: 100%;
    }
    &--width-auto {
        @extend .userValoration;
        width: auto;
    }
    &__valoration {
        width: 25px;
        text-align: center;
        @include t.display("xs", "medium");
        &--gray {
            @extend .userValoration__valoration;
            color: c.$gray500;
            @include t.text("lg", "medium");
        }

        &--md {
            @include t.text("lg", "medium");
        }
        &--xl {
            @include t.display("xs", "medium");
        }
    }
    &__total {
        width: 50px;
        &--gray {
            @include t.text("sm");
            @extend .userValoration__total;
            color: c.$gray500;
        }
        &--white {
            @include t.text("sm");
            @extend .userValoration__total;
            color: c.$baseWhite;
        }
    }
}
