@use "/src/styles/variables.scss" as v;

.dataToReportGrid {
    grid-column: 1 / 2;
    display: flex;
    width: 100%;
}
.dataToReport {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        width: 100%;
    }
    &__photoRegister {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        &__label {
            display: flex;
            gap: 8px;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .dataToReportGrid {
        grid-column: 1 / 3;
    }
}

@media screen and (min-width: v.$laptop) {
    .dataToReportGrid {
        grid-column: 2 / 3;
    }
}
