@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.data_report_inputs__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    &__AddNew {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        color: c.$primary700;
        &:hover {
            text-decoration: underline;
        }
        &__icon {
            font-size: 16px;
        }
        &__text {
            @include t.text("sm", "semibold");
        }
        &__container {
            width: fit-content;
        }
    }
}

.data_report_input__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
    justify-content: space-between;
}

.deleteInputIcon {
    color: c.$gray500;
    font-size: 16px;
    cursor: pointer;
}

.data_report_item {
    width: 100%;
}
