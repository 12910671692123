.avatarStack {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    & > *:last-child {
        margin-left: 0;
    }
    &__avatar {
        &--xs {
            margin-left: -15px;
        }
        &--sm {
            margin-left: -20px;
        }
        &--md {
            margin-left: -28px;
        }
        &--lg {
            margin-left: -28px;
        }
        &--xl {
            margin-left: -32px;
        }
        &--2xl {
            margin-left: -36px;
        }
    }
}
