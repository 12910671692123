@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;
@use "/src/styles/variables.scss" as v;

.skelletonRankingPill {
    &__info {
        display: flex;
        border: none;
        align-items: center;
        gap: 8px;
        &__icon {
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            @include m.skeleton-is-loading(c.$primary300);
        }
        &__text {
            height: 12px;
            width: 120px;
            border-radius: 12px;
            @include m.skeleton-is-loading(c.$primary100);
        }
    }
    &__userValoration {
        height: 19px;
        width: 180px;
        border-radius: 12px;
        @include m.skeleton-is-loading(c.$primary100);
    }
    &__badge {
        height: 18px;
        width: 60px;
        border-radius: 12px;
        @include m.skeleton-is-loading(c.$primary300);
    }
    &__counter {
        height: 38px;
        width: 60px;
        border-radius: 8px;
        @include m.skeleton-is-loading(c.$primary100);
        &--fullWidth {
            @extend .skelletonRankingPill__counter;
            width: 90px;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .skelletonRankingPill {
        &__counter {
            &--fullWidth {
                width: 130px;
            }
        }
        &__info {
            &--noStars {
                grid-column: 1 / 3;
            }
        }
    }
}
