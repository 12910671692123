@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.assetMobileRow {
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid c.$gray200;
    cursor: pointer;
    &--odd {
        @extend .assetMobileRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .assetMobileRow;
        background: c.$gray25;
    }
    &__body {
        display: flex;
        gap: 20px;
        width: 100%;
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        gap: 2px;
        width: 100%;
        &__header {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            &__name {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                color: c.$gray800;
                text-overflow: ellipsis;
                @include t.text("xs", "medium");
            }
            &__type {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                color: c.$gray700;
                text-overflow: ellipsis;
                @include t.text("xs", "regular");
            }
        }
        &__assetId,
        &__temp {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: c.$gray700;
            text-overflow: ellipsis;
            @include t.text("xs", "regular");
        }
        &__temp {
            -webkit-line-clamp: 1;
        }
    }
    &__icon {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        height: 100%;
        &__edit {
            height: 20px;
            width: 20px;
            color: c.$gray600;
        }
    }
    &__badges {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
    }
}

@media screen and (min-width: v.$mobile) {
    .assetMobileRow__badges {
        flex-direction: row;
        align-items: center;
    }
}
