@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.feedback {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100%;

    &__header {
        background-color: c.$primary600;
        width: 100%;

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px;
            &__icon {
                color: c.$baseWhite;
                cursor: pointer;
            }
        }
    }

    &__dateRangePicker {
        width: 100%;
    }

    &__order {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: flex-end;
        cursor: pointer;
        height: 100%;
        padding: 0 4px;
        flex-shrink: 0;
        position: relative;
        border-radius: 4px;
        padding: 4px;
        height: 28px;
        &:hover {
            background-color: c.$iconHoverBg;
        }
        &__container {
            display: flex;
            gap: 2px;
        }
        &__text {
            color: c.$baseWhite;
            @include t.text("xs", "medium");
            user-select: none;
            &__width {
                width: 65px;
                text-align: center;
            }
        }

        &__icon {
            width: 16px;
            height: 16px;
            color: c.$baseWhite;
            @include t.text("xs", "medium");
            user-select: none;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .feedback {
        &__header__container,
        &__dateRangePicker {
            max-width: 255px;
        }
    }
}

.popeverFeedback {
    width: 250px;
}
