@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

.crudHeaderLeftNodeBadge {
    @include m.skeleton-is-loading(c.$primary100);
    height: 22px;
    border-radius: 16px;
    &--lg {
        @extend .crudHeaderLeftNodeBadge;
        width: 71px;
    }
    &--sm {
        @extend .crudHeaderLeftNodeBadge;
        width: 22px;
    }
}
