@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.stepFourInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    &__title {
        color: c.$gray500;
        @include t.text("sm", "medium");
    }
    &__description {
        color: c.$gray900;
        @include t.text("md", "regular");
    }
}
