@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.assetDynamicField {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__title-input {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 6px;
        width: 100%;
    }
    &__title__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        width: 100%;
        &__text {
            color: c.$gray700;
            @include t.text("sm", "medium");
        }
    }
    &__delete-icon {
        position: initial;
        display: flex;
        justify-content: flex-end;
    }

    &__dropdown {
        width: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    &__unit {
        width: 100%;
    }

    &__minMax {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 6px;
        &__inputs {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
            width: 100%;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .assetDynamicField {
        &__delete-icon {
            position: absolute;
            top: 0px;
            right: 0px;
        }
        &__minMax {
            &__inputs {
                flex-direction: row;
            }
        }
        &__title-input,
        &__dropdown,
        &__unit {
            width: calc(50% - 10px);
        }
    }
}
