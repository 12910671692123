@use "/src/styles/variables.scss" as v;

.editImage {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100dvh;
    touch-action: none;
    &__container {
        width: 100%;
        height: 100%;
        max-width: fit-content;
        object-fit: contain;
        max-height: 100dvh;
        display: flex;
        align-items: center;
    }
    &__canvas {
        width: 100%;
        max-width: fit-content;
        object-fit: contain;
        max-height: 100dvh;
        user-select: none;
        &--block {
            display: block;
        }
        &--none {
            display: none;
        }
    }
    &__hueSlider {
        position: absolute;
        right: 34px;
        top: 240px;
    }
    &__sendImage {
        position: absolute;
        bottom: 12px;
        right: 12px;
    }
}
