.baseTaskTemporality {
    display: flex;
    width: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }
}
