@use "/src/styles/variables.scss" as v;

.dynamicFields {
    &__container,
    &__container--small {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }
}
