@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;

.dayBubbleList {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    &__label {
        display: flex;
        gap: 12px;
        align-items: center;
        &--primary {
            @extend .dayBubbleList__label;
            @include t.text("sm", "medium");
            color: c.$gray700;
        }
        &--dark {
            @extend .dayBubbleList__label;
            @include t.text("sm", "medium");
            color: c.$baseWhite;
        }
    }
    &__list {
        display: flex;
        gap: 12px;
    }
    &__error {
        &--primary {
            @include t.text("sm");
            color: c.$error600;
        }
        &--dark {
            @include t.text("sm");
            color: c.$error400;
        }
    }
    &__hint {
        &--primary {
            @include t.text("sm");
            color: c.$gray600;
        }
        &--dark {
            @include t.text("sm");
            color: c.$baseWhite;
        }
    }
}
