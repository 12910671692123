@use "/src/styles/variables.scss" as v;

.alertFloatingButtons {
    position: fixed;
    right: 12px;
    bottom: 20px;
    display: flex;
    gap: 8px;
    padding: 0;
    &--ios {
        bottom: 80px;
    }
}

@media screen and (min-width: v.$tablet) {
    .alertFloatingButtons {
        bottom: 20px;
        &--ios {
            bottom: 20px;
        }
    }
}
