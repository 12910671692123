.taskTemporalityPeriodicalDisabled {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    &__hours {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }
    &__month {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }
    &__weekDays {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        flex-wrap: wrap;
    }
}
