@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.rankingPillCounter {
    display: flex;
    width: 100%;
    min-width: 60px;
    min-height: 38px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    &--yellow {
        @extend .rankingPillCounter;
        background-color: c.$yellow100;
    }
    &--danger {
        @extend .rankingPillCounter;
        background-color: c.$error50;
    }
    &--success {
        @extend .rankingPillCounter;
        background-color: c.$success50;
    }
    &--default {
        @extend .rankingPillCounter;
        background-color: c.$gray100;
    }
    &--defaultCross,
    &--defaultClock {
        @extend .rankingPillCounter;
        background-color: c.$gray100;
    }
    &--fullWidth {
        @extend .rankingPillCounter;
        max-width: 90px;
        min-width: 90px;
        padding: 8px;
        gap: 2px;
    }
    &--fullHeight {
        @extend .rankingPillCounter;
        height: 52px;
    }

    &__icon {
        width: 12px;
        height: 12px;
        font-weight: 400;
        &--yellow {
            @extend .rankingPillCounter__icon;
            color: c.$yellow600;
        }
        &--danger {
            @extend .rankingPillCounter__icon;
            color: c.$error600;
        }
        &--success {
            @extend .rankingPillCounter__icon;
            color: c.$success600;
        }
        &--default {
            @extend .rankingPillCounter__icon;
            color: c.$gray600;
        }
        &--defaultCross,
        &--defaultClock {
            @extend .rankingPillCounter__icon;
            color: c.$gray600;
        }
    }

    &__number {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
    }

    &__text {
        text-align: center;
        @include t.text("lg", "semibold");
        margin: 0;
        &--yellow {
            @extend .rankingPillCounter__text;
            color: c.$yellow600;
        }
        &--danger {
            @extend .rankingPillCounter__text;
            color: c.$error600;
        }
        &--success {
            @extend .rankingPillCounter__text;
            color: c.$success600;
        }
        &--default {
            @extend .rankingPillCounter__text;
            color: c.$gray600;
        }
        &--defaultCross,
        &--defaultClock {
            @extend .rankingPillCounter__text;
            color: c.$gray600;
        }
    }
    &__percentage {
        @include t.text("xs", "medium");
        &--yellow {
            @extend .rankingPillCounter__percentage;
            color: c.$yellow600;
        }
        &--danger {
            @extend .rankingPillCounter__percentage;
            color: c.$error600;
        }
        &--success {
            @extend .rankingPillCounter__percentage;
            color: c.$success600;
        }
        &--default {
            @extend .rankingPillCounter__percentage;
            color: c.$gray600;
        }
        &--defaultCross,
        &--defaultClock {
            @extend .rankingPillCounter__percentage;
            color: c.$gray600;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .rankingPillCounter {
        min-height: 44px;
        &--fullWidth {
            min-width: 130px;
            max-width: fit-content;
        }
    }
}
