@use "/src/styles/colors.scss" as c;

.modal2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    &--blur {
        background-color: c.$blackTransparent;
    }
}
