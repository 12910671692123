@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;
@use "/src/styles/variables.scss" as v;

.avatar-skelleton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.avatar-is-loading {
    display: inline-block;
    border-radius: 50%;
    @include m.skeleton-is-loading(c.$primary300);
    &--xs {
        @extend .avatar-is-loading;
        height: 24px;
        width: 24px;
    }
    &--sm {
        @extend .avatar-is-loading;
        height: 32px;
        width: 32px;
    }
    &--md {
        @extend .avatar-is-loading;
        height: 40px;
        width: 40px;
    }
    &--lg {
        @extend .avatar-is-loading;
        height: 48px;
        width: 48px;
    }
    &--xl {
        @extend .avatar-is-loading;
        height: 56px;
        width: 56px;
    }
    &--2xl {
        @extend .avatar-is-loading;
        height: 64px;
        width: 64px;
    }
}

.avatarName-is-loading {
    display: inline-block;
    border-radius: 10px;
    @include m.skeleton-is-loading(c.$primary100);
    &--xs {
        @extend .avatarName-is-loading;
        height: 14px;
        width: 60px;
    }
    &--sm {
        @extend .avatarName-is-loading;
        height: 14px;
        width: 60px;
    }
    &--md {
        @extend .avatarName-is-loading;
        height: 20px;
        width: 80px;
    }
    &--lg {
        @extend .avatarName-is-loading;
        height: 20px;
        width: 80px;
    }
    &--xl {
        @extend .avatarName-is-loading;
        height: 20px;
        width: 120px;
    }
}

@media screen and (min-width: v.$tablet) {
    .avatar-skelleton {
        justify-content: flex-start;
    }
}
