@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;
.supervisor-team-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: c.$gray100;
    width: fit-content;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__icon {
        display: flex;
        align-items: center;
        gap: 6px;

        svg {
            color: c.$gray500;
        }
    }
    &__number {
        margin-left: 4px;
        @include t.text("lg", "semibold");
        color: c.$gray800;
    }

    &__text {
        margin-top: 6px;
        @include t.text("sm", "medium");
        color: c.$gray800;
    }
}
