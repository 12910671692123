@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.editAuditHeader {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (min-width: v.$tablet) {
        flex-direction: row;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        @media screen and (min-width: v.$mobile) {
            flex-direction: row;
        }
    }

    &__title {
        color: c.$baseWhite;
        text-align: center;
        @include t.text("lg", "semibold");
        @media screen and (min-width: v.$tablet) {
            text-align: left;
        }
    }

    &__subtitle {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}
