@use "src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.alertNotification {
    &__image {
        width: 80px;
        height: 80px;
    }
    &__info {
        @include t.text("xs");
        width: 100%;
        text-align: center;
    }

    &__attachedFile {
        width: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &__video {
        width: 100%;
        max-height: 300px;
    }

    &__body {
        &__mediaFiles {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__content {
            @include t.text("md");
            color: c.$gray600;
            font-weight: initial;
            word-wrap: break-word;
            white-space: pre-wrap;
            word-break: break-word;
            max-height: 350px;
            overflow: auto;
        }
    }
}
