@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.readOnlyInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    &--auto {
        width: auto;
    }
    &--full {
        width: 100%;
    }
    &__container {
        display: flex;
        padding: 8px;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        background-color: c.$primary25;
        width: 100%;
        justify-content: space-between;
        &--input {
            min-height: 41px;
        }
        &--textArea {
            min-height: 140px;
            align-items: flex-start;
        }
        &--select {
            min-height: 42px;
        }
        &--date {
            min-height: 40px;
        }
    }
    &__value {
        @include t.text("sm", "medium");
        color: c.$gray900;
        flex: 1;
        line-break: anywhere;
    }
    &__placeholder {
        @include t.text("sm", "medium");
        color: c.$gray500;
        flex: 1;
        line-break: anywhere;
    }
    &__icon {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        &__svg {
            text-align: center;
            font-family: "Font Awesome 6 Pro";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 16px;
            flex-shrink: 0;
            &--success {
                color: c.$success500;
            }
            &--error {
                color: c.$error500;
            }
        }
    }
}
