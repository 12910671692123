@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

@mixin RankingPill($padding: 12px) {
    display: flex;
    width: 100%;
    padding: $padding;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
}

.rankingPill {
    &--percentage {
        @include RankingPill();
    }
    &--default {
        @include RankingPill();
    }
    &--thin {
        padding: 8px;
    }
    &--line {
        border: 1px solid c.$gray200;
    }
    &--analist {
        @include RankingPill();
        border-left: 8px solid c.$purple400;
    }
    &--supervisor {
        @include RankingPill();
        border-left: 8px solid c.$green400;
    }
    &--worker {
        @include RankingPill();
        border-left: 8px solid c.$primary400;
    }
    &--thin {
        padding: 8px;
    }
    &--shadow {
        box-shadow: c.$shadow-md;
    }
    &--white {
        background: c.$baseWhite;
    }
    &--primary {
        background: c.$baseWhite;
        outline: 1px solid c.$primary500;
    }
    &__container,
    &__starsBottom {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr 1fr;
        gap: 8px;
        width: 100%;
        height: 92px;
        &--noCounters {
            @extend .rankingPill__container;
        }
    }
    &__starsBottom {
        &--noStars {
            @extend .rankingPill__starsBottom;
            height: auto;
            grid-template-rows: 1fr;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
        overflow: hidden;
        &--noCounters {
            @extend .rankingPill__info;
        }
        &__avatar {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            overflow: hidden;
            height: 49px;
            &--noCounters {
                @extend .rankingPill__info__avatar;
                width: 100%;
            }
            &__icon {
                display: flex;
                width: 25px;
                height: 25px;
                justify-content: center;
                align-items: center;
                &__svg {
                    display: flex;
                    width: 20px;
                    height: 19px;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 20px;
                    color: c.$primary700;
                    cursor: pointer;
                }
            }
        }

        &__stars,
        &__starsBottom {
            display: flex;
            align-items: center;
            gap: 4px;
            height: 100%;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            align-items: flex-end;
            &--noCounters {
                @extend .rankingPill__info__stars;
                width: 100%;
            }
        }
    }
    &__counters {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: space-between;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }
}

@media screen and (min-width: v.$tablet) {
    .rankingPill {
        &__container {
            gap: 12px;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            height: auto;
            &--noCounters {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
        }
        &__info {
            flex-direction: row;
            gap: 12px;
            &--noCounters {
                width: 100%;
                flex-direction: row;
            }
            &__avatar {
                overflow: hidden;
                grid-column: 1 / 2;
                grid-row: 1 / 1;
                &__icon {
                    height: 100%;
                }
            }
            &__stars {
                grid-column: 2 / 3;
                grid-row: 1 / 1;
                align-items: center;
                justify-self: center;
                &--noCounters {
                    height: auto;
                }
            }
        }
        &__counters {
            flex-direction: row;
            gap: 12px;
            grid-column: 3 / 4;
            grid-row: 1 / 1;
            justify-content: flex-end;
            justify-self: flex-end;
        }
    }
}
