@use "/src/styles/colors.scss" as c;

.popover {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: c.$baseWhite;
    border-radius: 10px;
    box-shadow: c.$shadow-2xl;
    overflow: hidden;
    left: 0;
    top: 0;
}
