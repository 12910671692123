@use "/src/styles/colors.scss" as c;

.errorIcon {
    height: 100%;
    display: flex;
    align-items: center;
    width: 30px;
    justify-content: flex-end;
    user-select: none;

    &__icon {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: c.$error500;
    }
}
