@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.chatInputBox {
    background-color: c.$baseWhite;
    outline: 1px solid c.$gray300;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 10px 12px 10px;
    gap: 10px;
    height: 48px;
    width: 100%;
    @include t.text("sm", "medium");

    &--focused {
        @extend .chatInputBox;
        outline: 2px solid c.$primary600;
    }
    &--disabled {
        background-color: #f3f3f3;
    }

    &--borderRadiusAll {
        border-radius: 6px;
    }

    &--borderRadiusBottom {
        border-radius: 0 0 6px 6px;
    }

    &__uploadedFile {
        width: 100%;
    }
    &__container {
        flex-grow: 1;
        display: flex;
        align-items: center;
        height: 20px;
    }
    &__input {
        flex-grow: 1;
        &__audio {
            height: 30px;
            width: 90%;
            &::-webkit-media-controls-panel {
                background-color: white;
            }
        }
        &__img {
            width: 100%;
            max-width: 600px;
            cursor: pointer;
            max-height: 200px;
        }
        &__file {
            background-color: c.$primary50;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px;
            width: 100%;
            height: 50px;
            border-radius: 8px;
            border: 1px solid c.$primary200;
            cursor: pointer;
            gap: 10px;
            &__text {
                color: c.$primary600;
                font-size: 12px;
            }
            &__icon {
                font-size: 16px;
                color: c.$primary600;
            }
        }
    }

    &__icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        &__sendIcon {
            cursor: pointer;
            background-color: c.$primary500;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            font-size: 16px;
            color: c.$baseWhite;
            padding: 5px;
            color: c.$baseWhite;
            &__container {
                cursor: pointer;
                width: 32px;
                min-width: 32px;
                height: 32px;
                background-color: c.$primary500;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
            }
        }
    }
}
