@use "/src/styles/colors.scss" as c;

.goBackIcon {
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &__icon {
        color: c.$baseWhite;
        font-size: 16px;
    }
}
