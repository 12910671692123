@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

.optionCompanySkelleton {
    display: flex;
    padding: 12px 16px 12px 12px;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
    min-height: 86px;
    height: 86px;
    border: 1px solid c.$gray300;
    &__container {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    &__image {
        width: 58px;
        height: 58px;
        border-radius: 5px;
        @include m.skeleton-is-loading(c.$primary300);
    }
    &__text {
        height: 24px;
        border-radius: 12px;
        width: 200px;
        &__bg {
            @include m.skeleton-is-loading(c.$primary100);
            width: 100%;
            height: 16px;
            border-radius: 12px;
        }
    }
}
