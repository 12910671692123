@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.taskHistoryHeader {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    &__plan {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }
    &__title {
        color: c.$baseWhite;
        align-self: flex-start;
        @include t.text("xl", "semibold");
    }
    &__icons {
        display: flex;
        align-items: center;
        gap: 20px;
        align-self: flex-end;
        flex-shrink: 0;
        position: relative;
    }
}

@media screen and (min-width: v.$mobile) {
    .taskHistoryHeader {
        flex-direction: row;
        &__plan {
            flex-direction: row;
            justify-content: space-between;
        }
        &__title {
            align-self: center;
        }
        &__icons {
            align-self: center;
        }
    }
}
