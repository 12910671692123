@use "/src/styles/colors.scss" as c;

.radio {
    width: 20px;
    height: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid c.$primary600;
    cursor: pointer;
    transition: all 200ms;
    flex-shrink: 0;
    min-width: 20px;
    min-height: 20px;

    &.disabled {
        background-color: c.$gray100;
        border-color: c.$gray300;
        cursor: default;
        &:hover {
            background-color: c.$gray100;
            border-color: c.$gray300;
        }
        .radio__interior.disabled {
            background-color: c.$gray100;
        }
    }

    &:hover .radio__interior {
        background-color: c.$primary50;
    }

    &--active:hover {
        .radio__interior {
            background-color: white;
        }
    }
    &__interior {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: white;
        transition: all 200ms;

        &.disabled {
            cursor: default;
        }
    }

    &:hover {
        @extend .radio;
        background-color: c.$primary50;
    }
    &--focus {
        @extend .radio;
        background-color: c.$primary100;
        border-color: c.$primary500;
        .radio__interior {
            background-color: c.$primary100;
        }
        &:hover {
            background-color: c.$primary100;
            border-color: c.$primary500;
            .radio__interior {
                background-color: c.$primary100;
            }
        }
    }

    &--active {
        @extend .radio;
        background-color: c.$primary600;
        &:hover {
            background-color: c.$primary700;
        }
        &--focus {
            @extend .radio;
            background-color: c.$primary800;
            &:hover {
                background-color: c.$primary800;
            }
        }

        &.disabled {
            background-color: c.$gray300;
            &:hover {
                background-color: c.$gray300;
            }

            & .radio__interior.disabled {
                background-color: white;
            }
        }
    }
}
