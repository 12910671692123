@use "/src/styles/variables.scss" as v;

.taskTemporalityDateRangeRepeatEvery {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
    }
}

@media screen and (min-width: v.$tablet) {
    .taskTemporalityDateRangeRepeatEvery {
        &__container {
            flex-direction: row;
        }
    }
}
