@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.taskCheckListItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 6px 8px;
    border-bottom: 1px solid c.$gray300;

    &__checkboxes {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    &__header {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: flex-start;
    }
    &__label {
        color: c.$gray900;
        @include t.text("sm", "regular");
        width: 100%;

        &.isChecked {
            @extend .taskCheckListItem__label;
            text-decoration: line-through;
            color: c.$gray500;
        }
        &.isUnChecked {
            @extend .taskCheckListItem__label;
            color: c.$error700;
        }
    }
    &__numberLabel {
        color: c.$gray900;
        @include t.text("sm", "regular");

        &.isChecked {
            @extend .taskCheckListItem__numberLabel;
            color: c.$gray500;
        }
        &.isUnChecked {
            @extend .taskCheckListItem__numberLabel;
            color: c.$error700;
        }
    }
}
