@use "/src/styles/variables.scss" as v;

.layoutContainer {
    padding: 20px;
    background: #f5f5f5;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (max-width: v.$laptop-1023) {
    .layoutContainer {
        padding: 10px;
    }

    .layoutContainer.white {
        background: white;
    }

    .layoutContainer.grey {
        background: #f5f5f5;
    }
}
