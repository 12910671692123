@use "/src/styles/variables.scss" as v;

.fullScreenQrModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    justify-content: center;
    flex: 1 0 0;
}

@media screen and (min-width: v.$tablet) {
    .fullScreenQrModal {
        gap: 64px;
    }
}
