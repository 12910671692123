@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.checklistItemReadOnly {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style: none;
    &__option {
        @include t.text("sm", "semibold");

        color: c.$primary900;

        &::first-letter {
            text-transform: none;
        }
    }
}
