.createNotificationDistributionListUsersModal {
    &__addAll {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &__button {
            display: flex;
            min-width: 130px;
            justify-content: flex-end;
        }
    }
    &__label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
    }
    &__inputs {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &__content {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
}
