@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.sidebarItem {
    display: flex;
    gap: 10px;
    padding: 8px 12px;
    @include t.text("sm", "medium");
    color: c.$gray700;
    align-items: center;
    cursor: pointer;
    width: 100%;
    transition: background-color 200ms;
    border-radius: 4px;
    &__link {
        @include t.text("sm", "medium");
        text-decoration: none;
        color: c.$gray700;
    }
    &__icon {
        font-size: 16px;
    }
    &__sidebarIcon {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        background-color: c.$gray100;
        &__link {
            color: c.$gray700;
        }
    }
}
