@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/mixin/clamp" as m;

.mobileIssueRowBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    &__info {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
    }
    &__id {
        @include t.text("xs", "medium");
        color: c.$gray800;
        @include m.textclamp();
    }
    &__openDate {
        @include t.text("xs", "regular");
        color: c.$gray700;
        @include m.textclamp();
    }
    &__description {
        @include t.text("xs", "regular");
        color: c.$gray700;
        @include m.textclamp(2);
    }
    &__auditCode {
        @include t.text("xs", "regular");
        color: c.$gray400;
        @include m.textclamp(2);
    }
}
