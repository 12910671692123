.collapsableBody {
    display: grid;
    grid-template-rows: 1fr;
    opacity: 1;
    transition: grid-template-rows 200ms, opacity 200ms;
    &--closed {
        @extend .collapsableBody;
        grid-template-rows: 0fr;
        opacity: 0;
        overflow: hidden;
    }
    &__children {
        display: flex;
        flex-direction: column;
        gap: 12px;
        min-height: 0;
    }
}
