.modulesForm {
    display: flex;
    flex-direction: column;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__spinner {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            align-items: center;
        }
    }
}
