@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.editAudit {
    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: c.$baseWhite;
        text-align: left;
        @include t.text("lg", "semibold");
    }
    &__group {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }
    &__grade {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        color: c.$baseWhite;
        width: 100%;
    }
}

@media screen and (min-width: v.$mobile) {
    .editAudit {
        &__header {
            flex-direction: row;
            gap: 8px;
        }
        &__grade {
            flex-direction: row;
            width: fit-content;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .editAudit__grade {
        width: auto;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
}
@media screen and (min-width: v.$laptop) {
    .editAudit {
        padding-bottom: 20px;
    }
}
