@use "/src/styles/variables.scss" as v;

.skeleton-box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    border-radius: 10px;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: "";
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

.skelleton-img {
    width: 5.1rem;
    height: 5.1rem;
}

.blog-post {
    &__headline {
        font-size: 1.25em;
        font-weight: bold;
    }

    &__meta {
        font-size: 0.85em;
        color: #6b6b6b;
    }
}

// OBJECTS
.taskpillListskelleton {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}
.o-media {
    display: flex;
    flex-direction: row-reverse;
    box-shadow: v.$shadow-mid;
    height: 112px;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    background-color: white;

    &__body {
        flex-grow: 1;
    }
}

.op-0 {
    width: 90%;
    opacity: 0;
}
.w-5 {
    width: 5%;
}
.w-30 {
    width: 50%;
}
.w-50 {
    width: 50%;
}
.w-60 {
    width: 60%;
}
.w-75 {
    width: 75%;
}
.w-90 {
    width: 90%;
}

.skeletonTaskpillHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    &__left {
        display: flex;
        align-items: center;
        gap: 8px;
        &__avatar {
            width: 18px;
            height: 18px;
            border-radius: 100%;
        }
        &__username {
            width: 60px;
            height: 16px;
        }
    }
    &__right {
        display: flex;
        align-items: center;
        gap: 8px;
        &__checkbox {
            width: 18px;
            height: 18px;
            border-radius: 100%;
        }
        &__badge {
            width: 60px;
            height: 16px;
        }
    }
}

.skeletonTaskpillFooter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    &__date {
        width: 60px;
        height: 16px;
        &__container {
            display: flex;
            align-items: center;
            gap: 8px;
            &__icons {
                width: 60px;
                height: 16px;
            }
        }
    }
}
