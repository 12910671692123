@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.loginInputForm {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    max-width: 375px;
    min-height: 0;
    background-color: c.$baseWhite;
    box-shadow: c.$shadow-lg;
    border-radius: 12px;
    padding: 32px 16px;
    min-height: 0;
    &__title {
        @include t.display("xs", "semibold");
        color: c.$gray900;
        text-align: center;
    }
    &__container,
    &__fields {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
        overflow: auto;
        &__inputs {
            @extend .loginInputForm__container;
        }
    }

    &__container {
        overflow: hidden;
    }
    &__fields {
        overflow: auto;
    }

    &__passwordItem {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        &__recoverPassword {
            @include t.text("sm", "semibold");
            color: c.$primary700;
            align-self: flex-end;
            cursor: pointer;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .loginInputForm {
        max-width: 400px;
        padding: 32px;
    }
}
