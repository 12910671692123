@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.taskReview {
    border: 1px solid c.$gray200;
    background-color: c.$gray50;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 8px;

    &--danger {
        @extend .taskReview;
        border: 1px solid c.$error200;
    }
    &--green {
        @extend .taskReview;
        border: 1px solid c.$success200;
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
    }

    &__comment {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        gap: 6px;

        &__name {
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            color: c.$primary600;
            @include t.text("sm", "semibold");
        }
        &__date {
            color: c.$gray600;
            @include t.text("xs");
        }
        &__comment {
            @include t.text("xs", "medium");
            color: c.$gray500;
            width: 100%;
            text-align: left;
            word-wrap: break-word;
        }
    }

    &__audio {
        width: 100%;
        display: flex;
        justify-content: center;
        &__player {
            width: 100%;
            height: 30px;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .taskReview {
        height: fit-content;
    }
}
