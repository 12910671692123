@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.tabHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 48px;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &--primary {
        background-image: url("../../../../../public/headerBackground.jpg");
        background-color: c.$header-bg;
    }

    &--appcc {
        background: c.$header-bg-gradient-appcc;
    }

    &__breadcrums {
        width: 100%;
        gap: 32px;
        padding: 0 14px;
        &--fullWidth {
            max-width: 100% !important;
        }
        &--sm {
            @extend .tabHeader__breadcrums;
        }
        &--md {
            @extend .tabHeader__breadcrums;
        }
        &--xl {
            @extend .tabHeader__breadcrums;
        }
    }
    &--noChildren {
        @extend .tabHeader;
        gap: 12px;
    }

    &--noPadding {
        @extend .tabHeader;
        padding-top: 12px;
    }
    &--p36 {
        padding-top: 36px;
    }

    &__body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        &--fullWidth {
            max-width: 100% !important;
        }

        &--sm {
            padding: 12px;
        }
        &--md {
            padding: 20px 12px;
        }
        &--xl {
            padding: 20px;
        }
    }

    &__tabs {
        width: 100%;
        &--fullWidth {
            max-width: 100% !important;
        }

        display: flex;
        align-items: center;
        padding: 0 12px;
        &--40 {
            height: 40px;
        }
        &--50 {
            height: 50px;
        }
    }
}

.tabHeder__column {
    display: flex;
    flex-direction: column !important;
}
.pageTitle {
    @include t.text("xl", "semibold");
    color: c.$baseWhite;
    display: none;
}

.grayBlendMode {
    @extend .tabHeader;
    z-index: 0;
    position: relative;
    &--primary {
        background-image: url("../../../../../public/headerBackground.jpg");
        background-color: c.$header-bg;
    }

    &--appcc {
        background: c.$header-bg-gradient-appcc;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: inherit;
        filter: grayscale(100%);
        z-index: -1;
    }
}

@media screen and (min-width: v.$tablet) {
    .tabHeader {
        &__body {
            flex-direction: row;
            justify-content: space-between;
            padding: 20px;
        }

        &__tabs {
            padding: 0 20px;
        }
    }
    .pageTitle {
        display: block;
    }
}

@media screen and (min-width: v.$laptop) {
    .tabHeader {
        &--p36 {
            padding: 0;
        }
        &__body {
            max-width: v.$layoutMaxWidth;
            margin: 0 auto;
            gap: 20px;
            &--sm {
                padding-bottom: 12px;
                padding-top: 12px;
            }
        }

        &__tabs {
            max-width: v.$layoutMaxWidth;
            display: flex;
            align-items: center;
            gap: 32px;
            margin: 0 auto;
        }
        &__breadcrums {
            max-width: v.$layoutMaxWidth;
        }
    }
}
