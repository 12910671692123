@use "/src/styles/variables.scss" as v;
.taskTemporality {
    grid-column: 1 / 2;
    display: flex;
    width: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }
    &__hours {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 219px;
    }
    &__multiHours {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &__rangeHours {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 12px;
    }
    &__customRepeat {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }
}

@media screen and (min-width: v.$tablet) {
    .taskTemporality {
        grid-column: 1 / 3;
    }
}

@media screen and (min-width: v.$laptop) {
    .taskTemporality {
        grid-column: 1 / 2;
    }
}
