@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.pageContainer--md > *,
.pageContainer--sm > * {
    padding-bottom: 12px;
}

.pageContainer {
    width: 100%;
    &--fullHeight {
        height: 100%;
    }
    margin: 0 auto;
    padding: 12px 12px 0px 12px;

    &--p48 {
        padding-top: 60px;
    }

    background-color: c.$gray100;
    &--white {
        background-color: c.$baseWhite;
    }
}

@media screen and (min-width: v.$laptop) {
    .pageContainer--sm > * {
        padding-bottom: 12px;
    }
    .pageContainer--md > * {
        padding-bottom: 20px;
    }

    .pageContainer {
        max-width: v.$layoutMaxWidth;
        padding: 20px 20px 0px 20px;

        &--p48 {
            padding-top: 68px;
        }

        &--fullWidth {
            padding: 20px 20px 0px 20px;
            max-width: 100%;
        }
    }
}
