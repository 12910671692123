@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.alertPillBody {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    align-items: center;
    width: 100%;

    &__messageAudio {
        @extend .alertPillBody;
        flex-direction: column;
        &--maxWidth60 {
            @extend .alertPillBody__messageAudio;
            max-width: 60%;
        }
    }
    &__message {
        @include t.text("xs");
        color: c.$gray500;
        &--maxWidth {
            @extend .alertPillBody__message;
            width: 100%;
            max-width: 100%;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .alertPillBody__message--maxWidth {
        width: 100%;
        max-width: 100%;
    }
}
