@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.auditSelector {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;
    padding: 0 4px;
    flex-shrink: 0;
    position: relative;
    border-radius: 4px;
    padding: 4px;
    height: 28px;
    width: 170px;

    &__text {
        color: c.$gray600;
        @include t.text("xs", "medium");
        user-select: none;
    }
    &__order {
        display: flex;
        gap: 2px;

        &__text {
            color: c.$gray600;
            @include t.text("xs", "medium");
            user-select: none;
        }
        &__icon {
            width: 16px;
            height: 16px;
            color: c.$gray600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0em;
            font-weight: 500;
            -webkit-user-select: none;
            user-select: none;
        }
    }

    &__popover {
        width: 180px;
    }
}

@media screen and (min-width: v.$mobile) {
    .auditSelector {
        &__text {
            color: c.$baseWhite;
        }
        &__order {
            &__text {
                color: c.$baseWhite;
            }
            &__icon {
                color: c.$baseWhite;
            }
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .auditSelector {
        &__text {
            display: block;
        }
        transition: background-color 200ms;
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
}
