@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.taskPillPercentage {
    width: 200px;
    height: 18px;
}

@media screen and (min-width: v.$tablet) {
    .taskPillPercentage {
        width: 150px;
    }
}
