@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.fullScreenQrModalHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    &__title {
        color: c.$gray100;
        @include t.text("md", "medium");
        text-align: center;
        text-wrap: balance;
    }
    &__options {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 52px;
    }
}
