@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.taskDataInputPopoverChildren {
    &__text,
    &__searching {
        color: c.$gray700;
        @include t.text("sm", "medium");
        padding: 11px 14px;
    }
    &__header {
        display: flex;
        gap: 8px;
        width: 100%;
        padding: 11px 14px;
        align-items: center;
    }
    &__title {
        color: c.$gray700;
        @include t.text("sm", "medium");
    }
    &__icon {
        width: 16px;
        height: 16px;
        color: c.$warning600;
        text-align: center;
        font-size: 16px;
    }
    &__list {
        display: flex;
        flex-direction: column;
        list-style: none;
    }
}
