.taskValorationModalHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    &__info {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 4px;
    }
}
