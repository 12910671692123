@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.starValorationContainer {
    display: flex;
    gap: 8px;
}

.starValorationIcon {
    font-size: 38px;
    color: c.$gray200;

    &--pointer {
        @extend .starValorationIcon;
        &:hover {
            cursor: pointer;
        }
    }

    &--primary {
        color: c.$primary500;
    }

    &--yellow {
        color: c.$yellow400;
    }
}
