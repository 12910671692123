@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.generateQr {
    display: flex;
    max-width: 775px;
    padding: 18px 10px 0px 10px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 50vh;
    border-bottom: 1px solid #eee;
    &__header {
        display: flex;
        padding: 40px;
        align-items: center;
        gap: 32px;
        border-radius: 20px;
        border: 1px solid #2f368c;
        background: linear-gradient(95deg, rgba(47, 54, 140, 0.15) -3.36%, rgba(47, 54, 140, 0.05) 104.45%);
        width: 100%;
        height: 64%;
    }
    &__qrCode {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 8px;
        &__image {
            width: 168px !important;
            height: 168px !important;
            flex-shrink: 0;
        }
        &__label {
            color: #2f368c;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.88px;
            text-align: center;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
        padding-bottom: 24px;
        &__companyName {
            color: #2f368c;
            font-size: 49px;
            font-style: normal;
            font-weight: 700;
            line-height: 49px;
            letter-spacing: -0.88px;
        }
        &__qrName {
            color: #0082c8;
            font-family: Helvetica;
            font-size: 31px;
            font-style: normal;
            font-weight: 700;
            line-height: 31px;
        }
    }
    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 120px;
        align-self: stretch;
        height: 36%;
    }
    &__companyPhoto {
        height: 140px;
    }
    &__logo {
        height: 55px;
    }
}

@media print {
    .generateQr {
        &__header {
            background-color: linear-gradient(95deg, rgba(47, 54, 140, 0.15) -3.36%, rgba(47, 54, 140, 0.05) 104.45%);
            print-color-adjust: exact;
        }
    }
}
