@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.dataToReportReadOnly {
    display: flex;
    width: 100%;
    gap: 8px;
    flex-direction: column;
    &__header {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    &__title {
        color: c.$primary600;
        @include t.text("sm", "semibold");
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }
}
