.issue {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__spinner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
    }
}
