@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.criticalTask {
    display: flex;
    align-items: center;
    gap: 3px;
    background-color: c.$gray500;
    padding: 3px 8px;
    border-radius: 27px;
    height: 24px;

    &__icon {
        font-weight: 901;
        font-size: 15px;
        line-height: 15px;
        color: c.$gray300;
        background-color: c.$baseWhite;
        border-radius: 50%;
        display: flex;
        align-items: center;
        text-align: center;

        &--gray {
            @extend .criticalTask__icon;
            color: c.$baseWhite;
            background-color: transparent;
            font-size: 12px;
            line-height: 12px;
        }
    }

    &__title {
        @include t.text("xs", "semibold");
        text-transform: uppercase;
        color: white;
        font-weight: 600;
    }
}
