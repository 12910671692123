@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

@mixin lastChild() {
    cursor: default;
    color: c.$baseWhite;
    @include t.text("sm", "semibold");
    text-decoration: none;
    background-color: transparent;
}

.breadcrumb {
    &__item {
        @include t.text("sm", "medium");
        cursor: pointer;
        border-radius: 4px;
        display: inline-flex;
        padding: 2px 6px;
        justify-content: center;
        align-items: center;
        &--primary {
            color: c.$primary100;
        }
        &--appcc {
            color: c.$appcc100;
        }

        &:hover {
            color: c.$baseWhite;
            background-color: rgba(255, 255, 255, 0.1);
        }
        &--lastCrumb {
            @include lastChild();
            &:hover {
                @include lastChild();
            }
        }
    }
    &__separator {
        display: flex;
        width: 13px;
        height: 13px;
        justify-content: center;
        align-items: center;
        &__icon {
            color: c.$primary300;
            text-align: center;
            font-size: 11px;
            font-weight: 900;
            &--primary {
                color: c.$primary300;
            }
            &--appcc {
                color: c.$appcc300;
            }
        }
    }
}
