@use "/src/styles/colors.scss" as c;

.webcamCapture {
    width: 100%;
    &__container {
        background-color: c.$gray900;
        height: 100dvh;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 20px;
        padding: 12px 0px;
    }
}
