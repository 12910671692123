@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.genericModalHeader {
    display: flex;
    width: 100%;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    &--primary {
        background-color: c.$primary600;
    }
    &--appcc {
        background-color: c.$appcc600;
    }

    &--radius {
        border-radius: 8px 8px 0px 0px;
    }
    &__container {
        display: flex;
        width: 100%;
        min-height: 48px;
        height: 48px;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
    }
    &__title {
        color: c.$baseWhite;
        text-align: center;
        @include t.text("sm", "semibold");
        width: 100%;
        margin-left: 20px;
        &--withIcon {
            color: c.$baseWhite;
            text-align: center;
            @include t.text("sm", "semibold");
            margin-left: 20px;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .genericModalHeader {
        border-radius: 8px 8px 0px 0px;
    }
}
