@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;

.gridPage__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: c.$gray800;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.groupCompany {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__onBoardingButton {
        margin-bottom: 12px;
    }
}
