@use "/src/styles/variables.scss" as v;

.workingPositionFormPageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.workingPositionForm {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    &__body {
        display: flex;
        flex-direction: column;
        padding: 0 12px 20px 12px;
        gap: 20px;
    }
    &__badge {
        margin-left: 12px;
    }
    &__inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

@media screen and (min-width: v.$tablet) {
    .workingPositionForm {
        &__body {
            padding: 0 20px 20px;
        }
        &__inputs {
            flex-direction: row;
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .workingPositionForm {
        &__body {
            padding: 0;
            padding-bottom: 20px;
        }
        &__inputs {
            flex-direction: row;
        }
    }
}
