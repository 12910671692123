@use "/src/styles/colors.scss" as c;

.tableBody {
    border-bottom: 1px solid c.$gray200;
    background: c.$baseWhite;
    &:nth-child(odd) {
        background: c.$baseWhite;
        &:hover {
            background: c.$gray100;
        }
    }
    &:nth-child(even) {
        background: c.$gray25;
        &:hover {
            background: c.$gray100;
        }
    }
    &--primary {
        &--focused {
            @extend .tableBody;
            &:nth-child(odd) {
                background: c.$primary50;
                &:hover {
                    background: c.$primary100;
                }
            }
            &:nth-child(even) {
                background: c.$primary50;
                &:hover {
                    background: c.$primary100;
                }
            }
        }
    }
    &--appcc {
        &--focused {
            @extend .tableBody;
            &:nth-child(odd) {
                background: c.$appcc50;
                &:hover {
                    background: c.$appcc100;
                }
            }
            &:nth-child(even) {
                background: c.$appcc50;
                &:hover {
                    background: c.$appcc100;
                }
            }
        }
    }
    &__row {
        height: 56px;
        padding: 11px 12px;
    }
}
