@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.issueBodyDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    &__description {
        color: c.$gray900;
        @include t.text("md", "regular");
    }
}
