@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.openClosedOptionCompanyCollapsable {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__companyGroup {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &__label {
            color: c.$primary600 !important;
            @include t.text("sm", "semibold");
        }
        &__department {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 12px;
        }
    }
}
