@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;
@use "/src/styles/variables.scss" as v;

.taskPillBodyHeader-skelleton {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        width: 100%;
        &__circle {
            width: 20px;
            height: 20px;
            @include m.skeleton-is-loading(c.$gray300);
            border-radius: 50%;
        }
        &__badge {
            display: flex;
            height: 24px;
            padding: 3px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 27px;
            @include m.skeleton-is-loading(c.$gray200);
            width: 76px;
        }
        &__reopen {
            width: 24px;
            height: 24px;
            @include m.skeleton-is-loading(c.$gray400);
            border-radius: 50%;
        }
    }
}
