@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.buttonGroup {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__label {
        text-align: left;
        color: c.$gray700;
        @include t.text("sm", "medium");
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        overflow: hidden;
    }

    &__content {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__item {
        cursor: pointer;
        border: 1px solid c.$primary500;
        color: c.$primary500;
        border-left: none;
        height: 40px;
        display: flex;
        align-items: center;
        @include t.text("sm", "semibold");
        padding: 10px 16px;
        user-select: none;
        min-width: 50px;

        &:hover {
            background-color: c.$primary50;
        }

        &__selected {
            background-color: c.$primary500;
            color: c.$baseWhite;
            &:hover {
                background-color: c.$primary500;
            }
        }

        &:first-of-type {
            border-left: 1px solid c.$primary500;
            border-radius: 32px 0 0 32px;
        }
        &:last-of-type {
            border-radius: 0 32px 32px 0%;
        }

        &--minWidth {
            @extend .buttonGroup__item;
            width: inherit;
            justify-content: center;
        }
    }
}

.invertedGroup {
    @extend .buttonGroup__item;
    background-color: c.$primary500;
    color: c.$baseWhite;
    border: 1px solid c.$primary500;
    border-left: 1px solid c.$baseWhite;

    &:hover {
        background-color: c.$primary50;
        color: c.$primary500;
    }

    &__selected {
        background-color: c.$baseWhite;
        color: c.$primary500;
        border-right: 1px solid c.$baseWhite;
        &:last-child {
            border-right-color: c.$primary500;
        }

        &:hover {
            background-color: c.$baseWhite;
        }
    }
    &:first-of-type {
        border-left: 1px solid c.$primary500;
        border-left: 1px solid c.$primary500;
    }
}

@media screen and (min-width: v.$tablet) {
    .buttonGroup__item--minWidth {
        @extend .buttonGroup__item;
        width: 100px;
        justify-content: center;
    }
}
