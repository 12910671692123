@use "./colors.scss" as c;

$display-sizes: (
    "2xl": (
        "font-size": 72px,
        "line-height": 90px,
        "letter-spacing": -0.02em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
    "xl": (
        "font-size": 60px,
        "line-height": 72px,
        "letter-spacing": -0.02em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
    "lg": (
        "font-size": 48px,
        "line-height": 60px,
        "letter-spacing": -0.02em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
    "md": (
        "font-size": 36px,
        "line-height": 44px,
        "letter-spacing": -0.02em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
    "sm": (
        "font-size": 30px,
        "line-height": 38px,
        "letter-spacing": 0em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
    "xs": (
        "font-size": 24px,
        "line-height": 32px,
        "letter-spacing": 0em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
);

$text-sizes: (
    "xl": (
        "font-size": 20px,
        "line-height": 30px,
        "letter-spacing": 0em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
    "lg": (
        "font-size": 18px,
        "line-height": 28px,
        "letter-spacing": 0em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
    "md": (
        "font-size": 16px,
        "line-height": 24px,
        "letter-spacing": 0em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
    "sm": (
        "font-size": 14px,
        "line-height": 20px,
        "letter-spacing": 0em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
    "xs": (
        "font-size": 12px,
        "line-height": 18px,
        "letter-spacing": 0em,
        "color": c.$gray900,
        "weights": (
            "regular": 400,
            "medium": 500,
            "semibold": 600,
            "bold": 700,
        ),
    ),
);

@mixin display($size, $weight: 400) {
    $sizeData: map-get($display-sizes, $size);

    font-size: map-get($sizeData, "font-size");
    line-height: map-get($sizeData, "line-height");
    letter-spacing: map-get($sizeData, "letter-spacing");

    font-weight: map-get(map-get($sizeData, "weights"), $weight);
}

@mixin text($size, $weight: 400) {
    $sizeData: map-get($text-sizes, $size);

    font-size: map-get($sizeData, "font-size");
    line-height: map-get($sizeData, "line-height");
    letter-spacing: map-get($sizeData, "letter-spacing");

    font-weight: map-get(map-get($sizeData, "weights"), $weight);
}
