@use "/src/styles/colors.scss" as c;

.uploadFile {
    cursor: pointer;
    display: flex;
    align-items: center;

    &__icon {
        cursor: "pointer";
        font-size: 16px;
        color: c.$primary500;
    }
    &__input {
        display: none;
    }
}
