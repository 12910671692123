@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;
@use "/src/styles/variables.scss" as v;

.taskPillBodyRating-skelleton {
    display: flex;
    padding: 15.448px;
    align-items: flex-start;
    width: 100%;
    background-color: c.$gray50;
    justify-content: center;
    &__starIcon {
        display: flex;
        align-items: center;
        gap: 7.724px;
        &__icon {
            width: 38px;
            height: 38px;
            color: c.$gray200;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .taskPillBodyRating-skelleton {
        width: 260px;
        height: 56px;
        justify-content: center;
        align-self: center;
        padding: 8px;
        align-items: center;
    }
}
