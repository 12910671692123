@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.onBoardingSteps {
    gap: 8px;
    display: grid;
    grid-template-columns: 1fr;
    &__header {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
    }
    &__title {
        color: c.$baseWhite;
        @include t.text("xl", "semibold");
    }
    &__buttons {
        display: flex;
        gap: 8px;
    }
    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

@media screen and (min-width: v.$tablet) {
    .onBoardingSteps {
        grid-template-columns: repeat(2, 1fr);
    }
}
