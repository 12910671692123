@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.squareButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    &__btn {
        all: unset;
        border-radius: 4px;
        border: 1px solid c.$primary300;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &--focus {
            @extend .squareButton__btn;
            border: 1px solid c.$baseWhite;
        }

        &:hover {
            border: 1px solid c.$baseWhite;
        }
    }
    &__icon {
        display: flex;
        width: 42px;
        height: 42px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        &__content {
            color: c.$primary300;
            text-align: center;
            font-size: 33px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &--focus {
                color: c.$baseWhite;
                @extend .squareButton__icon__content;
            }
        }
    }

    &__text {
        color: c.$primary300;
        @include t.text("md", "bold");
        &--focus {
            color: c.$baseWhite;
            @extend .squareButton__text;
        }
    }
}
