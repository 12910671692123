@use "/src/styles/variables.scss" as v;

.starRatingBadge {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    &--col {
        @extend .starRatingBadge;
        flex-direction: column;
    }
    &--row {
        @extend .starRatingBadge;
        flex-direction: row;
    }
    &--flex-start {
        justify-content: flex-start;
    }

    &--flex-end {
        justify-content: flex-end;
    }
    &__badge {
        width: fit-content;
    }
}

@media screen and (min-width: v.$tablet) {
    .starRatingBadge {
        flex-direction: row;
        gap: 12px;
        &--col {
            @extend .starRatingBadge;
            flex-direction: row;
        }
        &--row {
            @extend .starRatingBadge;
            flex-direction: row;
        }
        &--flex-start {
            justify-content: flex-start;
        }

        &--flex-end {
            justify-content: flex-end;
        }
    }
}
