@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.bar-chart {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__container {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 4px;
    }
    &__y-axis-title {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        text-align: center;
        @include t.text("sm", "medium");
    }
    &__x-axis-title {
        text-align: center;
        @include t.text("sm", "medium");
    }
    &__chart-container {
        width: 100%;
        height: 230px;
        position: relative;
    }
    &__chart {
        width: 100% !important;
        height: 100% !important;
    }
}
