@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.inputWithPopover {
    width: 100%;
    &__input {
        width: 100%;
    }
    &__results {
        position: relative;
        width: 100%;
    }
    &__popover {
        border: 1px solid c.$gray300;
    }
    &__item {
        width: 100%;
        max-height: 350px;
        background-color: c.$baseWhite;
        border-radius: 10px;
        box-shadow: c.$shadow-2xl;
    }
}
