@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;

.text_clamp {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    width: 100%;
    font-size: inherit;
    word-break: break-word;
}

.text_clamp-3 {
    -webkit-line-clamp: 3;
}

.cursorPointer {
    cursor: pointer;
}

.flex {
    display: flex;
    align-items: center;
    width: 100%;
    &--center {
        @extend .flex;
        justify-content: center;
    }
    &--between {
        @extend .flex;
        justify-content: space-between;
    }
    &--end {
        @extend .flex;
        justify-content: flex-end;
    }
    &--column {
        @extend .flex;
        flex-direction: column;
    }
}

.gap {
    &--8 {
        gap: 8px;
    }
    &--12 {
        gap: 12px;
    }
    &--20 {
        gap: 20px;
    }
}

.flexColumn {
    flex-direction: column;
}

.fullWidth {
    width: 100% !important;
}

.fullHeight {
    height: 100% !important;
}

.padding20 {
    padding: 20px;
}

.spinnerFullHeightCenteredContainer {
    height: 100% !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cursorPointer {
    cursor: pointer;
}

.main_content {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding-bottom: 52px;
    &--removePadding {
        @extend .main_content;
        padding-bottom: 0;
    }
    &--mobile {
        padding-bottom: 63px;
    }
}

.pointer {
    cursor: pointer !important;
}
.cursorDefault {
    cursor: default !important;
}

.default {
    cursor: default;
}

.capitalize {
    text-transform: capitalize;
}

@media screen and (min-width: v.$tablet) {
    .main_content {
        padding-bottom: 0;
    }
}
.onlyInMobileFlexColumn {
    flex-direction: row;
}
.removePaddingAndMargin {
    padding: 0 !important;
    margin: 0 !important;
}

@media screen and (max-width: v.$mobile-767) {
    .onlyInMobileFlexColumn {
        flex-direction: column;
    }
    .displayNoneMobile {
        display: none;
    }
    .mobile_display_none {
        display: none;
    }
}

.alignCenter {
    justify-content: center;
}

.alignEnd {
    justify-content: flex-end;
}

.react-datepicker-wrapper {
    width: 100% !important;
}
.wrap {
    display: flex;
    flex-wrap: wrap;
}

.text_clamp {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    width: 100%;
    font-size: inherit;
}

.text_clamp-3 {
    -webkit-line-clamp: 3;
}

.text_clamp-1 {
    -webkit-line-clamp: 1;
}

.relative {
    position: relative;
}

.width--auto {
    width: auto !important;
}

.paddingIos {
    padding-bottom: 18px !important;
    padding-top: 5px !important;
}

.showScrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(177, 177, 177, 0.6) rgba(0, 128, 0, 0);
    scrollbar-arrow-color: transparent;

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}
.showScrollbarMobileOnly {
    scrollbar-width: thin;
    scrollbar-color: rgba(177, 177, 177, 0.6) rgba(0, 128, 0, 0);
    scrollbar-arrow-color: transparent;

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}
.paddingX12 {
    padding: 0px 12px;
}

.radius {
    &--6 {
        border-radius: 6px;
    }
}
.paddingX20 {
    padding: 0px 20px;
}
.showScrollbarDesktopOnly {
    scrollbar-width: none;
    background-color: none;
    &::-webkit-scrollbar {
        width: 5px !important;
    }
}

@media screen and (min-width: v.$tablet) {
    .showScrollbarMobileOnly {
        scrollbar-width: none;
    }
    .showScrollbarDesktopOnly {
        scrollbar-width: auto;
        scrollbar-arrow-color: transparent;
        scrollbar-color: rgba(177, 177, 177, 0.6) rgba(0, 128, 0, 0);
    }
}

.textAlignCenter {
    width: 100%;
    text-align: center;
}

.w50 {
    width: 50% !important;
}

.rounded {
    border-radius: 50%;
}

.zIndexUp {
    z-index: 999 !important;
}

* {
    scrollbar-width: none;
}

.hide {
    display: none !important;
}

audio {
    height: 32px;
}
audio::-webkit-media-controls-panel {
    background-color: c.$baseWhite;
}
