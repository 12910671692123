@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.chatBotMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;

    &--user {
        align-items: flex-end;
    }
    &__text {
        @include t.text("sm", "regular");
        padding: 10px 14px;
        display: flex;
        flex-direction: column;
        min-width: 150px;
        gap: 4px;

        &--user {
            border-radius: 8px 0px 8px 8px;
            background-color: c.$primary600;
            color: c.$baseWhite;
        }
        &--assistant {
            border-radius: 0px 8px 8px 8px;
            background-color: c.$gray100;
            color: c.$gray900;
        }

        &--error {
            color: c.$error700;
            outline: 1px solid c.$error300;
            background: c.$error50;
        }
        &__list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-left: 30px;
        }
    }
}
