@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.mobileBaseAuditGroupCheckListItemRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    &__title {
        @include t.text("xs", "medium");
    }
    &__type {
        @include t.text("xs");
    }
    &__rightData {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
    }
    &--odd {
        @extend .mobileBaseAuditGroupCheckListItemRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .mobileBaseAuditGroupCheckListItemRow;
        background: c.$gray25;
    }
    &--focused {
        @extend .mobileBaseAuditGroupCheckListItemRow;
        background-color: c.$primary50;
    }
}

@media screen and (min-width: v.$tablet) {
    .mobileBaseAuditGroupCheckListItemRow__description {
        flex-direction: row;
    }
}
