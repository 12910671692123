@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.genericModalFooter {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    width: 100%;
    background-color: c.$baseWhite;
    &--boxShadow {
        box-shadow: 0px -4px 13px 0px rgba(0, 0, 0, 0.1);
    }
    &--borderTop {
        border-top: 1px solid c.$gray200;
    }
    &--spaceBetween {
        justify-content: space-between;
        gap: 12px;
    }
    &--end {
        justify-content: flex-end;
    }
}

@media screen and (min-width: v.$tablet) {
    .genericModalFooter {
        box-shadow: none;
    }
}
