@use "/src/styles/colors.scss" as c;

.mainInputFile {
    &__wrapper {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        gap: 12px;
        & img {
            width: 100%;
            max-height: 300px;
            object-fit: contain;
            max-width: 400px;
        }
    }
    &__imageContainer {
        display: flex;
        width: 100%;
        justify-content: flex-start;

        & > img {
            border: 1px solid c.$gray200;
        }
    }
    &-visible__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: c.$primary500;
        padding: 0.75rem 2rem;
        font-size: 11px;
        border-radius: 5px;
        color: #fff;
    }
    &-visibleLabel {
        cursor: pointer;
        overflow-x: hidden;
    }
    &-visibleLabel:hover {
        text-decoration: underline;
    }
    &__icon {
        width: 30px;
        height: 30px;
    }
    &__input {
        cursor: inherit;
        font-size: 16px;
    }
    &Preview {
        &_button {
            width: 50%;
            color: c.$primary500;
            border-color: c.$primary500;
            &__wrapper {
                display: flex;
                justify-content: space-between;
                margin-top: 1rem;
                gap: 0.5rem;
            }
            &:hover,
            &:focus {
                color: c.$second-color;
                border-color: c.$second-color;
            }
        }
    }
}

.dragFileWithPreview {
    &__buttons {
        display: flex;
        gap: 12px;
    }
}
