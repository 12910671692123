@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.issueModalHeader {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
        color: c.$gray700;
        @include t.text("sm", "bold");
    }
    &__subtitle {
        color: c.$gray700;
        @include t.text("sm", "medium");
    }
}
