@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.mobilePatchNoteRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 8px;
    gap: 12px;
    @include t.text("sm");
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
        width: 100%;
        &__text {
            display: flex;
            gap: 8px;
            &__title {
                @include t.text("sm", "medium");
            }
        }
    }
    &__actions {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }
}
