@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.placeholder {
    height: 100%;
    width: 100%;
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    background-color: c.$gray100;
    &__iconContainer {
        display: flex;
        padding: 64px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 50%;
        background-color: c.$gray50;
        &__icon {
            display: flex;
            width: 80px;
            height: 80px;
            justify-content: center;
            align-items: center;
            color: c.$gray400;
        }
    }
    &__text {
        color: rgba(84, 87, 89, 1);
        @include t.text("md", "regular");
        font-size: 17px;
    }
}

@media screen and (min-width: v.$tablet) {
    .placeholder {
        padding: 0px 40px;
        flex-direction: row;
        &__text {
            width: 300px;
        }
    }
}
