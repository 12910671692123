@use "/src/styles/colors.scss" as c;

.globalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow-y: hidden;
    &--noDisturb {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: hidden;
    }
    &--noConexion {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        border: 2px solid c.$error500;
        position: relative;
        overflow-y: hidden;
    }

    &__mode {
        &--noDisturb {
            display: flex;
            padding: 0px 4px;
            align-items: flex-start;
            color: c.$baseWhite;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            background-color: c.$warning500;
            height: 18px;
            width: 100%;
        }
    }

    &__link {
        text-decoration: underline;
        cursor: pointer;
    }
}
