@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileAutomaticRecord {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    &__titles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        @include t.text("xs");
        &__title {
            display: flex;
            gap: 4px;
            flex-wrap: wrap;
        }
        &__subtitle {
            @extend .mobileAutomaticRecord__titles;
            @include t.text("xs", "medium");
        }
    }
    &__data {
        min-width: 40%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        @include t.text("xs");
    }
}
