@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.assetDynamicFields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__assetField {
        display: flex;
        width: 100%;
    }
    &__assetDynamicField {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }
}

@media screen and (min-width: v.$tablet) {
    .assetDynamicFields {
        &__assetField {
            width: calc(50% - 10px);
        }
    }
}
