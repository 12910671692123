@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;

.taskDetailDragFile {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    gap: 20px;
    &__header {
        display: flex;
        align-content: center;
        gap: 12px;
        width: 100%;
        padding-bottom: 8px;
        border-bottom: 1px solid c.$primary600;
    }
    &__title {
        @include t.text("sm", "medium");
        flex-shrink: 0;
        &--primary {
            @include t.text("sm", "semibold");
            color: c.$primary600;
        }
    }
    &__progressBar {
        display: flex;
        width: 100%;
        max-width: 275px;
    }
}
