@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.recoverPassword {
    &__title {
        @include t.display("xs", "semibold");
        color: c.$gray900;
        text-align: center;
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
        &__footer {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            &__backToLogin {
                @include t.text("sm");
                color: c.$primary700;
                text-align: center;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
