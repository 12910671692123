@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.baseTaskTemporalityHint {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &__title {
        color: c.$gray600;
        @include t.text("sm", "regular");
    }
    &__example {
        color: c.$gray400;
        @include t.text("xs", "regular");
    }
}
