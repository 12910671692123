@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;
@use "/src/styles/typographies.scss" as t;

.mobileAuditGroupCheckListInstance {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    padding: 12px;
    @include t.text("xs");
    &__name {
        width: 50%;
    }
    &__grade {
        display: flex;
        justify-content: flex-start;
    }
    &--odd {
        @extend .mobileAuditGroupCheckListInstance;
        background: c.$baseWhite;
    }
    &--even {
        @extend .mobileAuditGroupCheckListInstance;
        background: c.$gray25;
    }
    &--focused {
        @extend .mobileAuditGroupCheckListInstance;
        background-color: c.$primary50;
    }
}

@media screen and (min-width: v.$mobile) {
    .mobileAuditGroupCheckListInstance {
        flex-direction: row;
    }
}
