.taskTemporalityDateRangeMonthly {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;

    &__custom {
        display: flex;
        gap: 12px;
        width: 100%;
    }
}
