@use "/src/styles/colors.scss" as c;

.attachment {
    width: 80px;
    height: 80px;
    &__image {
        width: 100%;
        height: 100%;
        min-height: 40px;
        max-height: 200px;
        cursor: pointer;
        border: 1px solid c.$primary200;
        border-radius: 8px 0px 8px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
    }
}
