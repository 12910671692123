@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.collapsableTitle {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    &--noCollapsable {
        cursor: default;
    }
    &--bottom {
        padding-bottom: 8px;
        border-bottom: 1px solid;
        &--primary {
            border-bottom-color: c.$primary600;
        }
        &--appcc {
            border-bottom-color: c.$appcc600;
        }
    }
    &--none {
        border: none;
        padding-bottom: 0;
    }
    &__text {
        @include t.text("sm", "semibold");
        &--primary {
            color: c.$primary600;
        }
        &--appcc {
            color: c.$appcc600;
        }
    }
    &__icon {
        transition: rotate 200ms;
        &--close {
            @extend .collapsableTitle__icon;
            rotate: -180deg;
        }
        &--primary {
            color: c.$primary600;
        }
        &--appcc {
            color: c.$appcc600;
        }
    }
}
