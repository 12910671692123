@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.alertPillHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__assigned {
        display: flex;
        gap: 8px;
        align-items: center;
    }
    &__date {
        @include t.text("xs");
        color: c.$gray600;
    }
    &__badgeCheck {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
    }
}
