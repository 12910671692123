@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.baseTaskDataToReport {
    display: flex;
    width: 100%;
    &__checklist,
    &__selector {
        width: 100%;
    }

    &__selector {
        &--preview {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 12px;
            justify-content: space-between;
        }
        &__previewIcon {
            color: c.$gray500;
            font-size: 16px;
            cursor: pointer;
        }
    }
}
