@use "/src/styles/colors.scss" as c;

.inputHint {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: c.$gray600;
    &--inverted {
        @extend .inputHint;
        color: c.$baseWhite;
    }
}

.hintIcon {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: c.$gray500;
    cursor: pointer;
    &--inverted {
        @extend .hintIcon;
        color: c.$baseWhite;
    }
}
