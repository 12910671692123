@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.selectDepartmentWizard {
    max-width: 600px;
    width: 100%;
    overflow: hidden;
    min-height: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: c.$baseWhite;
    box-shadow: c.$shadow-lg;
    border-radius: 12px;

    &__formContainer {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
        gap: 32px;
        padding-top: 32px;
    }
    &__children {
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        height: 100%;
        width: 100%;
        gap: 20px;
        padding: 0 16px;
        &__title {
            color: c.$gray800;
            @include t.text("lg", "semibold");
            text-align: center;
            margin: 0;
        }
        &__subtitle {
            color: c.$gray500;
            @include t.text("md", "medium");
            text-align: center;
            margin: 0;
        }
        &__options {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            padding: 0 0 20px 0;
            overflow-y: scroll;
            height: 100%;

            &__companyListEmpty {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__cancel {
        width: 100%;
        max-width: 600px;
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        border-top: 1px solid c.$gray200;
        box-shadow: 0px -4px 13px 0px rgba(0, 0, 0, 0.1);
    }
}
@media screen and (min-width: v.$tablet) {
    .selectDepartmentWizard {
        min-height: 0;
        max-height: 600px;
        &__children {
            padding: 0 32px;
            &__options {
                padding-bottom: 0;
            }
        }
    }
}
