.editImageMenu {
    position: absolute;
    top: 12px;
    right: 12px;
    width: auto;
    z-index: 1;
    &__container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        height: 32px;
    }
}
