.test_col2 {
    width: auto;
}

.test_col3 {
    width: auto;
}

.test_col4 {
    width: 100px;
}

.test_col5 {
    width: auto;
    &__icons {
        display: flex;
        gap: 8px;
    }
}

.test_col6 {
    width: auto;
    &__actionButtons {
        display: flex;
        gap: 8px;
        justify-content: center;
        &__button {
            height: 24px;
            width: 24px;
            padding: 12px;
        }
    }
}
