@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.taskfield {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    flex-direction: column;
    &__title {
        color: c.$gray500;
        @include t.text("sm", "semibold");
    }
    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4px;
    }
    &__label {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        flex: 1 0 0;
        overflow: hidden;
        color: c.$gray700;
        text-overflow: ellipsis;
    }
    &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
    }
    &__input {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }
}
