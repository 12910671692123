.editImageFooter {
    width: 100%;
    padding: 12px;
    position: absolute;
    bottom: 0;
    &__draw {
        display: flex;
        justify-content: space-around;
        gap: 8px;
    }
}
