@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.inputTextWrapper {
    width: 100%;
    height: 40px;
    border: 1px solid c.$gray300;
    background-color: c.$baseWhite;
    border-radius: 6px;
    padding: 8px 12px;
    @include t.text("md");
    color: c.$gray900;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--md {
        height: 40px;
    }

    &--xs {
        height: 32px;
    }
    &__labelContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }

    &.transparent {
        background-color: transparent;
    }

    &--focus {
        @extend .inputTextWrapper;
        border: 2px solid c.$primary500;
        padding: 6px 11px;
        &.transparent {
            background-color: transparent;
        }
        &.errored {
            @extend .inputTextWrapper;
            border: 2px solid c.$error500;
            padding: 6px 11px;
            &.transparent {
                background-color: transparent;
            }
        }
        &.whiteInput {
            @extend .inputTextWrapper;
            border: 2px solid c.$baseWhite;
            padding: 6px 11px;
        }
    }
    &--disabled {
        @extend .inputTextWrapper;
        background: c.$gray50;
        border: 1px solid c.$gray300;
        color: c.$gray500;
        &.transparent {
            background-color: transparent;
        }
    }

    &::placeholder {
        color: c.$gray500;
    }

    &:focus {
        border: 2px solid c.$primary500;
    }

    &.errored {
        @extend .inputTextWrapper;
        border-color: c.$error500;
        &.transparent {
            background-color: transparent;
        }
    }

    &__iconwrapper {
        display: flex;
        width: 30px;

        &__icon {
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: c.$gray500;

            &--focus {
                @extend .inputTextWrapper__iconwrapper__icon;
                color: c.$gray600;
            }
            &--error {
                @extend .inputTextWrapper__iconwrapper__icon;
                color: c.$error500;
                &--focus {
                    @extend .inputTextWrapper__iconwrapper__icon;
                    color: c.$error500;
                }
            }
        }
        &__right {
            @extend .inputTextWrapper__iconwrapper;
            justify-content: flex-end;
            user-select: none;
            &.pointer {
                cursor: pointer;
            }
        }
    }

    &__wrapper {
        width: 100%;
        &.transparent {
            background-color: transparent;
        }
        &__input {
            border: none;
            width: 100%;
            outline: none;
            @include t.text("md");
            &::placeholder {
                font-size: 16px;
                font-style: italic;
            }
            &.transparent {
                background-color: transparent;
                border: none;
                color: c.$baseWhite;
            }
            &--white {
                &::placeholder {
                    color: c.$baseWhite;
                }
            }
        }
    }
}

.inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    transition: all 200ms;
    border: 1px solid transparent;
    &--disabled {
        @extend .inputContainer;
    }
}

.whiteInput {
    border: 1px solid white;
    background-color: c.$primary600;
    color: white;
    @include t.text("sm");
    &::placeholder {
        color: c.$baseWhite;
    }
    &--icon {
        color: white !important;
    }
}
