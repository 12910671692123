@use "/src/styles/variables.scss" as v;

.auditReport {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 0 12px 0;
    &__printButton {
        &--hide {
            display: none;
        }
    }
    &__selector {
        display: flex;
        align-items: center;
        width: 100%;
    }
}

@media screen and (min-width: v.$mobile) {
    .auditReport__selector {
        display: flex;
        align-self: flex-end;
        width: 200px;
    }
}

@media screen and (min-width: v.$laptop) {
    .auditReport {
        padding: 0 0 20px 0;
    }
}
