@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileRoleGridBody {
    display: flex;
    height: 56px;
    padding: 12px;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid c.$gray200;
    &--odd {
        @extend .mobileRoleGridBody;
        background: c.$baseWhite;
    }
    &--even {
        @extend .mobileRoleGridBody;
        background: c.$gray25;
    }
    &__container {
        display: flex;
        align-items: center;
        gap: 2px;
        width: 100%;
    }
    &__name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: c.$gray800;
        overflow: hidden;
        text-overflow: ellipsis;
        @include t.text("xs", "medium");
        width: 100%;
    }
    &__user {
        display: flex;
        align-items: center;
        gap: 8px;
        &__count {
            display: -webkit-box;
            width: 20px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: c.$gray700;
            text-overflow: ellipsis;
            @include t.text("xs", "regular");

            display: flex;
            justify-content: end;
            flex-direction: column;
            height: 20px;
        }
    }
    &__icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &__edit {
            color: c.$gray500;
            font-size: 16px;
            width: 16px;
        }
    }

    &__action {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &__edit {
            color: c.$gray600;
            text-align: center;
            font-size: 16px;
            width: 16px;
        }
    }
}
