@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.change__company {
    width: 100%;
    padding: 16px 16px 0 16px;
    &__title {
        color: c.$gray900;
        @include t.text("lg", "bold");
    }
}
