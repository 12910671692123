@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.taskComments {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    &__title {
        @include t.text("sm", "medium");
        color: c.$gray700;
    }
    &__chat {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;
    }
    &__chatbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        width: 100%;
    }
    &__container {
        background-color: c.$baseWhite;
        height: 100%;
        min-height: 250px;
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        max-height: 400px;
        &__placeholder {
            padding: 12px;
            color: c.$gray600;
            @include t.text("sm", "regular");
            user-select: none;
        }
        &--hide {
            @extend .taskComments__container;
            min-height: 0;
            height: 50px;
        }

        &__messages {
            overflow: scroll;
            scroll-behavior: auto;
            display: flex;
            flex-direction: column-reverse;
            flex: 1;
            height: 100%;
            width: 100%;
            outline: 1px solid c.$gray300;
            max-height: 300px;
            border-radius: 6px 6px 0 0;
            &--hide {
                display: none;
                height: 0;
                flex: 0;
            }
        }
    }
    &__placeholder {
        @include t.text("sm");
        padding: 12px;
        color: c.$gray500;
        min-height: 150px;
        height: 100%;
    }
    &__maxLength {
        width: 100%;
        text-align: right;
        color: c.$error600;
    }
    &__info {
        @include t.text("xs");
        width: 100%;
    }
}
.extraHeight {
    height: 80px;
}
