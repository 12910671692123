@use "/src/styles/variables.scss" as v;

.editForm {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__loader {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__container {
        display: flex;
        gap: 20px;
        flex-direction: column;
        &__inputs {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
            width: 100%;
        }
        &__switch {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            width: 100%;
        }
    }
    &__options {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 20px;
    }
}

@media screen and (min-width: v.$tablet) {
    .editForm {
        &__container {
            flex-direction: row;
            &__inputs {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        &__options {
            flex-direction: row;
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .editForm {
        &__container {
            &__inputs {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}
