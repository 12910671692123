@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

@mixin current($color) {
    border-bottom: 2px solid $color;
}

@mixin flex_center() {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.tab {
    width: fit-content;
    color: c.$gray500;
    @include flex_center();
    @include current(transparent);

    &--fullWidth {
        width: 100% !important;
    }

    &--xs {
        @extend .tab;
        @include t.text("xs");
    }
    &--md {
        @extend .tab;
        @include t.text("md");
        padding: 12px;
    }

    &--50 {
        height: 50px;
        padding: 12px;
    }

    &--40 {
        height: 40px;
        padding: 8px 12px 12px 12px;
    }

    &:hover {
        color: c.$gray600;
        background-color: c.$primary500transparent5;
        border-radius: 4px 4px 0px 0px;
    }

    &.tab--focus {
        @extend .tab;
        color: c.$gray600;
        background-color: c.$primary500transparent15;
        border-radius: 4px 4px 0px 0px;

        &--xs {
            @extend .tab--xs;
            color: c.$gray600;
            background-color: c.$primary500transparent15;
            border-radius: 4px 4px 0px 0px;
        }
        &--md {
            @extend .tab--md;
            color: c.$gray600;
            background-color: c.$primary500transparent15;
            border-radius: 4px 4px 0px 0px;
            padding: 12px;
        }
    }

    &--current {
        color: c.$primary600;
        @extend .tab;
        @include current(c.$primary600);
        &--xs {
            @extend .tab--current;
            @include t.text("xs", "semibold");
        }
        &--md {
            @extend .tab--current;
            @include t.text("md", "semibold");
            padding: 12px;
        }
        &:hover {
            @include current(c.$primary700);
            color: c.$primary700;
            background-color: c.$primary500transparent5;
        }
        &.tab--focus {
            @extend .tab--current;
            @include current(c.$primary700);
            color: c.$primary700;
            background-color: c.$primary500transparent15;
            &--xs {
                @extend .tab--current--xs;
                @include current(c.$primary700);
                color: c.$primary700;
                background-color: c.$primary500transparent15;
            }
            &--md {
                @extend .tab--current--md;
                @include current(c.$primary700);
                color: c.$primary700;
                background-color: c.$primary500transparent15;
            }
        }
    }

    &--dark {
        @extend .tab;
        color: c.$primary100;
        &--xs {
            @extend .tab--dark;
            @include t.text("xs");
            &:hover {
                @extend .tab--dark--xs;
                background-color: c.$baseWhiteTransparent5;
                border-radius: 4px 4px 0px 0px;
            }
        }
        &--md {
            @extend .tab--dark;
            @include t.text("md");
            padding: 12px;
            &:hover {
                @extend .tab--dark--md;
                background-color: c.$baseWhiteTransparent5;
                border-radius: 4px 4px 0px 0px;
            }
        }
        &:hover {
            @extend .tab;
            background-color: c.$baseWhiteTransparent5;
            border-radius: 4px 4px 0px 0px;
        }

        &.tab--focus {
            @extend .tab--dark;
            background-color: c.$baseWhiteTransparent15;
            border-radius: 4px 4px 0px 0px;
            &--xs {
                @extend .tab--dark--xs;
                background-color: c.$baseWhiteTransparent15;
                border-radius: 4px 4px 0px 0px;
            }
            &--md {
                @extend .tab--dark--md;
                background-color: c.$baseWhiteTransparent15;
                border-radius: 4px 4px 0px 0px;
            }
        }

        &--current {
            @extend .tab--dark;
            @include current(c.$baseWhite);
            color: c.$baseWhite;
            &--xs {
                @extend .tab--dark--current;
                @include t.text("xs", "semibold");
                &:hover {
                    @extend .tab--dark--current--xs;
                }
            }
            &--md {
                @extend .tab--dark--current;
                @include t.text("md", "semibold");
                padding: 12px;
                &:hover {
                    @extend .tab--dark--current--md;
                }
            }
            &:hover {
                @extend .tab--dark--current;
                background-color: c.$baseWhiteTransparent5;
            }
            &.tab--focus {
                @extend .tab--dark--current;
                background-color: c.$baseWhiteTransparent15;
                &--xs {
                    @extend .tab--dark--current--xs;
                    background-color: c.$baseWhiteTransparent15;
                    &:hover {
                        @extend .tab--focus--xs;
                    }
                }
                &--md {
                    @extend .tab--dark--current--md;
                    background-color: c.$baseWhiteTransparent15;
                    &:hover {
                        @extend .tab--focus--md;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: v.$mobile-767) {
    .tab {
        &:hover {
            background-color: transparent;
        }

        &.tab--focus {
            background-color: transparent;
        }

        &--current {
            &:hover {
                background-color: transparent;
            }
            &.tab--focus {
                background-color: transparent;
            }
        }

        &--dark {
            &:hover {
                background-color: transparent;
            }

            &.tab--focus {
                background-color: transparent;
            }

            &--current {
                &:hover {
                    background-color: transparent;
                }
                &.tab--focus {
                    background-color: transparent;
                }
            }
        }
    }
}
