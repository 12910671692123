@use "/src/styles/variables.scss" as v;

.reviewHeader {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    &__filterIcon {
        position: relative;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__carrousel {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        height: 105px;
        overflow-x: auto;
        user-select: none;
        padding-bottom: 5px;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            display: none;
        }

        &--empty {
            @extend .reviewHeader__carrousel;
            height: 0px;
            padding: 0;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .reviewHeader__carrousel {
        &::-webkit-scrollbar {
            display: block;
        }
    }
}

@media screen and (min-width: v.$mobile) {
    .reviewHeader {
        &__header > div {
            max-width: 260px;
        }
    }
}
