@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.rankingPillListSkeleton {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;
    background-color: c.$baseWhite;
}

@media screen and (min-width: v.$mobile) {
    .rankingPillListSkeleton {
        gap: 12px;
    }
}

@media screen and (min-width: v.$laptop) {
    .rankingPillListSkeleton {
        padding: 0;
    }
}
