@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.onBoardingStepsTitle {
    display: flex;
    gap: 4px;
    align-items: flex-start;
    &__text {
        color: c.$gray900;
        @include t.text("md", "semibold");
        line-height: 28px;
        &--blocked {
            color: c.$gray500;
        }
    }
    &__icon {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__svg {
            width: 15px;
            height: 15px;
            color: c.$gray300;
        }
    }
}
