@use "src/styles/colors.scss" as c;

.iconSelect {
    border: none;
    width: 305px;
    outline: none;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 36px;
    background-color: c.$baseWhite;
    border-radius: 6px;
    position: absolute;
    left: 8px;
    padding: 0 14px;
    margin-top: 10px;

    &__select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    &__options {
        position: absolute;
        left: 0px;
        padding: 0 14px;
        width: 100%;
        background-color: white;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        bottom: 30px;
        border: 1px solid c.$gray200;
        border-radius: 6px;
        padding: 4px 0;

        background-color: c.$gray200;
        &__item {
            height: 36px;
            border-radius: 6px;
            width: 95%;
            display: flex;
            align-items: center;
            padding-left: 12px;
            background-color: white;

            &:hover {
                background-color: c.$gray100;
            }
        }
    }
}

.grayBg200 {
    background-color: c.$gray200;
}
