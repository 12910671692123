@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.mobileNotificationDistributionListRow {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    padding: 8px;
    border-bottom: 1px solid c.$gray200;
    &__header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }
    &__title {
        width: 100%;
        @include t.text("sm", "medium");
    }
    &__small {
        width: 100%;
        @include t.text("sm");
    }
    &__counter {
        width: 40px;
    }
}
