@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.circularProgressBar {
    &__container {
        width: 100%;
        height: 100%;
    }
    &__text {
        @include t.text("lg", "semibold");
    }
    &--lightRed {
        stroke: c.$error300;
    }
    &--red {
        stroke: c.$error500;
    }
    &--lightYellow {
        stroke: c.$yellow200;
    }
    &--yellow {
        stroke: c.$yellow400;
    }
    &--lightGreen {
        stroke: c.$success200;
    }
    &--green {
        stroke: c.$success300;
    }
    &--lightBlue {
        stroke: c.$primary300;
    }
    &--blue {
        stroke: c.$primary600;
    }
    &--lightBlue {
        stroke: c.$primary300;
    }
    &__legend {
        @include t.text("sm", "medium");
        color: c.$gray700;
        text-align: center;
        margin-top: 12px;
        &__number {
            @extend .circularProgressBar__legend;
            margin-top: 0;
        }
    }
    &--all {
        width: 100%;
    }
}
.container_all__circularProgressBar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
