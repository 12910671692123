@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.planMobileRowFooter {
    display: flex;
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        flex: 1 0 0;
        &__text {
            color: c.$gray700;
            @include t.text("xs", "regular");
        }
    }
}
