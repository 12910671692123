@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;
@use "/src/styles/mixin/skelletonAnimation" as m;

.auditIssuePillSkelleton {
    background-color: c.$baseWhite;
    border: 1px solid c.$gray200;
    box-shadow: v.$shadow-light;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;
    &__header {
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__avatar {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        &__img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            @include m.skeleton-is-loading(c.$gray200);
        }
        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            &__name,
            &__date {
                height: 18px;
                width: 70px;
                &-fs {
                    height: 12px;
                    width: 100%;
                    border-radius: 8px;
                    @include m.skeleton-is-loading(c.$gray200);
                }
            }
        }
    }
    &__status {
        width: 62px;
        height: 22px;
        @include m.skeleton-is-loading(c.$gray200);
        border-radius: 12px;
    }
    &__body {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
    }

    &__description {
        &.w-70 {
            max-width: 70%;
        }
        &.w-50 {
            max-width: 50%;
        }
        &-fs {
            @include m.skeleton-is-loading(c.$gray200);
            height: 12px;
            border-radius: 12px;
            width: 100%;
        }
    }
}
