@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.rankingPillInfo {
    &__avatar {
        display: flex;
        flex-direction: column;
        gap: 4px;
        overflow: hidden;
        &__userType {
            @include t.text("xs", "regular");
            color: c.$gray500;
            word-break: break-word;
        }
    }
}
