@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.optionCompany {
    display: flex;
    padding: 12px 16px 12px 12px;
    gap: 12px;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    border-radius: 6px;
    outline: 1px solid c.$gray300;
    cursor: pointer;
    user-select: none;
    outline-offset: -2px;
    &--active {
        @extend .optionCompany;
        outline: 2px solid c.$primary500;
        background: c.$primary100;
    }
    &--disabled {
        @extend .optionCompany;
    }
    &--noHover {
        outline: 1px solid c.$gray300;
        cursor: default;
        border-radius: 6px;
        padding: 12px 16px 12px 12px;
        width: 100%;
    }
    &__imageContainer {
        display: flex;
        padding: 4.462px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 5px;
        outline: 1px solid c.$gray200;
        background-color: c.$baseWhite;
        flex-shrink: 0;
        &--xl {
            @extend .optionCompany__imageContainer;
            width: 58px;
            height: 58px;
        }
        &--md {
            @extend .optionCompany__imageContainer;
            width: 43px;
            height: 43px;
        }

        &--xs {
            @extend .optionCompany__imageContainer;
            width: 32px;
            height: 32px;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &__defaultCompany {
        color: c.$gray600;
        &--xl {
            @extend .optionCompany__defaultCompany;
            height: 30px;
        }
        &--md {
            @extend .optionCompany__defaultCompany;
            height: 24px;
        }
    }
    &__text {
        color: c.$gray800;
        @include t.text("md", "regular");
    }
    &__container {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    &__header {
        display: flex;
        width: 100%;
        flex-direction: column;
        min-height: 58px;
        justify-content: space-between;
        &--center {
            @extend .optionCompany__header;
            justify-content: center;
        }
        &--spaceBetween {
            @extend .optionCompany__header;
            justify-content: space-between;
        }
        &__info {
            display: flex;
            gap: 8px;
        }
    }
    &__counter {
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: c.$primary500;
        border: 1px solid c.$baseWhite;
        color: c.$baseWhite;
        @include t.text("xs", "medium");
    }
}

@media screen and (min-width: v.$tablet) {
    .optionCompany {
        transition: background-color 200ms;
        outline-offset: -2px;
        &:hover {
            background-color: c.$primary200;
            outline: 2px solid c.$primary500;
        }
        &--disabled {
            transition: background-color 200ms;
            outline-offset: -2px;
            &:hover {
                background-color: c.$gray100;
                outline: 2px solid c.$gray300;
            }
        }
    }
}
