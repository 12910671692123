@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.usersLoginItem {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    border: 1px solid c.$gray300;
    cursor: pointer;
    &__avatar {
        display: flex;
        align-items: center;
        gap: 8px;
        &__title {
            @include t.text("sm", "semibold");
            color: c.$primary600;
        }
    }
    &__icon {
        display: flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
    }
    &__svg {
        font-size: 12px;
        height: 12px;
        width: 12px;
        color: c.$primary500;
    }
}
