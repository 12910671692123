@use "/src/styles/variables.scss" as v;

.skeleton-is-loading {
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: "";
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

.starAndValoration-skelleton {
    width: 220px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.star-is-loading {
    display: inline-block;
    height: 24px;
    width: 130px;
    border-radius: 10px;
    @extend .skeleton-is-loading;
}

.valoration-is-loading {
    display: inline-block;
    height: 24px;
    width: 220px;
    border-radius: 10px;
    @extend .skeleton-is-loading;
}

@media screen and (min-width: v.$tablet) {
    .starAndValoration-skelleton {
        flex-direction: row;
        width: 100%;
        height: 32px;
        justify-content: flex-end;
    }
}
