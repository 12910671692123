@use "/src/styles/colors.scss" as c;

.companyGrid {
    &__cif {
        width: 120px;
    }
    &__actions {
        width: 60px;
        justify-content: center;
    }
}

.editcompany__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    &__buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 12px;
        border-top: 1px solid c.$gray300;
        padding-top: 12px;
    }
}
