@use "/src/styles/variables.scss" as v;

.addDepartmentModal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.departmentsContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &__noResults {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20vh;
    }
}

.departmentsCollapse {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: scroll;
}

.companiesDepartment {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    overflow: auto;
    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.subdepartmentGrid {
    &__actions {
        width: 90px;
    }
}
