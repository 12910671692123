@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.auditOrderFilterContainer {
    display: flex;
    gap: 4px;
}

.auditGrid {
    &__tabContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include t.text("xl", "semibold");
        color: c.$baseWhite;
        flex-direction: column;
        gap: 12px;
        &__title {
            color: c.$baseWhite;
            @include t.text("xl", "semibold");
        }
        &__button {
            width: 200px;
        }
    }
    &__order {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        height: 100%;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100%;
    }
}

@media screen and (min-width: v.$mobile) {
    .auditGrid {
        &__header {
            flex-direction: row;
        }
    }
}
