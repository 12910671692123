@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.spinnerContainer {
    width: 100%;
    height: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--moreHeight {
        @extend .spinnerContainer;
        height: 270px;
    }
}
.analistDetailBody {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr;
    padding-bottom: 12px;
    &__column {
        display: flex;
        gap: 12px;
        flex-direction: column;
        width: calc(50% - 10px);
    }
    &__box {
        background-color: white;
        padding: 12px;
        border-radius: 8px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100%;
        &--heightFitContent {
            @extend .analistDetailBody__box;
            height: fit-content;
        }
        &__title {
            @include t.text("md", "semibold");
        }
        &__header {
            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
        }
    }
    &__stars {
        @extend .analistDetailBody__column;
        width: 100%;
    }
}

@media screen and (min-width: v.$laptop) {
    .analistDetailBody {
        gap: 20px;
        padding-bottom: 20px;
        grid-template-columns: repeat(2, 1fr);
        &__column {
            gap: 20px;
        }
        &__box {
            padding: 20px;
            gap: 20px;
            &--fullwidth {
                grid-row: 2 / 3;
                grid-column: 1 / 3;
            }
        }
    }
}
