.taskTemporalityRangeHour {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
    &__hours {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 219px;
    }
    &__multiHours {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
