@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.baseTaskModal {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    &__description {
        color: c.$gray600;
        @include t.text("md", "regular");
    }
    &__footer {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
