@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;

.confirmModal {
    background-color: c.$baseWhite;
    padding: 32px;
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 10px;
    max-height: 70%;
    overflow: scroll;
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &__title {
            color: c.$gray900;
            text-align: center;
            @include t.text("lg", "semibold");
            text-wrap: balance;
        }
        &__description {
            color: c.$gray500;
            text-align: center;
            @include t.text("sm");
            text-wrap: pretty;
        }
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        &--fixed {
            @extend .confirmModal__footer;
            border-top: 1px solid red;
            padding-top: 12px;
        }
    }
}

@media screen and (max-width: 600px) {
    .confirmModal {
        width: 90%;
        padding: 20px 12px 12px 12px;
        &__footer {
            flex-direction: column-reverse;
        }
    }
}
