@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.roundedFilterButtonGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 59px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    height: 56px;
    max-width: 400px;
    &__container {
        display: flex;
        padding: 4px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
        height: 100%;
        gap: 4px;
    }
}
