@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.auditChecklistModal {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 12px;
    &__title {
        @include t.text("lg", "semibold");
        color: c.$gray800;
        padding: 12px 0px;
        text-align: center;
    }
    &__spinner {
        display: flex;
        justify-content: center;
        height: 100%;
    }
    &__form {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        overflow-y: scroll;
    }
}

@media screen and (min-width: v.$mobile) {
    .auditChecklistModal {
        padding: 0 32px;
        &__title {
            padding: 20px 0px;
        }
    }
}
