@use "src/styles/colors.scss" as c;

.tabs {
    display: flex;
    overflow: auto;
    min-height: 40px;
    &--full {
        width: 100%;
    }
    &--auto {
        width: auto;
    }

    &__container {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0;
        flex-shrink: 0;
    }

    &__content {
        width: 100%;
    }
}
