@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.radioLabels {
    display: flex;
    flex-direction: column;
    &--minWidth {
        @extend .radioLabels;
        min-width: 100px;
    }
    &__label {
        color: c.$gray700;
        &--sm {
            @include t.text("sm", "medium");
        }
        &--md {
            @include t.text("md", "medium");
        }
        &--xl {
            @include t.text("xl", "medium");
        }
        &.inverted {
            @extend .radioLabels__label;
            color: c.$baseWhite;
        }
    }

    &__sublabel {
        line-height: 24px;
        font-weight: 400;
        font-size: 16px;
        color: c.$gray600;
        &.inverted {
            @extend .radioLabels__sublabel;
            color: c.$baseWhite;
        }
    }
}
