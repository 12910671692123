@use "/src/styles/colors.scss" as c;

.line-chart-container {
    text-align: center;
}

.lineChart {
    border: 1px solid c.$gray200;
    display: block;
    margin: 20px auto;
    &__legend {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        &-item {
            display: flex;
            align-items: center;
            margin: 0 10px;
            cursor: pointer;
            transition: color 0.3s;
        }
        &-color {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 5px;
            background-color: currentColor;
        }
    }
}
