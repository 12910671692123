@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileTaskInstanceRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 12px;
    width: 100%;
    border-bottom: 1px solid c.$gray200;
    cursor: pointer;
    &--odd {
        @extend .mobileTaskInstanceRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .mobileTaskInstanceRow;
        background: c.$gray25;
    }
    &__title {
        flex: 1 0 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        color: c.$gray800;
        @include t.text("xs", "medium");
    }
    &__body {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        height: 100%;
        &__col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            width: 100%;
            height: 100%;
            &__text {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                flex: 1 0 0;
                overflow: hidden;
                color: c.$primary600;
                text-overflow: ellipsis;
                @include t.text("xs", "semibold");
                margin-left: 10px;
            }
        }
    }
}
