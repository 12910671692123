@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.alertPill {
    background-color: c.$baseWhite;
    box-shadow: c.$shadow-md;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 325px;
    width: 100%;
    cursor: pointer;
    &__footer {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        &__qr {
            margin-left: auto;
            gap: 16px;
            font-size: 16px;
            color: c.$gray300;
        }
    }
}
