@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.formLayout__children > * {
    padding-bottom: 12px;
}

.formLayoutScroll {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__children {
        background-color: c.$baseWhite;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        &--white {
            background-color: c.$baseWhite;
        }
        &--gray {
            background-color: c.$gray100;
        }
    }
}

.formLayout {
    width: 100%;
    height: 100%;
    padding: 12px 12px 0px 12px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    &__spinner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &--white {
        background-color: c.$baseWhite;
    }
    &--gray {
        background-color: c.$gray100;
    }
    &__children {
        display: flex;
        flex-direction: column;
        height: 100%;
        &--white {
            background-color: c.$baseWhite;
        }
        &--gray {
            background-color: c.$gray100;
        }
        &--noHeight {
            @extend .formLayout__children;
            height: auto;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .formLayout {
        padding: 20px 20px 0px 20px;
        &__children > * {
            padding-bottom: 20px;
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .formLayout {
        max-width: v.$layoutMaxWidth;

        &--fullWidth {
            max-width: 100%;
        }
    }
}
