.auditGroupCheckListItem {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    &__ponderation {
        min-width: 80px;
    }
}
