@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.cellWithAvatar {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    &__taskInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        &__name {
            color: c.$primary600;
            @include t.text("xs", "semibold");
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            flex: 1 0 0;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
        &--noBadge {
            @include t.text("xs");
            color: c.$gray500;
        }
    }
}
