.correctiveMeasure {
    &__id {
        width: 80px;
        min-width: 80px;
    }
    &__description,
    &__plan {
        width: 45%;
        min-width: 330px;
    }
    &__actions {
        min-width: 120px;
        width: 120px;
    }
}
