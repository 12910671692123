@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;

.selectCustomChildren {
    width: 100%;
    border-radius: 8px;
    @include t.text("sm", "medium");

    &--inverted {
        @extend .selectCustomChildren;
        border: 1px solid c.$baseWhite;
    }
}
