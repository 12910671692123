.auditGroupCheckListModal {
    display: flex;
    flex-direction: column;
    gap: 32px;
    &__items {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__label {
            display: flex;
            align-items: flex-end;
        }
    }
}
