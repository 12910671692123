@use "/src/styles/colors.scss" as c;

.markableIcon {
    position: relative;
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.allowHover {
        border-radius: 50%;
    }
    &--primary {
        @extend .markableIcon;
        &.allowHover {
            &:hover {
                background-color: c.$basewhiteTransparent;
            }
        }
        &--active {
            background-color: c.$primary400;
            border-radius: 100%;
        }
    }
    &--appcc {
        @extend .markableIcon;
        &.allowHover {
            &:hover {
                background-color: c.$basewhiteTransparent;
            }
        }
        &--active {
            background-color: c.$appcc100;
            border-radius: 100%;
        }
    }
    &--warning {
        @extend .markableIcon;
        background-color: c.$warning500;
        transition: all 200ms;
        &.allowHover {
            &:hover {
                background-color: c.$basewhiteTransparent;
            }
        }
        &--active {
            background-color: c.$warning500;
            &:hover {
                background-color: c.$warning400;
            }
        }
    }

    &__icon {
        position: relative;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &--primary {
            @extend .markableIcon__icon;
            color: c.$primary25;
            &--active {
                @extend .markableIcon__icon--primary;
            }
        }
        &--appcc {
            @extend .markableIcon__icon;
            color: c.$gray800;
            &--active {
                @extend .markableIcon__icon--appcc;
                color: c.$appcc600;
            }
        }
        &--warning {
            @extend .markableIcon__icon;
            color: c.$primary25;
            &--active {
                color: c.$warning25;
            }
        }

        &--sm {
            width: 16px;
            height: 16px;
        }

        &--xl {
            border: 1px solid c.$baseWhite;
            padding: 8px;
            border-radius: 100%;
        }
    }

    &__notification {
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background-color: c.$error500;
        position: absolute;
        top: 5px;
        right: 5px;
    }
    &--sm {
        height: 30px;
        width: 30px;
    }
}
