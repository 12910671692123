@use "/src/styles/colors.scss" as c;

.roleCollapseCheckbox {
    display: grid;
    grid-template-rows: 1fr;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: grid-template-rows 0.2s;
    &__collapse {
        display: flex;
        overflow: auto;
    }
}
