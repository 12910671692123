@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.auditReportBarChart {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 200px;
    &__title {
        @include t.text("lg", "medium");
        color: c.$appcc500;
    }
}
