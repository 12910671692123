@use "/src/styles/variables.scss" as v;

.notificationsQrModal {
    display: flex;
    gap: 32px;
    width: 100%;
    flex-direction: column;
    &__company {
        width: 100%;
    }
    &__container {
        display: flex;
        gap: 32px;
        width: 100%;
        flex-direction: column;
    }
}

@media screen and (min-width: v.$tablet) {
    .notificationsQrModal {
        &__company {
            width: calc(50% - 16px);
        }
        &__container {
            flex-direction: row;
        }
    }
}
