@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.auditReportGrid {
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__header {
        height: max-content;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
        @include t.text("sm", "semibold");
        color: c.$primary500;
    }
    &__grade {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
        color: c.$baseWhite;
        @include t.text("sm");
        &__badge {
            width: 48px;
            display: flex;
            justify-content: flex-end;
            &--bigWidth {
                @extend .auditReportGrid__grade__badge;
                width: 100%;
            }
        }
    }
    &__column {
        width: 200px;
    }
}

.starsSkelleton {
    width: 154px;
    display: flex;
    align-items: center;
    gap: 12px;
}

@media screen and (min-width: v.$mobile) {
    .auditReportGrid__header {
        flex-direction: row;
    }
}

@media screen and (min-width: v.$tablet) {
    .auditReportGrid {
        gap: 20px;
        &__header {
            gap: 20px;
        }
    }
}
