@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileUserRow {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid c.$gray200;
    &--odd {
        @extend .mobileUserRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .mobileUserRow;
        background: c.$gray25;
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        &__text {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
        }
    }
    &__name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        @include t.text("xs", "medium");
        color: c.$gray800;
    }
    &__userCode {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        @include t.text("xs", "regular");
        color: c.$gray700;
    }
    &__edit {
        width: 20px;
        height: 20px;
        cursor: pointer;
        &__icon {
            width: 16px;
            color: c.$gray600;
            text-align: center;
            font-size: 16px;
        }
    }
}
