@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;
@use "/src/styles/variables.scss" as v;

.taskPillWithStar-skelleton {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 8px;
    background: c.$baseWhite;
    box-shadow: c.$shadow-md;
    &__img {
        height: 202px;
        border-radius: 4px 4px 0px 0px;
        width: 100%;
        @include m.skeleton-is-loading(c.$gray200);
    }
    &__container {
        display: flex;
        padding: 12px;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        &__taskInfo {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
        }
    }
}
