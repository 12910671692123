@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.avatarQr {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    right: -4px;
    bottom: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 1px solid c.$baseWhite;
    background-color: c.$primary500;
    color: c.$baseWhite;
    @include t.text("xs", "medium");
}
