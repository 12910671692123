@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.issueHistory {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        width: 100%;
    }
}
