@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.auditBoxHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12px;
    width: 100%;
    flex-wrap: wrap;
    &__title {
        @include t.text("md", "bold");
        color: c.$appcc500;
        flex-shrink: 0;
    }
    &__buttons {
        display: flex;
        gap: 12px;
    }
}
