@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.edit_task_column {
    &__cancelReasign {
        @include t.text("sm");
        cursor: pointer;
        text-decoration: underline;
        color: c.$gray700;
    }
}

.taskBelongs {
    grid-column: 1 / 2;
    display: flex;
    width: 100%;
}

@media screen and (min-width: v.$tablet) {
    .taskBelongs {
        grid-column: 2 / 2;
    }
}
