@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.issueHistoryPill {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
    border: 1px solid c.$gray200;
    border-radius: 8px;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
    }
    &__comment {
        color: c.$gray900;
        @include t.text("md", "regular");
    }
    &__correctiveMeasures {
        display: flex;
        flex-direction: column;
        padding-left: 16px;
    }
    &__correctiveMeasure {
        color: c.$gray600;
        @include t.text("xs", "medium");
        text-wrap: pretty;
    }
}
