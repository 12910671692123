.taskStart {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    &__label {
        display: flex;
        gap: 6px;
        width: 100%;
    }
}
