@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.addNewButton {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 12px;
    flex-wrap: wrap;
    border: 0;
    background: none;
    width: fit-content;
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        cursor: pointer;
    }
    &__icon {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        &__svg {
            width: 16px;
            height: 16px;
            &--primary {
                color: c.$primary700;
            }
            &--appcc {
                color: c.$appcc700;
            }
            &--white {
                color: c.$baseWhite;
            }
        }
    }
    &__text {
        @include t.text("sm", "semibold");
        border-bottom: 1px solid transparent;
        transition: border-bottom-color 200ms;
        &--primary {
            color: c.$primary700;
            &:hover {
                border-bottom-color: c.$primary700;
            }
        }
        &--appcc {
            color: c.$appcc700;
            &:hover {
                border-bottom-color: c.$appcc700;
            }
        }
        &--white {
            color: c.$baseWhite;
            &:hover {
                border-bottom-color: c.$baseWhite;
            }
        }
    }
}
