@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.avatarImage {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid c.$baseWhite;

    &--xs {
        width: 24px;
        min-width: 24px;
        height: 24px;
    }
    &--sm {
        width: 32px;
        min-width: 32px;
        height: 32px;
    }
    &--md {
        width: 48px;
        min-width: 48px;
        height: 48px;
    }
    &--lg {
        width: 48px;
        min-width: 48px;
        height: 48px;
    }
    &--xl {
        width: 56px;
        min-width: 56px;
        height: 56px;
    }
    &--2xl {
        width: 64px;
        min-width: 64px;
        height: 64px;
    }

    &--border {
        border: 1px solid c.$baseWhite;
    }

    &--contain {
        object-fit: contain;
    }

    &--white {
        background-color: c.$baseWhite;
    }
}
