@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.rolesWizard {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    gap: 20px;
    padding-top: 32px;
    .stepsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        overflow-y: auto;
        height: 100%;
    }
    .wizardFooter {
        padding: 12px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;
        &--between {
            @extend .wizardFooter;
            justify-content: space-between;
        }
    }
}

.roleWizardStep2 {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    height: 100%;
    &__spinner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.roleWizardStep4 {
    text-align: center;
    color: c.$gray700;
    @include t.text("sm", "medium");
    width: 100%;
    &__roleName {
        color: c.$primary500;
        @include t.text("sm", "medium");
    }
    &__permissions {
        @extend .roleWizardStep4;
        display: flex;
        flex-direction: column;
        gap: 15px;
        overflow-y: auto;
    }
}
.rolesWizardStep1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
