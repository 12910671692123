@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;

.floatingButton {
    position: fixed;
    right: 12px;
    bottom: 60px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    box-shadow: c.$shadow-md;
    padding: 0 !important;
    z-index: 1;
    &--ios {
        bottom: 80px;
    }
}

@media screen and (min-width: v.$tablet) {
    .floatingButton {
        bottom: 20px;
        &--ios {
            bottom: 20px;
        }
    }
}
