.userGrid {
    &__firstName {
        width: 25%;
    }
    &__role,
    &__department {
        width: 20%;
    }
    &__userCode {
        min-width: 140px;
    }
    &__actions {
        min-width: 60px;
        text-align: center;
    }
}
