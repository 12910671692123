.dragFileWithLabel {
    display: flex;
    gap: 6px;
    width: 100%;
    flex-direction: column;
    &__preview {
        display: flex;
        gap: 20px;
        width: 100%;
        flex-direction: column;
    }
}
