.skeleton-is-loading {
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: "";
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

@mixin systemNotificationText($width: 85%) {
    width: $width;
    height: 12px;
    display: inline-block;
    border-radius: 10px;
    @extend .skeleton-is-loading;
}

.sysNotification-skelleton {
    height: 113px;
    display: flex;
    gap: 12px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    width: 100%;
    padding: 16px 12px;
    &__icon {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        @extend .skeleton-is-loading;
    }
    &__body {
        display: flex;
        padding: 0px 16px 0px 0px;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0;
        gap: 6px;
        &__title {
            width: 100%;
            height: 16px;
            display: inline-block;
            border-radius: 10px;
            @extend .skeleton-is-loading;
        }
        &__text {
            display: flex;
            flex-direction: column;
            gap: 6px;
            width: 100%;
            &--85 {
                @include systemNotificationText();
            }
            &--50 {
                @include systemNotificationText(50%);
            }
        }
    }
}
