@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;

.taskInstanceGrid {
    &__col {
        min-width: 260px;
        max-width: 30%;
    }
    &__actions {
        min-width: 80px;
        width: 80px;
    }

    &__tabsWithCounter {
        display: flex;
        width: 100%;
        gap: 12px;
        align-items: center;

        &__counter {
            flex-shrink: 0;
            color: c.$baseWhite;
            @include t.text("xs", "medium");
        }
    }
}
