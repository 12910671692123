@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.errorPage {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 96px 16px;
    &__container {
        width: 100%;
        max-width: 720px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 48px;
    }
    &__heading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 100%;
        &__subHeading {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            &__errorCode {
                color: c.$primary700;
                @include t.text("md", "semibold");
            }
            &__title {
                color: c.$gray900;
                @include t.display("md", "semibold");
                margin: 0;
            }
        }
        &__description {
            color: c.$gray600;
            @include t.text("md", "regular");
        }
    }
    &__actions {
        width: 100%;
    }
}
