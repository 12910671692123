@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.patchNotesModal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__spinner {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__add {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        color: c.$primary700;
        &:hover {
            text-decoration: underline;
        }
        &__icon {
            font-size: 16px;
        }
        &__text {
            @include t.text("sm", "semibold");
        }
    }
}
