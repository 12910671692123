@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.tableTab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
    user-select: none;
    &--primary {
        background-color: c.$primary600;
    }
    &--appcc {
        background-color: c.$baseWhite;
    }

    &__header {
        display: flex;
        gap: 12px;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        &--primary {
            color: c.$baseWhite;
        }
        &--appcc {
            color: c.$gray800;
        }
        width: 100%;
        min-height: 50px;
        flex-direction: column;

        &--primary.pointer {
            &:hover {
                background-color: c.$primary700;
            }
        }

        &--appcc.pointer {
            &:hover {
                background-color: c.$gray50;
            }
        }

        &--row {
            @extend .tableTab__header;
            flex-direction: row;
        }
        &__titles {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            &--row {
                @extend .tableTab__header__titles;
                width: auto;
            }
            &__title {
                margin: 0px;
                flex-shrink: 0;
                @include t.text("sm", "semibold");
                &--primary {
                    color: c.$baseWhite;
                }
                &--appcc {
                    color: c.$gray800;
                }
            }
            &__subtitle {
                flex-shrink: 0;
                flex-shrink: 0;
                @include t.text("xs");
            }
            &__container {
                display: flex;
                gap: 16px;
            }
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .tableTab {
        &__header {
            flex-direction: row;
            &__titles {
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 16px;
                &__title {
                    @include t.text("sm", "semibold");
                }

                &__subtitle {
                    @include t.text("xs");
                }
            }
        }
    }
}
