@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileTaskRowHeader {
    display: flex;
    gap: 20px;
    align-items: center;
    &__userInfo {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        &__userName {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            flex: 1 0 0;
            text-overflow: ellipsis;
            overflow: hidden;
            color: c.$primary600;
            @include t.text("xs", "semibold");
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}
