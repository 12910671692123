.buttonIcon {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    &__svg {
        text-align: center;
        font-size: 16px;
    }
}
