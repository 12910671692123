@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.loginContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: c.$primary600;
    background-image: url("../../../../../public/bgPhone.png");
    background-repeat: no-repeat;
    padding: 32px 12px;
    gap: 32px;
    background-position: top center;
    background-size: cover;
    &__logo {
        display: block;
        max-width: 100%;
        height: 80px;
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        overflow: hidden;
        height: 100%;
        justify-content: center;
    }
}

@media screen and (min-width: v.$tablet) {
    .loginContainer {
        background-position: center left;
    }
}
