@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.lineChart {
    &__title {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
    }

    display: flex;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 0;
    padding-top: 0;

    &__icon {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        color: c.$primary500;
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 250px;
        max-height: 250px;
        width: 100%;
        max-width: 100%;
        margin-top: 0;
        padding: 0;

        &__label {
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            text-align: center;
            @include t.text("sm", "medium");
        }
        &__dates {
            width: 100%;
            text-align: center;
            @include t.text("sm", "medium");
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .lineChart__custom {
        height: 350px !important;
        max-height: 300px !important;
        width: 100% !important;
    }
}
