@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.rankingPillCounters {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__counters {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    &__total {
        display: none;
    }
}

@media screen and (min-width: v.$tablet) {
    .rankingPillCounters {
        flex-direction: row;
        gap: 4px;
        border-radius: 11px;
        &--border {
            padding: 4px;
            border: 1px solid c.$gray200;
            padding: 4px;
        }
        &__counters {
            flex-direction: row;
        }
        &__total {
            display: flex;
            width: 60px;
            align-items: center;
            justify-content: flex-start;
            gap: 2px;
            color: c.$gray600;
            &__slash {
                @include t.text("xs", "medium");
            }
            &__number {
                @include t.text("lg", "semibold");
            }
        }
    }
}
