@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.sqaureInput {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    outline: 1px solid c.$primary300;
    border: none;
    text-align: center;
    background-color: transparent;
    color: c.$baseWhite;
    caret-color: transparent;
    &--sm {
        @include t.text("xl", "medium");
        width: 38px;
        height: 38px;
        padding: 6px 10px;
    }
    &--md {
        @include t.display("xs", "medium");
        width: 48px;
        height: 48px;
    }
    &--lg {
        @include t.display("sm", "medium");
        width: 52px;
        height: 52px;
    }
    &:focus {
        outline: 2px solid c.$baseWhite;
    }
    &::placeholder {
        color: c.$primary700;
    }
}
