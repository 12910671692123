$main-color-transparent: #3970964d;
$second-color: #1c5372;

$baseWhite: white;
$baseWhiteTransparent5: #ffffff0d;
$baseWhiteTransparent15: #ffffff26;
$baseBlack: black;

$gray25: #fcfcfc;
$gray50: #fafafa;
$gray100: #f5f5f5;
$gray200: #e5e5e5;
$gray300: #d6d6d6;
$gray400: #a3a3a3;
$gray500: #737373;
$gray600: #525252;
$gray700: #424242;
$gray800: #292929;
$gray900: #141414;

$primary25: #f5fbff;
$primary50: #eaf6fe;
$primary100: #d4e9f6;
$primary200: #b6d8ee;
$primary300: #7eafcf;
$primary400: #558baf;
$primary500: #397096;
$primary600: #27648d;
$primary700: #15547e;
$primary800: #104162;
$primary900: #08283d;

$basewhiteTransparent: rgba(255, 255, 255, 0.1);
$primary500transparent5: #3970960d;
$primary500transparent15: #39709626;
$blackTransparent: #000000a1;

$error25: #fff8f9;
$error50: #ffe8ed;
$error100: #ffd0db;
$error200: #fba9bc;
$error300: #fb90a8;
$error400: #f26f8c;
$error500: #ed5879;
$error600: #e23e63;
$error700: #c9264b;
$error800: #a41837;
$error900: #770720;

$warning25: #fff8f1;
$warning50: #ffefdf;
$warning100: #fcd8b3;
$warning200: #ffce9d;
$warning300: #ffb162;
$warning400: #ff9933;
$warning500: #ff8000;
$warning600: #e37200;
$warning700: #c26200;
$warning800: #964f07;
$warning900: #733a00;

$success25: #f4fff0;
$success50: #e8feda;
$success100: #c1f3b1;
$success200: #9eed87;
$success300: #74df54;
$success400: #43cb1a;
$success500: #26a600;
$success600: #229500;
$success700: #1e7904;
$success800: #1b5f07;
$success900: #144606;

$yellow25: #fefdf0;
$yellow50: #fefbe8;
$yellow100: #fef7c3;
$yellow200: #feee95;
$yellow300: #fde272;
$yellow400: #fac515;
$yellow500: #eaaa08;
$yellow600: #ca8504;
$yellow700: #a15c07;
$yellow800: #854a0e;
$yellow900: #713b12;

$purple25: #fafaff;
$purple50: #f4f3ff;
$purple100: #ebe9fe;
$purple200: #d9d6fe;
$purple300: #bdb4fe;
$purple400: #9b8afb;
$purple500: #7a5af8;
$purple600: #6938ef;
$purple700: #5925dc;
$purple800: #4a1fb8;
$purple900: #3e1c96;

$light-blue: #e1effe;
$light-gray: #f4f4f4;
$mid-gray: #999999;
$dark-gray: #797979;
$yellow: #eaaa08;
$danger: #c20b2f;

$moss25: #fafdf7;
$moss50: #f5fbee;
$moss100: #e6f4d7;
$moss200: #ceeab0;
$moss300: #acdc79;
$moss400: #86cb3c;
$moss500: #669f2a;
$moss600: #4f7a21;
$moss700: #3f621a;
$moss800: #335015;
$moss900: #2b4212;

$greenLight25: #fafef5;
$greenLight50: #f3fee7;
$greenLight100: #e4fbcc;
$greenLight200: #d0f8ab;
$greenLight300: #a6ef67;
$greenLight400: #85e13a;
$greenLight500: #66c61c;
$greenLight600: #4ca30d;
$greenLight700: #3b7c0f;
$greenLight800: #326212;
$greenLight900: #2b5314;

$green25: #f6fef9;
$green50: #edfcf2;
$green100: #d3f8df;
$green200: #aaf0c4;
$green300: #73e2a3;
$green400: #3ccb7f;
$green500: #16b364;
$green600: #099250;
$green700: #087443;
$green800: #095c37;
$green900: #084c2e;

$teal25: #f6fefc;
$teal50: #f0fdf9;
$teal100: #ccfbef;
$teal200: #99f6e0;
$teal300: #5fe9d0;
$teal400: #2ed3b7;
$teal500: #15b79e;
$teal600: #0e9384;
$teal700: #107569;
$teal800: #125d56;
$teal900: #134e48;

$cyan25: #f5feff;
$cyan50: #f5feff;
$cyan100: #cff9fe;
$cyan200: #a5f0fc;
$cyan300: #67e3f9;
$cyan400: #22ccee;
$cyan500: #06aed4;
$cyan600: #088ab2;
$cyan700: #0e7090;
$cyan800: #155b75;
$cyan900: #164c63;

$blueLight25: #f5fbff;
$blueLight50: #f0f9ff;
$blueLight100: #e0f2fe;
$blueLight200: #b9e6fe;
$blueLight300: #7cd4fd;
$blueLight400: #36bffa;
$blueLight500: #0ba5ec;
$blueLight600: #0086c9;
$blueLight700: #026aa2;
$blueLight800: #065986;
$blueLight900: #0b4a6f;

$blue25: #f5faff;
$blue50: #eff8ff;
$blue100: #d1e9ff;
$blue200: #b2ddff;
$blue300: #84caff;
$blue400: #53b1fd;
$blue500: #2e90fa;
$blue600: #1570ef;
$blue700: #175cd3;
$blue800: #1849a9;
$blue900: #194185;

$blueDark25: #f5f8ff;
$blueDark50: #eff4ff;
$blueDark100: #d1e0ff;
$blueDark200: #b2ccff;
$blueDark300: #84adff;
$blueDark400: #528bff;
$blueDark500: #2970ff;
$blueDark600: #155eef;
$blueDark700: #004eeb;
$blueDark800: #0040c1;
$blueDark900: #00359e;

$indigo25: #f5f8ff;
$indigo50: #eef4ff;
$indigo100: #e0eaff;
$indigo200: #c7d7fe;
$indigo300: #a4bcfd;
$indigo400: #8098f9;
$indigo500: #6172f3;
$indigo600: #444ce7;
$indigo700: #3538cd;
$indigo800: #2d31a6;
$indigo900: #2d3282;

$violet25: #fbfaff;
$violet50: #f5f3ff;
$violet100: #ece9fe;
$violet200: #ddd6fe;
$violet300: #c3b5fd;
$violet400: #a48afb;
$violet500: #875bff;
$violet600: #7839ee;
$violet700: #6927da;
$violet800: #5720b7;
$violet900: #491c96;

$fuchsia25: #fefaff;
$fuchsia50: #fdf4ff;
$fuchsia100: #fbe8ff;
$fuchsia200: #f6d0fe;
$fuchsia300: #eeaafd;
$fuchsia400: #e478fa;
$fuchsia500: #dd44f1;
$fuchsia600: #ba24d5;
$fuchsia700: #9f1ab1;
$fuchsia800: #821890;
$fuchsia900: #6f1877;

$pink25: #fef6fb;
$pink50: #fdf2fa;
$pink100: #fce7f6;
$pink200: #fcccee;
$pink300: #faa7e0;
$pink400: #f670c7;
$pink500: #ee46bc;
$pink600: #dd2590;
$pink700: #c11574;
$pink800: #9e165f;
$pink900: #851651;

$rose25: #fff5f6;
$rose50: #fff1f3;
$rose100: #ffe4e8;
$rose200: #fecdd6;
$rose300: #fea3b4;
$rose400: #fd6f8e;
$rose500: #f63d68;
$rose600: #e31b54;
$rose700: #c01048;
$rose800: #a11043;
$rose900: #89123e;

$orangeDark25: #fff9f5;
$orangeDark50: #fff4ed;
$orangeDark100: #ffe6d5;
$orangeDark200: #ffd6ae;
$orangeDark300: #ff9c66;
$orangeDark400: #ff692e;
$orangeDark500: #ff4405;
$orangeDark600: #e62e05;
$orangeDark700: #bc1b06;
$orangeDark800: #97180c;
$orangeDark900: #771a0d;

$orange25: #fefaf5;
$orange50: #fef6ee;
$orange100: #fdead7;
$orange200: #f9dbaf;
$orange300: #f7b27a;
$orange400: #f38744;
$orange500: #ef6820;
$orange600: #e04f16;
$orange700: #b93815;
$orange800: #932f19;
$orange900: #772917;

$appcc25: $teal25;
$appcc50: $teal50;
$appcc100: $teal100;
$appcc200: $teal200;
$appcc300: $teal300;
$appcc400: $teal400;
$appcc500: $teal500;
$appcc600: $teal600;
$appcc700: $teal700;
$appcc800: $teal800;
$appcc900: $teal900;
$header-bg-gradient-appcc: linear-gradient(192deg, $appcc600, $appcc800);
$header-bg-appcc: $appcc700;

$shadow-up: 0px -4px 13px 0px #0000001a;
$shadow-md:
    0px 4px 20px -2px rgba(16, 24, 40, 0.06),
    0px 3px 11px -2px rgba(16, 24, 40, 0.04);
$shadow-lg:
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12),
    0px 5px 5px -3px rgba(0, 0, 0, 0.2);

$shadow-2xl: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
$iconHoverBg: rgba(255, 255, 255, 0.1);

// Variants

// Primary
$header-bg: #16557e;
