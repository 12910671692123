@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.customQrScanner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 368px;
    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    &__video {
        width: 100%;
        height: 268px;
        border-radius: 8px;
        &__content {
            height: 100%;
            width: 100%;

            &--visible {
                @extend .customQrScanner__video__content;
                display: block;
            }
            &--hidden {
                @extend .customQrScanner__video__content;
                display: none;
            }
        }
    }
    &__recording {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        @include t.text("sm", "semibold");
        color: c.$baseWhite;
        &__icon {
            display: flex;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            animation: rotate 600ms linear infinite;
            &__content {
                font-size: 16px;
            }
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes upDown {
    0% {
        top: 100%;
    }
    50% {
        top: 0;
    }

    100% {
        top: 100%;
    }
}
