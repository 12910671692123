@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;

.ImagePreview {
    height: 76px;
    width: 100%;
    display: flex;
    background-color: c.$baseWhite;
    border: 1px solid c.$primary600;
    border-radius: 6px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    gap: 5px;

    &--error {
        @extend .ImagePreview;
        background-color: c.$error25;
        border: 1px solid c.$error300;
    }
    &--loading {
        @extend .ImagePreview;
        border: 1px solid c.$gray200;
    }
    &__flex {
        display: flex;
        align-items: center;
        gap: 14px;
    }
    &__image {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 4px solid c.$primary50;
        background-color: c.$primary100;
        display: flex;
        align-items: center;
        justify-content: center;
        &__icon {
            font-size: 12px;
        }
    }
    &__texts {
        max-width: 95%;

        &__firstLine {
            width: 100%;
            line-break: anywhere;

            display: flex;
            gap: 6px;
            color: c.$primary600;
            @include t.text("xs", "medium");
            align-items: center;
            &--error {
                color: c.$error700;
            }
            &--loading {
                color: c.$gray700;
            }
            &__subtext {
                @include t.text("xs");
                color: c.$gray600;
                &--error {
                    @extend .ImagePreview__texts__firstLine__subtext;
                    color: c.$error700;
                }
                &--loading {
                    @extend .ImagePreview__texts__firstLine__subtext;
                    color: c.$gray700;
                }
            }
        }
    }
    &__icon {
        color: c.$primary600;
        font-size: 16px;
        cursor: pointer;
        &--error {
            cursor: pointer;
            color: c.$error700;
        }
    }
    &__iconContainer {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}
