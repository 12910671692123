.taskTemporalityPeriodical {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }
    &__recurrency {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        &__repeat {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
        }
        &__ends {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
        }
    }
}
