@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
.notificationIcon {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &--notification {
        @extend .notificationIcon;
        width: 40px;
        height: 40px;
        &--sm {
            width: 32px;
            height: 32px;
        }
        &--md {
            width: 40px;
            height: 40px;
        }
        &--lg {
            width: 48px;
            height: 48px;
        }
        &--xl {
            width: 50px;
            height: 50px;
        }

        &--primary,
        &--sporadic-task,
        &--audit-report,
        &--patch-notes,
        &--star-info {
            border: 6px solid c.$primary50;
            background: c.$primary100;
            &--xl {
                @extend .notificationIcon--notification--primary;
                border-width: 8px;
            }
        }
        &--audit-nc,
        &--danger,
        &--star-danger,
        &--issue-task-temp,
        &--issue-task {
            border: 6px solid c.$error50;
            background: c.$error100;
        }
        &--warning {
            border: 6px solid c.$warning50;
            background: c.$warning100;
        }
        &--gray {
            border: 6px solid c.$gray100;
            background: c.$gray200;
        }
        &--success {
            border: 6px solid c.$success50;
            background: c.$success100;
        }
        &--star-yellow {
            border: 6px solid c.$yellow50;
            background: c.$yellow100;
        }
    }

    &--alert {
        @extend .notificationIcon;
        width: 25px;
        height: 25px;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        text-align: center;
        &--primary,
        &--star-info,
        &--sporadic-task,
        &--audit-report,
        &--patch-notes {
            @extend .notificationIcon__icon;
            color: c.$primary600;
        }
        &--audit-nc,
        &--danger,
        &--star-danger,
        &--issue-task-temp,
        &--issue-task {
            @extend .notificationIcon__icon;
            color: c.$error600;
        }
        &--warning {
            @extend .notificationIcon__icon;
            color: c.$warning600;
        }
        &--success {
            @extend .notificationIcon__icon;
            color: c.$success600;
        }
        &--gray {
            @extend .notificationIcon__icon;
            color: c.$gray600;
        }

        &--star-yellow {
            @extend .notificationIcon__icon;
            color: c.$yellow600;
        }
        &--notification {
            font-size: 16px;
        }
        &--alert {
            font-size: 20px;
        }

        &--sm {
            font-size: 12px;
        }

        &--xl {
            font-size: 18px;
        }
    }
}
