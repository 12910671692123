@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.taskpill__sliderModal {
    width: 100%;
}

.taskPill {
    background-color: c.$baseWhite;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &--box-shadow {
        box-shadow: c.$shadow-md;
    }

    &--border {
        border: 1px solid c.$gray200;
    }

    &__sliderModal {
        width: 100%;
    }

    &--displayOnly {
        @extend .taskPill;
        padding: 12px;
        box-shadow: none;
        cursor: default;
    }
    &--hidePadding {
        padding: 0px;
    }
    &__carrousel {
        user-select: none;
    }
    &__section {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__wrapper {
            gap: 12px;
            display: flex;
            flex-direction: column;
        }
        &__container {
            display: flex;
            width: 100%;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            &__line {
                display: flex;
                width: 60%;
                gap: 20px;
                height: 100%;
                &--dsk {
                    @extend .taskPill__section__container__line;
                    width: 100px;
                    min-width: 100px;
                }
            }
        }

        &__review {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 12px;
            height: 100%;
        }
        &--last {
            @extend .taskPill__section;
            gap: 12px;
            align-items: flex-end;
            &__date {
                @include t.text("xs");
                color: c.$gray600;
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 4px;
            }
        }
        &__right {
            display: flex;
            align-items: center;
            gap: 8px;
            &--top {
                align-items: flex-start;
            }

            &__icons {
                @extend .taskPill__section__right;
                gap: 16px;
                font-size: 16px;
                color: c.$gray300;
            }
        }
        &__title {
            @include t.text("sm", "semibold");
            color: c.$gray900;
        }
        &__taskDescription {
            @include t.text("xs", "regular");
            color: c.$gray600;
        }
    }
    &.disabled {
        border: 1px solid c.$gray300;
        background: c.$gray50;
        box-shadow: none;
    }
}

.flexDirectionColumn {
    flex-direction: column;
}

@media screen and (min-width: v.$tablet) {
    .taskPill {
        flex-direction: row;
        gap: 12px;
        &__carrousel {
            height: 100%;
            width: 100%;
            &__wrapper {
                width: 100%;
                max-width: 180px;
                height: 122px;
                &--oneColumn {
                    height: 100%;
                    width: 100%;
                }
            }
        }
        &__section {
            &__wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top: 0;
                align-items: normal;
            }

            &__review {
                margin-top: 0;
                min-width: 260px;
                width: 260px;
                padding-top: 8px;
                align-items: flex-start;
                height: fit-content;
            }
        }
    }
}

.oneColumn {
    flex-direction: column;
    .taskPill__section__review {
        width: 100%;
    }
}

.taskPIllDsk__container {
    &__section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        min-width: 190px;
        width: 100%;
        min-width: 200px;
        &--top {
            @extend .taskPIllDsk__container__section;
            align-self: flex-start;
        }
        &--right {
            @extend .taskPIllDsk__container__section;
            width: 260px;
            min-width: 260px;
        }
    }
    &__carrousel {
        height: 100%;
        display: flex;
        align-items: center;
    }
}
.taskPillDsk__section__right {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
