.auditIssuePillList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    width: 100%;
}

.auditIssuePillList > .pagination {
    padding-bottom: 12px;
}
