@use "/src/styles/variables.scss" as v;

.selectDataToReport {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    &__selectors,
    &__checklist {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding-right: 0;
    }
    &__preview {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__container {
            display: flex;
            gap: 12px;
            align-items: center;
        }
    }
    &__select {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
    }
}
.dynamicFields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }
}

@media screen and (min-width: v.$tablet) {
    .selectDataToReport__select {
        max-width: 400px;
    }
}
