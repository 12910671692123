@use "/src/styles/colors.scss" as c;

.mobileTaskRow {
    display: flex;
    width: 100%;
    padding: 12px;
    align-items: flex-start;
    gap: 20px;
    border-bottom: 1px solid c.$gray200;
    user-select: none;
    &--odd {
        @extend .mobileTaskRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .mobileTaskRow;
        background: c.$gray25;
    }
    &--focused {
        @extend .mobileTaskRow;
        background-color: c.$primary50;
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }
}
