.baseAuditGroupCheckListItemGrid {
    &__ponderation {
        width: 210px;
        &__row {
            text-align: center;
        }
    }
    &__actionButtons {
        width: 100px;
    }
}
