.reviewTaskFilter {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    &__checkboxes {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
