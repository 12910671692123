@use "/src/styles/colors.scss" as c;

.customContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background-color: c.$gray100;
    &--white {
        background-color: c.$baseWhite;
    }
}
