@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.assetFormPageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.assetForm {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    &__spinner {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        &__assetName {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            gap: 12px;
        }

        &__notWorking {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 6px;
            height: 100%;
        }

        &__number {
            display: flex;
            flex-direction: column;
            align-items: baseline;
        }

        &__fields {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
        }

        &__section {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
            width: 100%;
        }
    }
    &__submit {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;

        &__btn {
            font-size: 14px;
            font-weight: 600;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .assetForm {
        &__container {
            &__assetName {
                flex-direction: row;
                justify-content: space-between;
                gap: 32px;
                &--new,
                &--asset_range {
                    width: calc(50% - 10px);
                }
            }

            &__notWorking {
                gap: 16px;
            }

            &__fields {
                flex-direction: column;
            }

            &__assetDescription {
                width: 100%;
            }

            &__section {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
