@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.scheduleNotificationModal {
    width: 100%;
    background-color: white;
    z-index: 6;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__button {
        width: 100%;
        padding: 10px 12px;
        outline: 1px solid c.$gray300;
        border-radius: 6px;
        @include t.text("sm");
        color: c.$gray900;
        &:hover {
            outline: 2px solid c.$primary500;
            cursor: pointer;
            user-select: none;
        }
        &__confirm {
            margin-top: 40px;
        }
    }
}
