@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

.carrouselItem-skelleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    justify-content: flex-end;
    &__avatar {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        @include m.skeleton-is-loading(c.$primary300);
    }
    &__body {
        height: 18px;
        display: flex;
        justify-content: center;
        &__text {
            display: flex;
            width: 60px;
            height: 10px;
            align-items: center;
            gap: 10px;
            border-radius: 12px;
            @include m.skeleton-is-loading(c.$primary100);
        }
    }
}
