@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.editRole__container {
    display: flex;
    flex-direction: column;
    &__role {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}

@media screen and (min-width: v.$laptop) {
    .editRole__container__role {
        width: 100%;
    }
}
