@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.wizardHeaderNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__connector {
        height: 2px;
        width: 100%;
        &--h0 {
            @extend .wizardHeaderNumber__connector;
            height: 0;
        }
        &--gray {
            background-color: c.$gray200;
        }
        &--primary {
            background-color: c.$primary500;
        }
        &--success {
            background-color: c.$success500;
        }
    }
    &__ball {
        width: 28px;
        height: 28px;
        min-width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &--gray {
            @extend .wizardHeaderNumber__ball;
            background-color: c.$gray200;
            &__id {
                color: c.$gray700;
                @include t.text("xs", "regular");
            }
        }
        &--primary {
            @extend .wizardHeaderNumber__ball;
            background-color: c.$primary500;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &__inner {
                height: 8px;
                width: 8px;
                background-color: c.$baseWhite;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);
            }
        }
        &--success {
            @extend .wizardHeaderNumber__ball;
            background-color: c.$success500;
            color: c.$baseWhite;
            &:hover {
                background-color: c.$success600;
            }
        }
    }
}
