@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.optionWithCounter {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    &__counter {
        width: 50px;
        text-align: center;
        color: c.$gray600;
        @include t.text("sm");
        &--selected {
            color: c.$baseWhite;
        }
    }
}
