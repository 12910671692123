@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;

.myInformation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 32px;

    &__title {
        @include t.text("sm", "bold");
        color: c.$primary500;
    }

    &__item {
        min-width: 200px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__label {
            color: c.$gray600;
            @include t.text("sm", "regular");
        }
        &__text {
            @include t.text("sm", "semibold");

            color: c.$primary900;

            &::first-letter {
                text-transform: none;
            }
        }
        &__list {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-inline-start: 0;
            & .myInformation__item__text {
                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }
}
