@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;
.SuperVisorValorationDetailModal {
    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 20px;
        &__item {
            @extend .SuperVisorValorationDetailModal__header;
            gap: 20px;
        }
    }
    &__avatar {
        &__name {
            color: white;
            @include t.text("sm", "semibold");
        }
    }
}

@media screen and (max-width: v.$mobile) {
    .SuperVisorValorationDetailModal__header {
        flex-direction: column;
        padding: 20px;
    }
}
