.baseAuditGroupCheckListItemModal {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__spinner {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 229px;
        justify-content: center;
        align-items: center;
    }
}
