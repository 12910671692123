@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;

.planCheckboxStepOne {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.charsCount {
    @include t.text("sm");
    width: 100%;
    text-align: end;
}
