@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.dashboardHeader {
    &__body {
        &__header {
            display: flex;
            flex-direction: column;
            gap: 4px;
            &__container {
                display: flex;
                align-items: center;
                gap: 20px;
            }
            &__arrowBack {
                display: block;
            }
        }

        &__counters {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
        &__container {
            width: 100%;
        }
    }

    &__tabs__icon {
        cursor: pointer;
        color: c.$baseWhite;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
    }
}

@media screen and (min-width: v.$tablet) {
    .dashboardHeader__body {
        &__counters {
            gap: 8px;
            justify-content: flex-end;
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .dashboardHeader__body {
        &__counters {
            display: flex;
            align-items: center;
            z-index: 1;
            position: relative;
            height: 100%;
        }
    }
}

.fullheightwidth {
    height: 100%;
    width: 100%;
}
