@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.selectWithActionsLastItems {
    display: flex;
    padding: 12px 14px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 100%;
    gap: 4px;
    &:hover {
        background-color: c.$gray100;
        color: c.$gray600;
    }
    &__title,
    &__icon {
        color: c.$gray700;
        @include t.text("sm", "medium");
    }
}
