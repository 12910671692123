@use "/src/styles/colors.scss" as c;

.mobileTableBody {
    min-height: 560px;
    &--fitHeight {
        min-height: fit-content;
    }
    &__placeholder {
        min-height: 560px;
        display: flex;
        align-items: center;
    }
    &__row {
        &:nth-child(odd) {
            background: c.$baseWhite;
        }
        &:nth-child(even) {
            background: c.$gray25;
        }
    }
}
