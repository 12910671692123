@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.mobileHeaderTitle {
    color: c.$baseWhite;
    @include t.text("md", "semibold");
}

@media screen and (min-width: v.$mobile) {
    .mobileHeaderTitle {
        font-size: 18px;
        line-height: 28px;
    }
}
