@use "/src/styles/colors.scss" as c;

.radioGroup {
    height: 100%;
    display: flex;
    gap: 12px;

    &.row {
        flex-direction: row;
    }
    &.column {
        flex-direction: column;
        align-items: flex-start;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        &__wrapper {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            line-height: 24px;

            &__label {
                color: c.$gray700;
                font-weight: 500;
                &--disabled {
                    opacity: 0.7;
                }
            }

            &__sublabel {
                font-weight: 400;
                &--disabled {
                    opacity: 0.7;
                }
            }
        }
    }
}
