@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

:root {
    --toastify-toast-min-height: fit-content !important;
}

@mixin minimumtext($clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    flex: 1 0 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $clamp;
}

.toastGlobal {
    padding: 10px !important;
    width: 100% !important;
    right: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Toastify__toast-body {
    padding: 0px !important;
}

.toastAlert {
    box-shadow: none !important;
    border: 1px solid transparent !important;
    border-radius: 12px !important;
    padding: 16px !important;

    &--notification {
        @extend .toastAlert;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05) !important;
    }

    &--lessPadding {
        @extend .toastAlert;
        padding: 12px !important;
        border-radius: 10px !important;
    }

    &--primary {
        @extend .toastAlert;
        border-color: c.$primary300 !important;
        background-color: c.$primary25 !important;
    }
    &--danger {
        @extend .toastAlert;
        border-color: c.$error300 !important;
        background-color: c.$error25 !important;
    }
    &--warning {
        @extend .toastAlert;
        border-color: c.$warning300 !important;
        background-color: c.$warning25 !important;
    }
    &--success {
        @extend .toastAlert;
        border-color: c.$success300 !important;
        background-color: c.$success25 !important;
    }
    &--none {
        @extend .toastAlert;
        border-color: c.$gray300 !important;
        background-color: c.$gray25 !important;
    }
}

.toast {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100% !important;
    border-radius: 12px !important;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        &__title {
            align-items: center;
            display: flex;
            gap: 12px;
        }
        &__footer {
            display: flex;
        }
    }

    &__title {
        color: c.$gray900;
        @include t.text("sm", "semibold");
        @include minimumtext();
    }

    &__subtitle {
        color: c.$gray700;
        @include t.text("sm");
        @include minimumtext(2);
    }

    &__buttons {
        border: 0;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        @include t.text("sm", "semibold");
        color: c.$primary700;
    }
}

@media screen and (min-width: v.$tablet) {
    .toastGlobal {
        max-width: 600px !important;
        width: 100% !important;
        right: 50% !important;
        left: 50%;
        transform: translate(-50%) !important;
        gap: 0;
    }
    .toast {
        flex-direction: row;
    }
}
