@use "/src/styles/colors.scss" as c;

.customTable {
    width: 100%;
    border-top: 1px solid c.$gray200;
    box-shadow: c.$shadow-md;
    background-color: c.$baseWhite;
    position: relative;
    overflow: hidden;
    border-radius: 0px 0px 8px 8px;
    &--roundedCorners {
        @extend .customTable;
        border-radius: 8px;
    }
    &.isOpen {
        opacity: 1;
        display: block;
    }
    &.isClosed {
        opacity: 0;
        height: 0%;
        display: none;
    }
    &__table {
        width: 100%;
        // 610px 61px per fila * 10 files
        // 44.5px es l'altura del header
        // min-height: calc(610px + 44.5px);
        // height: calc(610px + 44.5px);
        min-height: 623px;
        height: 623px;
        border-spacing: 0;

        &--roundedCorners {
            @extend .customTable__table;
            border-radius: 8px;
        }

        &--fitHeight {
            height: fit-content;
            min-height: fit-content;
        }

        &__body {
            height: auto;
        }
    }

    &__footer {
        display: flex;
        padding: 12px 20px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-top: 1px solid c.$gray200;
        background: c.$gray50;
    }
}

@keyframes fadeIn {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        height: 0%;
        display: none;
    }
}

.tableSpinner {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    background: c.$baseWhite;
    user-select: none;
    z-index: 1;
}
