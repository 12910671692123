@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.barchart {
    --numberOfLabelsInView: 4;

    width: 100%;
    height: 300px;
    display: flex;
    gap: 8px;
    &__body {
        width: 100%;
        height: 100%;
        overflow-x: auto;
        scrollbar-width: none;

        &__content {
            height: calc(100% - 25px);
            display: flex;
            justify-content: space-between;

            &__date {
                display: flex;
                gap: 4px;
                min-width: 25%;
                align-items: flex-end;
                justify-content: center;
                background-size: 100% calc(329px / var(--numberOfLabelsInView));
                background-image: linear-gradient(to right, c.$gray300 1px, transparent 1px),
                    linear-gradient(to bottom, c.$gray300 1px, transparent 1px);
                &__bar {
                    width: 20px;
                }
            }
        }
    }
    &__xaxis {
        display: flex;
        justify-content: space-between;
        height: 25px;
        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            min-width: 25%;
            @include t.text("xs");
            color: c.$gray600;
        }
    }
    &__yaxis {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 25px;
        align-items: flex-end;
        @include t.text("xs");
        color: c.$gray600;
    }
}

@media screen and (min-width: v.$tablet) {
    .barchart {
        --numberOfLabelsInView: 5;
        &__body {
            scrollbar-width: thin;
            &__content__date {
                min-width: 20%;
            }
        }
        &__yaxis {
            padding-bottom: 36px;
        }
        &__xaxis__item {
            min-width: 20%;
        }
    }
}
