@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.fullScreenModal {
    width: 100%;
    height: 100%;
    background-color: c.$primary900;
    z-index: 10;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 16px;
}

@media screen and (min-width: v.$tablet) {
    .fullScreenModal {
        padding: 20px;
    }
}
