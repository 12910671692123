.taskTemporalityDateRangeCustom {
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 350px;
    &__date {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
