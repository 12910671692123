@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.editTask {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__spinner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__title {
        display: flex;
        padding-bottom: 8px;
        align-items: flex-start;
        gap: 10px;
        border-bottom: 1px solid c.$primary600;
        width: 100%;
        &__text {
            color: c.$primary600;
            @include t.text("sm", "semibold");
        }
    }
    &__container {
        display: flex;
        height: 100%;
        flex-direction: column;
        &__body {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 12px;
        }
        &__footer {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 0 12px;
            border-top: 1px solid c.$gray300;
            padding-top: 12px;
            &--flexEnd {
                @extend .editTask__container__footer;
                justify-content: flex-end;
            }
        }
    }
    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
        &__radio {
            display: flex;
            width: 100%;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .editTask {
        &__container {
            &__body {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
                gap: 20px;
            }
            &__dataToReport {
                padding: 0 20px 20px 20px;
            }
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .editTask {
        &__container {
            max-width: 100%;
            margin: 0 auto;
            width: 100%;
            &__body {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
