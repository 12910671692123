.planAppccGrid {
    &__id {
        min-width: 65px;
        width: 65px;
    }
    &__taskCount {
        min-width: 180px;
        width: 180px;
    }
    &__assetCount {
        min-width: 180px;
        width: 180px;
    }
    &__corectiveMeasureCount {
        min-width: 205px;
        width: 205px;
    }
    &__actions {
        min-width: 120px;
        width: 120px;
    }

    &__counters {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}
