@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.dynamicFieldItemReadOnly {
    width: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__type {
        color: c.$gray600;
        @include t.text("sm", "regular");
    }
    &__label {
        @include t.text("sm", "semibold");

        color: c.$primary900;

        &::first-letter {
            text-transform: none;
        }
    }

    &__options {
        display: flex;
        flex-direction: column;
        gap: 6px;
        list-style: none;
        padding-left: 16px;
    }
}
