@use "/src/styles/variables.scss" as v;

.stepper__form {
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    padding-right: 0;
    padding-bottom: 12px;
}

@media screen and (min-width: v.$tablet) {
    .stepper__form {
        padding-bottom: 32px;
    }
}
