@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;

@mixin colorStyle($color, $backgroundColor, $borderColor) {
    color: $color;
    background-color: $backgroundColor;
    border: 1px solid $borderColor;
}

.dayBubble {
    width: 32px;
    height: 32px;
    @include t.text("md");
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    user-select: none;
    &--xs {
        width: 20px;
        height: 20px;
        cursor: default;
        @include t.text("xs", "medium");
        &:hover {
            @extend .dayBubble--xs;
        }
    }
    &--primary {
        @extend .dayBubble;
        @include colorStyle(c.$gray500, c.$baseWhite, c.$gray300);
        &:hover {
            @extend .dayBubble--primary;
            @include colorStyle(c.$gray600, c.$gray100, c.$gray300);
        }
        &--active {
            @extend .dayBubble--primary;
            @include colorStyle(c.$baseWhite, c.$primary500, c.$primary500);
            &:hover {
                @extend .dayBubble--primary--active;
                @include colorStyle(c.$gray50, c.$primary400, c.$primary400);
            }
        }
        &--readonly {
            &:hover {
                @extend .dayBubble--primary;
                @include colorStyle(c.$gray500, c.$baseWhite, c.$gray300);
            }
            &--active {
                @extend .dayBubble--primary;
                @include colorStyle(c.$baseWhite, c.$primary500, c.$primary500);
                &:hover {
                    @extend .dayBubble--primary;
                    @include colorStyle(c.$baseWhite, c.$primary500, c.$primary500);
                }
            }
        }
    }
    &--dark {
        @extend .dayBubble;
        @include colorStyle(c.$baseWhite, c.$primary500, c.$primary500);
        &:hover {
            @extend .dayBubble--dark;
            @include colorStyle(c.$gray50, c.$primary400, c.$primary400);
        }
        &--active {
            @extend .dayBubble--dark;
            @include colorStyle(c.$gray500, c.$baseWhite, c.$gray300);
            &:hover {
                @extend .dayBubble--dark--active;
                @include colorStyle(c.$gray600, c.$gray100, c.$gray300);
            }
        }
        &--readonly {
            &:hover {
                @extend .dayBubble--dark;
                @include colorStyle(c.$baseWhite, c.$primary500, c.$primary500);
            }
            &--active {
                @extend .dayBubble--dark;
                @include colorStyle(c.$gray500, c.$baseWhite, c.$gray300);
                &:hover {
                    @extend .dayBubble--dark;
                    @include colorStyle(c.$gray500, c.$baseWhite, c.$gray300);
                }
            }
        }
    }
    &--disabled {
        @extend .dayBubble;
        @include colorStyle(c.$gray50, c.$gray300, c.$gray300);
        cursor: default;
        &:hover {
            @extend .dayBubble--disabled;
        }
        &--active {
            @extend .dayBubble--disabled;
            @include colorStyle(c.$gray50, c.$gray400, c.$gray400);
            &:hover {
                @extend .dayBubble--disabled--active;
            }
        }
    }
}
