@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;
@use "/src/styles/mixin/skelletonAnimation" as m;

.skeleton-box {
    @include m.skeleton-is-loading(c.$gray300);
}

.skelleton-img {
    width: 5.1rem;
    height: 5.1rem;
}

.auditPillSkelleton {
    height: 128px;
    &__avatar {
        display: flex;
        align-items: center;
        gap: 12px;
        &__icon--square {
            width: 32px;
            height: 32px;
        }
        &__icon--round {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
        &__label {
            width: 106px;
        }
    }
}
.auditPillListSkelleton {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

@media screen and (min-width: v.$mobile) {
    .auditPillSkelleton {
        height: 76px;
    }
}
