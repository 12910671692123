@use "/src/styles/colors.scss" as c;
.dotsLoading {
    &__moreMessages {
        position: relative;
        font-size: 50px;
        text-align: center;
        color: #333;

        &--loading {
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            .loader {
                display: flex;
                justify-content: center;

                &__dot {
                    width: 10px;
                    height: 10px;
                    margin: 0 5px;
                    background-color: c.$primary500;
                    border-radius: 50%;
                    animation: loaderAnim 0.6s infinite ease-in-out;

                    &:nth-child(1) {
                        animation-delay: 0.1s;
                    }

                    &:nth-child(2) {
                        animation-delay: 0.2s;
                    }

                    &:nth-child(3) {
                        animation-delay: 0.3s;
                    }
                }
            }
        }
    }
}

@keyframes loaderAnim {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
