@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.dynamicFieldItemDropdown {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    &__items {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
    }
    &__addNew {
        width: fit-content;
        &:hover &__text {
            border-bottom-color: c.$primary700;
        }
        &__container {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            color: c.$primary700;
        }
        &__icon {
            font-size: 16px;
        }
        &__text {
            @include t.text("sm", "semibold");
            border-bottom: 1px solid transparent;
        }
    }
}
