@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.filterButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 56px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    width: 110px;
    cursor: pointer;
    border: 1px solid transparent;
    user-select: none;
    &--active {
        @extend .filterButton;
        background: rgba(255, 255, 255, 0.2);
        border-color: c.$baseWhite;
    }

    &__container {
        align-items: center;
        display: flex;
        gap: 6px;
        justify-content: center;
        width: 100%;
    }

    &__icon {
        color: c.$baseWhite;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
    }

    &__counterContainer {
        align-items: center;
        color: c.$baseWhite;
        display: flex;
        justify-content: center;

        &__counter1 {
            @include t.display("xs");
            color: c.$baseWhite;

            &--bolder {
                @extend .filterButton__counterContainer__counter1;
                font-weight: 700;
            }
        }
        &__counter2 {
            @include t.text("xl");
            font-weight: 400;
            color: c.$baseWhite;
        }
    }

    &__subtitle {
        color: c.$primary100;
        text-align: center;
        @include t.text("xs");
        &--active {
            color: c.$baseWhite;
            @include t.text("xs");
            @extend .filterButton__subtitle;
        }
    }
}

.filterButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: 110px;
}

@media screen and (max-width: 525px) {
    .filterButtonsContainer {
        width: 77.5px;
        min-width: 77.5px;
    }
    .filterButton {
        height: 50px;
        width: 77.5px;
        min-width: 77.5px;
        &__icon {
            font-size: 12px;
            line-height: 12px;
        }

        &__counterContainer {
            &__counter1 {
                @include t.text("lg");
                &--bolder {
                    @extend .filterButton__counterContainer__counter1;
                    font-weight: 700;
                }
            }
            &__counter2 {
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
            }
        }

        &__subtitle {
            color: c.$primary100;
            @include t.text("xs", "medium");
            &--active {
                @extend .filterButton__subtitle;
                color: c.$baseWhite;
            }
        }
    }
}
