@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

.breadcrumbsSkeleton {
    display: flex;
    gap: 10px;
    width: 100%;
    &__item {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 24px;
        &__title {
            width: 120px;
            height: 14px;
            border-radius: 8px;
            @include m.skeleton-is-loading(c.$primary300);
        }
    }
}
