@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.selectOptions {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    color: c.$gray900;
    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }
    &__select {
        border-radius: 6px !important;
        border: 1px solid #d6d6d6 !important;
        position: relative;
        &__errored {
            border: 1px solid c.$error500 !important;
            border-radius: 6px !important;
            &--focused {
                border-radius: 6px !important;
                border: 2px solid c.$error500 !important;
                box-shadow: none !important;
            }
        }

        &--focused {
            border-radius: 6px !important;
            border: 2px solid c.$primary500 !important;
            box-shadow: none !important;
            padding: -1px;
        }
    }
    &__select--inverted {
        border-radius: 6px !important;
        border: 1px solid c.$baseWhite !important;

        &__errored {
            border: 1px solid c.$error500 !important;
            border-radius: 6px !important;
            &--focused {
                border-radius: 6px !important;
                border: 2px solid c.$error500 !important;
                box-shadow: none !important;
            }
        }

        &--focused {
            border-radius: 6px !important;
            border: 2px solid c.$baseWhite !important;
            box-shadow: none !important;
            padding: -1px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
    }
}
.selectOptions__select > div > div {
    cursor: pointer !important;
}

.reactSelectClassName {
    min-height: 40px !important;
    max-height: 40px !important;
    height: 40px !important;
    border-radius: 6px !important;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer;
    transition: none !important;
    &--inverted {
        @extend .reactSelectClassName;
        background-color: transparent !important;
        color: c.$baseWhite !important;
    }
    &--focused {
        min-height: 38px !important;
        max-height: 38px !important;
        height: 38px !important;
    }
    &--disabled {
        background-color: c.$gray50 !important;
    }
    &:hover {
        border: none !important;
        box-shadow: none !important;
    }
    &__input {
        &--inverted {
            color: c.$baseWhite !important;
            caret-color: c.$baseWhite !important;
        }
    }
}

.reactSelectIndicator {
    &--inverted > div {
        color: c.$baseWhite;
        cursor: pointer;
        &:hover {
            color: c.$baseWhite;
        }
    }
}

.reactSelectSingleValue {
    &--inverted {
        color: c.$baseWhite !important;
    }
}

.reactSelectPlaceholder {
    font-size: 16px !important;
    font-style: italic !important;
}
