.notificationPage {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__container {
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

.flex-between {
    justify-content: space-between;
}
