.reasignSelect {
    display: flex;
    gap: 12px;
    align-self: stretch;
    width: 100%;
    flex-direction: column;
    &__content {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 12px;
    }

    &__select {
        width: 100%;
    }
    &__placeholder {
        position: absolute;
        top: 10px;
        left: 19px;
        color: hsl(0, 0%, 50%);
        user-select: none;
    }
}
