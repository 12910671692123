@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.chatBotMessageHeader {
    display: flex;
    align-items: center;
    gap: 8px;

    &__date {
        @include t.text("xs");
        color: c.$gray600;
    }
}
