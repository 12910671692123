@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileTableHeader {
    display: flex;
    height: 40px;
    padding: 8px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid c.$gray200;
    background: c.$gray50;
    user-select: none;
    cursor: pointer;
    position: relative;

    &--roundedCorners {
        @extend .mobileTableHeader;
        border-radius: 8px 8px 0px 0px;
    }
    &--modal {
        height: auto !important;
        background-color: c.$baseWhite;
        transform: translate(-50%, 0);
        z-index: 1 !important;
        width: 95% !important;
        min-width: 340px;
        top: 40px !important;
        left: 50%;
        border-radius: 10px;
        box-shadow: c.$shadow-md;
    }
    &__popover {
        min-width: 250px;
        max-width: 340px;
        width: 100%;
    }
    &__container {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        &__info {
            display: flex;
            gap: 8px;
            align-items: center;
            width: 100%;
            justify-content: center;
            &__sortBy {
                color: c.$gray600;
                @include t.text("xs", "medium");
            }
        }
    }
}
