@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

@mixin avatarInitialsWithBg($color: "black") {
    background-color: $color;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: c.$baseWhite;
}

.avatarInitials {
    border-radius: 100%;
    position: relative;
    user-select: none;

    &__user {
        @include t.text("md", "medium");
    }

    &--xs {
        @include t.text("xs", "medium");
        width: 24px;
        min-width: 24px;
        height: 24px;
        @include t.text("xs", "medium");
    }

    &--sm {
        @include t.text("md", "medium");
        width: 32px;
        min-width: 32px;
        height: 32px;
        @include t.text("md", "medium");
    }

    &--md {
        @include t.text("md", "medium");
        width: 48px;
        min-width: 48px;
        height: 48px;
        @include t.text("md", "medium");
    }

    &--lg {
        @include t.text("lg", "medium");
        width: 48px;
        min-width: 48px;
        height: 48px;
        @include t.text("lg", "medium");
    }

    &--xl {
        @include t.text("xl", "medium");
        width: 56px;
        min-width: 56px;
        height: 56px;
        @include t.text("xl", "medium");
    }

    &--2xl {
        @include t.display("xs", "medium");
        width: 64px;
        min-width: 64px;
        height: 64px;
        @include t.display("xs", "medium");
    }

    &--noImage {
        background-color: c.$gray100;
        color: c.$gray600;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &--border {
        border: 1px solid c.$baseWhite;
    }
    &--moss {
        @include avatarInitialsWithBg(c.$moss600);
    }
    &--greenLight {
        @include avatarInitialsWithBg(c.$greenLight600);
    }
    &--green {
        @include avatarInitialsWithBg(c.$green600);
    }
    &--teal {
        @include avatarInitialsWithBg(c.$teal600);
    }
    &--cyan {
        @include avatarInitialsWithBg(c.$cyan600);
    }
    &--blueLight {
        @include avatarInitialsWithBg(c.$blueLight600);
    }
    &--blue {
        @include avatarInitialsWithBg(c.$blue600);
    }
    &--blueDark {
        @include avatarInitialsWithBg(c.$blueDark600);
    }
    &--indigo {
        @include avatarInitialsWithBg(c.$indigo600);
    }
    &--violet {
        @include avatarInitialsWithBg(c.$violet600);
    }
    &--purple {
        @include avatarInitialsWithBg(c.$purple600);
    }
    &--fuchsia {
        @include avatarInitialsWithBg(c.$fuchsia600);
    }
    &--pink {
        @include avatarInitialsWithBg(c.$pink600);
    }
    &--rose {
        @include avatarInitialsWithBg(c.$rose600);
    }
    &--orange {
        @include avatarInitialsWithBg(c.$orange600);
    }
    &--orangeDark {
        @include avatarInitialsWithBg(c.$orangeDark600);
    }
    &--yellow {
        @include avatarInitialsWithBg(c.$yellow600);
    }
}
