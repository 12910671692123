*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    overflow-y: hidden;
}

html,
body {
    width: 100%;
    height: 100%;
}

body,
* {
    margin: 0;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*::first-letter {
    text-transform: uppercase;
}

p {
    margin-bottom: 0 !important;
}
input[type="date"],
input[type="time"] {
    -webkit-appearance: none !important;
}
a {
    text-decoration: none;
}

/* scrollbar */
::-webkit-scrollbar {
    width: 0px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(177, 177, 177, 0.6);
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(177, 177, 177, 0.8);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

#root {
    height: 100%;
}
