@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

.legendItemSkelleton {
    height: 34px;
    &__mark {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        @include m.skeleton-is-loading(c.$primary300);
    }
    &__text {
        height: 12px;
        width: 120px;
        border-radius: 27px;
        @include m.skeleton-is-loading(c.$primary100);
    }
    &__percentage {
        height: 18px;
        width: 70px;
        border-radius: 8px;
        @include m.skeleton-is-loading(c.$primary100);
    }
}
