@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.notificationPill {
    display: flex;
    gap: 12px;
    padding: 16px 12px;
    border-bottom: 1px solid c.$gray200;
    position: relative;
    width: 100%;
    cursor: pointer;
    &:hover {
        background-color: c.$gray100;
    }

    &__featuredIcon {
        width: 32px;
        height: 32px;
        background-color: c.$primary100;
        border: 4px solid c.$primary50;
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        &__container {
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            &--primary {
                width: 16px;
                height: 16px;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                display: flex;
                align-items: center;
                text-align: center;
                color: c.$primary700;
            }
        }
    }

    &__content {
        display: flex;
        padding: 0px 16px 0px 0px;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        &__header {
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            justify-content: space-between;

            &__title {
                color: c.$gray700;
                overflow: hidden;
                text-overflow: ellipsis;
                @include t.text("sm", "semibold");
                margin: 0;
                flex: 1 0 0;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                &--clamp1 {
                    @extend .notificationPill__content__header__title;
                    -webkit-line-clamp: 1;
                }
                &--clamp3 {
                    @extend .notificationPill__content__header__title;
                    -webkit-line-clamp: 3;
                }
            }
            &__date {
                color: c.$gray600;
                align-self: flex-start;
                @include t.text("xs", "regular");
            }
        }
        &__body {
            display: -webkit-box;
            align-self: stretch;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            @include t.text("sm", "regular");
            overflow: hidden;
            text-overflow: ellipsis;
            color: c.$gray600;
        }
    }

    &__notification {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: c.$primary600;
        right: 12px;
        border-radius: 100%;
    }
}
