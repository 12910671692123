@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.exportCsv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 100%;
        &__body {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        &__page {
            display: flex;
            flex-direction: column;
            gap: 8px;
            &__subtitle {
                color: c.$gray700;
                @include t.text("lg", "semibold");
                margin: 0;
            }
            &__pageNumber {
                display: flex;
                gap: 8px;
            }
        }
    }
    &__maxRows {
        color: c.$gray400;
        @include t.text("xs", "medium");
    }
    &__footer {
        width: 100%;
        padding: 12px 0;
        &__content {
            display: flex;
            justify-content: flex-end;
            gap: 12px;
        }
    }
}
