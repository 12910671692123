@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.cellTitle {
    color: c.$gray700;
    @include t.text("xs", "regular");
    &--bolder {
        @extend .cellTitle;
        @include t.text("xs", "medium");
    }
    &--center {
        text-align: center;
    }
}
