@use "/src/styles/variables.scss" as v;

.editIssue {
    &__spinner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

@media screen and (min-width: v.$tablet) {
    .editIssue {
        &__container {
            padding: 0;
            padding-bottom: 20px;
        }
    }
}
