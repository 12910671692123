@use "/src/styles/variables.scss" as v;
.customDoughnutComponent2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 13px;
    &--row {
        @extend .customDoughnutComponent2;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
    }
    &__label {
        width: 100%;
        min-width: 300px;
        max-width: 400px;
    }
}

.dounutContainerClassName {
    width: 100%;
    height: 100%;
    max-height: 400px;
    max-width: 400px;
    display: flex;
    justify-content: center;
}
