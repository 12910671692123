@use "/src/styles/variables.scss" as v;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.closeCompanyModal {
    display: flex;
    flex-direction: column;
    gap: 32px;
    min-height: 250px;
    &__title {
        display: flex;
        justify-content: center;
        @include t.text("lg", "semibold");
        color: c.$gray900;
    }
}
