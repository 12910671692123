@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.alert {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100%;

    &__header {
        background-color: c.$primary600;
        width: 100%;

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px;
            &__icon {
                color: c.$baseWhite;
                cursor: pointer;
            }
        }
    }
    &__content {
        padding: 0 12px;
        overflow-y: scroll;
        width: 100%;
    }
}
.floatingButton {
    position: absolute;
    right: 12px;
    bottom: 60px;
    &--bottomLess {
        @extend .floatingButton;
        bottom: 20px;
    }
}

.floatingButtonIos {
    position: absolute;
    right: 12px;
    bottom: 80px;
}

@media screen and (min-width: v.$tablet) {
    .floatingButton {
        right: 20px;
        bottom: 20px;
    }
}
