@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.tableTab__header__actions {
    display: flex;
    align-items: center;
    gap: 32px;
    width: 100%;
    justify-content: flex-end;
    &--row {
        @extend .tableTab__header__actions;
    }
    &__markableIcon {
        top: -1px;
        right: -1px;
    }
    &__icons {
        display: flex;
        align-items: center;
        gap: 20px;
        position: relative;

        &__icon {
            display: flex;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            &__svg {
                font-size: 16px;
                transition: all 300ms;
                cursor: pointer;
                &.disabled {
                    opacity: 0.4;
                    cursor: default;
                    background-color: transparent;
                }
                &.isOpen {
                    transform: rotate(0deg);
                }

                &.isClosed {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .tableTab__header__actions {
        &__icons__icon {
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 4px;
            }
        }
    }
}
