@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

.headerAnalystSection {
    &__avatar {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        @include m.skeleton-is-loading(c.$primary300);
    }
    &__name {
        height: 20px;
        width: 120px;
        border-radius: 12px;
        @include m.skeleton-is-loading(c.$primary300);
    }
    &__stars {
        height: 20px;
        width: 200px;
        border-radius: 12px;
        @include m.skeleton-is-loading(c.$primary300);
    }
    &--32 {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
