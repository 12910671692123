@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;

.usersLoginItemSkelleton {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid c.$gray300;
    border-radius: 8px;

    &__trash {
        @include m.skeleton-is-loading(c.$primary100);
        border-radius: 4px;
        width: 12px;
        height: 12px;
    }
}
