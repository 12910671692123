@use "/src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.textAreaContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    &__label {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 12px;
        &__icon {
            cursor: pointer;
            @include t.text("sm", "medium");
            color: c.$gray700;
        }
    }
    &__emoji {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0;
        gap: 10px;
        width: 318px;
        background-color: c.$baseWhite;
        height: 300px;
        overflow-y: scroll;
        padding: 10px;
        border-radius: 8px;
        z-index: 4;
        box-shadow: v.$shadow-mid;
    }
    &__wrapper {
        border: 1px solid c.$gray300;
        padding: 12px 14px;
        border-radius: 4px;
        position: relative;
        &.disabled {
            @extend .textAreaContainer__wrapper;
            background-color: c.$gray100 !important;
            cursor: default;
        }

        &--errored {
            @extend .textAreaContainer__wrapper;
            border: 1px solid c.$error500;
        }

        &--focus {
            @extend .textAreaContainer__wrapper;
            border: 2px solid c.$primary500;
            padding: 11px 13px;
        }

        &--focusErrored {
            @extend .textAreaContainer__wrapper;
            border: 2px solid c.$error500;
            padding: 11px 13px;
        }

        &__input {
            width: 100%;
            height: 140px;
            box-sizing: border-box;
            border: none;
            @include t.text("md");
            outline: none;
            resize: none;
            background-color: transparent;
            &::placeholder {
                font-size: 16px;
                font-style: italic;
            }
        }
        &__audioContainer {
            padding-top: 6px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 12px;

            &__icon {
                cursor: pointer;
                background-color: c.$error500;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                font-size: 16px;
                color: c.$baseWhite;
                padding: 5px;
                width: 16px;
                height: 16px;
            }
        }
        &__recorderContainer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &__addFiles {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }
    &__emojis {
        &--top {
            bottom: 40px;
        }
        &--bottom {
            top: 20px;
        }
    }
    &__charsCount {
        font-size: 12px;
        text-align: right;
        color: c.$primary500;
    }
}

.recording {
    @include t.text("sm");
    color: c.$error500;
}

@media screen and (max-width: v.$mobile) {
    .textAreaContainer__wrapper__audioContainer {
        max-width: 300px;
    }
}
