@use "/src/styles/variables.scss" as v;
#analistDetailDonut {
    width: calc(100vw - 48px);
}

.analistDetail {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-end;
        gap: 12px;
        margin-bottom: 12px;
    }

    &__filters {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 12px;
        align-items: center;
        &__date {
            width: 100%;
            max-width: 350px;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .analistDetail {
        &__buttons {
            flex-direction: row;
        }
        &__filters {
            justify-content: flex-start;
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .analistDetail {
        &__buttons {
            gap: 20px;
            margin-bottom: 20px;
        }
        &__filters {
            gap: 20px;
            &__date {
                max-width: 250px;
            }
        }
    }
}
