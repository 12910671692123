@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.list__item__img {
    height: 100%;
}

.notifiactionsModal {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding: 12px;
    padding-bottom: 0;
    overflow: hidden;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100%;
        overflow-y: scroll;
        padding-bottom: 20px;

        &__avatar {
            display: flex;
            gap: 20px;
            align-items: center;
            &__title {
                color: c.$primary500;
            }
        }
    }
    &__closeIcon {
        align-self: flex-end;
        color: c.$primary500;
        font-size: 18px;
        cursor: pointer;
    }
    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }
    &__textArea {
        width: 100%;
        height: 140px;
        padding: 12px 20px;
        box-sizing: border-box;
        border: 1px solid c.$gray300;
        border-radius: 4px;
        background-color: c.$baseWhite;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__input {
            width: 100%;
            height: 100%;
            font-size: 16px;
            resize: none;
            outline: none;
            border: none;
        }
        &__title {
            line-height: 0;
            color: c.$primary500;
        }
        &__counter {
            font-size: 12px;
            text-align: right;
            color: c.$primary500;
        }
    }

    &__footer {
        display: flex;
        gap: 20px;
        margin-top: auto;
        align-items: center;
        position: absolute;
        bottom: 0;
        justify-content: center;
        width: 100%;
        left: 0;
        background-color: c.$baseWhite;
        border-top: 1px solid c.$gray200;
        padding: 12px 0;
    }
    &__error {
        color: c.$danger;
    }
}

@media screen and (min-width: v.$tablet) {
    .notifiactionsModal {
        padding: 32px;
        padding-bottom: 0;
        &__footer {
            justify-content: flex-end;
            position: relative;
        }

        &__container {
            gap: 32px;
            padding-bottom: 32px;
        }
    }
}
