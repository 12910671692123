@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.tableTabOneRowHeader {
    display: flex;
    padding: 12px 12px 0px 0px;
    flex-direction: column-reverse;
    &--primary {
        background-color: c.$primary600;
    }
    &--appcc {
        background-color: c.$baseWhite;
    }
}

@media screen and (min-width: v.$tablet) {
    .tableTabOneRowHeader {
        padding: 0px 12px 0px 0px;
        flex-direction: row;
        height: 50px;
    }
}
