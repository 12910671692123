@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.sendPatchNotesModal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__spinner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 300px;
    }
    &__title {
        display: flex;
        flex-direction: column;
        gap: 12px;
        color: c.$primary700;
        @include t.text("lg", "semibold");
    }
    &__notes {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-left: 12px;
    }
    &__note {
        @include t.text("md");
    }

    &__clients {
        width: 100%;
        gap: 12px;
        display: flex;
        flex-wrap: wrap;
    }
}
