@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileTaskBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    &__description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        align-self: stretch;
        overflow: hidden;
        text-overflow: ellipsis;
        color: c.$gray800;
        @include t.text("xs", "medium");
    }
    &__hours {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;
        overflow: hidden;
        text-overflow: ellipsis;
        color: c.$gray800;
        @include t.text("xs", "regular");
    }
    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
}
