@use "src/styles/colors.scss" as c;
@use "src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.companyWizardStep1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    &__dragFile {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    &__worksWithQR {
        display: flex;
        flex-direction: column;
        gap: 6px;
        @include t.text("sm", "medium");
    }
}

.companyWizardStep2 {
    &__buttonGroup {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
    }
}

.subdepartmentCheckbox {
    width: 100%;
    padding: 12px 0 0 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.companyWizardStep3 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__checkboxes {
        width: 100%;
        padding: 12px 0 0 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}
.companyWizardStep4 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__text {
        color: c.$gray900;
        @include t.text("md", "regular");
    }
    &__clone {
        color: c.$gray500;
        @include t.text("sm", "medium");
    }
    &__list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__item {
            color: c.$gray900;
            @include t.text("md", "regular");
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .companyWizardStep1 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
    }
}
