@use "/src/styles/colors.scss" as c;

.onBoardingIcon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &__svg {
        width: 15px;
        height: 15px;
        &--blocked {
            color: c.$gray300;
        }
        &--in_progress {
            color: c.$primary400;
        }
        &--review {
            color: c.$warning300;
        }
        &--done {
            color: c.$success300;
        }
    }
}
