@use "/src/styles/variables.scss" as v;

.sensorLinkedModalFooter {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
}

@media screen and (min-width: v.$tablet) {
    .sensorLinkedModalFooter {
        justify-content: flex-end;
    }
}
