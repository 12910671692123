@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.sliderElement {
    height: calc(100% - 20px);
    max-height: 400px;
}

.carrouselTaskPill {
    width: 100%;
    height: 100%;
    margin-bottom: 12px;
    align-self: center;
    max-height: 350px;
}

.mySlides__image {
    border-radius: 8px !important;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-wrapper {
    align-items: center;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sliderElement {
    & img {
        display: block;
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    &--fullScreen img {
        height: 100%;
        max-height: 90vh;
        object-fit: scale-down;
        overflow: hidden;
        border-radius: 8px;
    }
}

.singleImage {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
        // aspect-ratio: 3 / 2;
        &--fullScreen {
            @extend .singleImage__img;
            object-fit: scale-down;
        }
    }
    &__total {
        position: absolute;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(4px);
        border-radius: 50%;
        top: 4px;
        left: 4px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: c.$baseWhite;
        @include t.text("xs", "medium");
    }
    &--fullScreen {
        @extend .singleImage;
        width: 100%;
        max-width: 1200px;
        height: auto;
        max-height: 90vh;
    }
}

.swiper-pagination-bullet {
    display: block;
    border-radius: 10px;
    background: c.$gray200;
    opacity: 0.2;
    transition: all 0.3s;
    &-active {
        opacity: 1;
        height: 30px;
        box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
    }
}
.taskpill-slider {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
    &__photoExample {
        display: flex;
        height: 24px;
        padding: 3px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        position: absolute;
        right: 8px;
        bottom: 8px;
        border-radius: 27px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(4px);
        z-index: 1;
        color: c.$baseWhite;
        @include t.text("xs", "medium");
        &--taskDetail {
            @extend .taskpill-slider__photoExample;
        }
    }
}

@media (max-width: v.$mobile-767) {
    .carrouselTaskPill {
        width: 100%;
        display: flex;
        height: auto;
        border-radius: none;
    }

    .mySlides__image {
        border-radius: none;
    }
}

@media screen and (min-width: v.$tablet) {
    .sliderElement {
        &--fullScreen img {
            height: 100%;
            max-height: 90vh;
            object-fit: scale-down;
            overflow: hidden;
            border-radius: 8px;
        }
    }
    .taskpill-slider {
        &__photoExample {
            display: none;
            &--taskDetail {
                display: flex;
            }
        }
    }
}
