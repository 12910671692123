.workingPositionGrid {
    &__taskCount {
        width: 170px;
    }
    &__appccCount {
        width: 185px;
    }
    &__center {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    &__actions {
        width: 80px;
        min-width: 80px;
    }
}
