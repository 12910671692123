.sensorLinkedModalBody {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    flex: 1;
    &__description {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4px;
    }
}
