@use "/src/styles/colors.scss" as c;

.progressBar {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 11px;
    background-color: c.$gray200;
    &--sm {
        height: 8px;
    }
    &--md {
        height: 12px;
    }

    &__totalProgress {
        border-radius: 11px;
        height: 100%;
        &--green {
            @extend .progressBar__totalProgress;
            background-color: c.$success600;
        }
        &--yellow {
            @extend .progressBar__totalProgress;
            background-color: c.$yellow400;
        }
        &--red {
            @extend .progressBar__totalProgress;
            background-color: c.$error500;
        }
        &--blue {
            @extend .progressBar__totalProgress;
            background-color: c.$primary600;
        }
        &--sm {
            &--minWidth {
                min-width: 8px;
            }
        }
        &--md {
            &--minWidth {
                min-width: 12px;
            }
        }
    }
}
