@use "/src/styles/colors.scss" as c;

.tableHeaderContainer {
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    border-top: 1px solid c.$gray200;
    border-bottom: 1px solid c.$gray200;
    box-shadow: c.$shadow-md;
}
