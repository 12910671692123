@use "/src/styles/colors.scss" as c;

.dynamicFieldItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 6px;
    }
    &__labels {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-right: 32px;
    }
    &__input {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 12px;
        &__deleteIcon {
            color: c.$gray500;
            font-size: 16px;
            cursor: pointer;
        }
    }
}
