@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;
.companyDepartmentBox {
    outline: 1px solid c.$primary600;
    border-radius: 8px;
    overflow: hidden;
    &--disabled {
        @extend .companyDepartmentBox;
        outline: 1px solid c.$gray400;
    }
    &__select {
        min-width: 260px;
        max-width: 280px;
        width: 100%;
    }
    &__header {
        padding: 8px;
        border-radius: 7px 7px 0px 0px;
        background-color: c.$primary600;
        display: flex;
        flex-direction: column;
        gap: 12px;
        &__icons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
        }
        &--disabled {
            @extend .companyDepartmentBox__header;
            background-color: c.$gray400;
        }
        &__container {
            display: flex;
            color: c.$baseWhite;
            align-items: center;
            gap: 12px;
        }
        &__content {
            display: flex;
            width: 100%;
            min-height: 42px;
            justify-content: space-between;
        }
    }
    &__options {
        // TODO: --SUBDEPARTMENT--
        // padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__checkbox {
            padding: 8px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            &:hover {
                background-color: c.$primary50;
                color: c.$primary700 !important;
            }
        }
        &__subdepartment {
            padding-left: 32px;
        }
    }
    &__body {
        padding: 12px;
        &--disabled {
            @extend .companyDepartmentBox__body;
            background-color: c.$gray50;
            border-radius: 0px 0px 8px 8px;
        }
    }
}
