@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.resetPasswordInputForm {
    &__container,
    &__inputs {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        overflow: auto;
    }

    &__title {
        @include t.text("md", "semibold");
        color: c.$gray900;
        text-align: center;
    }
    &__password {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
}
