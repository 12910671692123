@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
.acceptPolicyPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__header {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        overflow: auto;
    }
    &__title {
        @include t.display("xs", "semibold");
        color: c.$gray900;
        text-align: center;
    }
    &__text {
        @include t.text("md", "regular");
        color: c.$gray600;
        text-align: center;
    }
    &__checkboxContainer {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        justify-content: center;
        @include t.text("md", "medium");

        &__text {
            color: c.$gray700;
        }
        &__link {
            color: c.$primary600;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__button {
        width: 100%;
    }
}
