@use "/src/styles/variables.scss" as v;

.teamValorationDetail {
    width: 100%;
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__counters {
        display: flex;
        gap: 12px;
        align-items: center;
    }
}

@media screen and (max-width: v.$mobile) {
    .teamValorationDetail {
        &__content {
            flex-direction: column;
            gap: 20px;
        }
    }
}
