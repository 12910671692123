@use "/src/styles/typographies.scss" as t;

.auditGroupChecklist {
    &__name {
        width: 40%;
        min-width: 250px;
    }
    &__total {
        width: 15%;
        min-width: 150px;
    }
    &__status {
        width: 15%;
        min-width: 150px;
        &__container {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
    &__issueCount {
        width: 15%;
        min-width: 140px;
        &__count {
            text-align: center;
        }
    }
    &__actions {
        width: 10%;
        min-width: 100px;
    }
    &__progressbar {
        display: flex;
        gap: 8px;
        align-items: center;
        &__total {
            @include t.text("sm", "medium");
            flex-shrink: 0;
        }
    }
}
