@use "src/styles/typographies.scss" as t;
@use "src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.myConfiguration {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: space-between;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    &__loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    &__notifications {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;
        &__doNotDisturb {
            display: flex;
            flex-direction: column;
            gap: 20px;
            &__text {
                display: flex;
                flex-direction: column;
                text-align: left;
            }
            &__content {
                display: flex;
                gap: 8px;
                align-items: flex-start;
            }
            &__timeContainer {
                display: flex;
                flex-direction: row;
                gap: 30px;
            }
        }
    }
    &__language {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }
    &__title {
        @include t.text("sm", "bold");
        color: c.$primary500;
        margin-bottom: 32px;
    }
    &__subtitle {
        @include t.text("sm", "medium");
    }
    &__description {
        @include t.text("sm", "medium");
        color: c.$gray700;
        cursor: pointer;
    }
    &__subdescription {
        @include t.text("sm", "regular");
        color: c.$gray600;
    }
}
