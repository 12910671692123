.mediaCapture {
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1 1;
    &__image {
        object-fit: contain;
        width: 100%;
        max-width: 960px;
        display: flex;
        justify-content: center;
        max-height: 100dvh;
    }
    &__video {
        width: 100%;
        max-height: calc(100dvh - 68px);
        max-width: 960px;
        max-width: fit-content;
        object-fit: contain;
        height: 100%;
    }
}
