.patchNotesGrid {
    &__date {
        width: 200px;
    }
    &__count {
        text-align: center;
    }
    &__actions {
        width: 100px;
    }
}
