@use "src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.sidebarModal {
    position: absolute;
    z-index: 3;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 200ms ease-in-out;
    &--hide {
        @extend .sidebarModal;
        box-shadow: none;
        left: -100%;
    }
    &__container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 9;

        &--hide {
            @extend .sidebarModal__container;
            left: -100%;
            transition: all 200ms ease-in-out;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .sidebarModal {
        width: 100%;
        max-width: 375px;
        box-shadow: c.$shadow-2xl;

        &--hide {
            box-shadow: none;
            left: -385px;
        }
    }
}
