@use "/src/styles/variables.scss" as v;
.imageModal__image {
    width: 70vw;
    max-height: 80vh;
    max-width: 1023px;
    border-radius: 10px;
    object-fit: contain;
}

@media screen and (max-width: v.$laptop-1023) {
    .imageModal__image {
        width: 90vw;
        border-radius: 10px;
    }
}
