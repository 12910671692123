@use "/src/styles/colors.scss" as c;

.taskFields {
    display: flex;
    flex-direction: column;
    &__container {
        @extend .taskFields;
        width: 100%;
        gap: 8px;
    }
    &__content {
        @extend .taskFields;
        gap: 20px;
    }
}
